import React, {useState, useEffect} from "react";
import {Table, Form, Col, Modal } from 'react-bootstrap';
import {TableCell, Button, Grid, Card, CardContent, CardActions, TextField, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemIcon, ListItemSecondaryAction} from '@material-ui/core'
import './Users.scss'
import ajax from '../utils/ajax'
import Media from '../components/Media/Media'
import ResourceList from '../components/ResourceList/ResourceList'
import { Autocomplete } from '@material-ui/lab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { IconButton } from "@material-ui/core"
import DeleteIcon from '@material-ui/icons/Delete';
import {useGlobalData} from '../hooks/useGlobalData'
import './AddressBook.scss'

library.add(fas)

export const ProviderAddressBook = (props) => {
  const globalData = useGlobalData()
  const user = globalData.user

  const status = (user) => {
    if (!user.email) return "Adresse email manquante"
    else {
      return ""
    }
  }

  const sortUsers = (users) => {
    users.sort((a, b) => ((a.name || '') + a.lastName).localeCompare((b.name || '') + b.lastName))
  }

  const formatPhone = (phone) => {
    let phoneBak = phone
    if (!phone || phone.length === 0) return null
    phone = phone.replace(/\s+/g, '')
    phone = phone.replace(/\./g, '')
    phone = phone.replace(/\//g, '')
    if (!phone || phone.length === 0) return null
    if (phone.length === 9 && !phone.startsWith('0')) phone = '0' + phone
    if (phone.startsWith('33')) phone = '0' + phone.slice(2)
    if (!phone.startsWith('0')) phone = '+' + phone
    if (phone.startsWith('00')) phone = '+' + phone.slice(2)
    if (phone.startsWith('+')) return phone
    if (!phone || phone.length !== 10) {
      if (phone) console.log(phone, phoneBak)
      return phoneBak
    }

    return phone.slice(0, 2) + ' ' + phone.slice(2, 4) + ' ' + phone.slice(4, 6) + ' ' + phone.slice(6, 8) + ' ' + phone.slice(8, 10)
  }

  const formatPhoneIntl = (phone) => {
    if (!phone || phone.length !== 10) return null

    phone = phone.replace(/\s+/g, '')
    return '+33' + phone.slice(1, 10)
  }

  const UserRow = (props) => {
    let cells = []
    cells = [
      ...cells,
      <TableCell key='name'>{props.firstName && props.lastName ? (props.firstName + ' ' + props.lastName.toUpperCase()) : ''}</TableCell>,
      <TableCell key='role'>
        <div style={{fontWeight: 700}}>{props.name}</div>
        <div>{props.function || ''}</div>
      </TableCell>,
      <TableCell key='email'>
        <div style={{marginBottom: '3px'}}>
          <FontAwesomeIcon icon='at' style={{marginRight: '2px'}} />
          <a href={'mailto:' + props.email}>{props.email}</a>
        </div>
        <div style={{marginBottom: '3px'}}>
          <FontAwesomeIcon icon='phone' style={{marginRight: '7px'}} />
          {formatPhone(props.phone) || 'Non renseigné'}
        </div>
        <div>
          <FontAwesomeIcon icon='mobile-alt' style={{width: '13px', marginRight: '7px'}} />
          {formatPhone(props.mobile) || 'Non renseigné'}
        </div>
      </TableCell>
    ]

    return cells
  }

  const UserListItem = (props) => {
    let website = ''
    if (props.mainContact && props.mainContact.website) {
      website = props.mainContact.website
      if (!website.startsWith('http')) {
        website = 'http://' + website
      }
    }

    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{display: 'flex', flexDirection: 'row', marginTop: '8px', marginBottom: '8px', marginRight: '8px', alignItems: "center"}}>
          <Avatar alt={props.lastName} src={'api/media/' + props.picture} style={{marginRight: '10px'}}/>
          <div style={{flexGrow: 1, flexShrink: 1, minWidth: 0}}>
            {props.name && <div style={{fontSize: '1rem', fontWeight: 700, lineHeight: 1.5}}>{props.name}</div>}
            <div style={{fontSize: '1rem', lineHeight: 1.5}}>{props.firstName + ' ' + props.lastName.toUpperCase()}</div>
            <div style={{fontWeight: 700, fontSize: '10.5px', color: 'rgba(0, 0, 0, 0.54)'}}>
            </div>
            <div style={{fontSize: '10.5px', color: 'rgba(0, 0, 0, 0.54)'}}>{props.function || ''}</div>
          </div>
          <div style={{marginLeft: '10px', flexGrow: 0, flexShrink: 0}}>
            <div style={{marginBottom: '5px'}}>
              <FontAwesomeIcon icon='phone' style={{marginRight: '7px'}} />
              <a href={formatPhoneIntl(props.phone) ? 'tel:' + formatPhoneIntl(props.phone) : undefined}>{formatPhone(props.phone) || 'Non renseigné'}</a>
            </div>
            <div style={{marginTop: '5px'}}>
              <FontAwesomeIcon icon='mobile-alt' style={{width: '13px', marginRight: '7px'}} />
              <a href={formatPhoneIntl(props.mobile) ? 'tel:' + formatPhoneIntl(props.mobile) : undefined}>{formatPhone(props.mobile) || 'Non renseigné'}</a>
            </div>
          </div>
        </div>
        <div style={{marginLeft: '50px', marginTop: '-8px', marginBottom: '10px', fontSize: '10.5px'}}>
          <FontAwesomeIcon icon='at' style={{marginRight: '5px'}} />
          <a href={'mailto:' + props.email}>{props.email}</a>
        </div>
      </div>

    )
  }

  const UserForm = (props) => {
    return (
      <Card>
        <CardContent>
          <Grid container item direction='column' xs={12} md={12} spacing={2}>
            <Grid container item direction='row' alignItems='center' spacing={2}>
              <Grid item xs={12} md={7} container spacing={2} direction='column'>
                <Grid container item spacing={2}>
                  <Grid item>{props.textInput('name', 'Société')}</Grid>
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item>{props.textInput('firstName', 'Prénom')}</Grid>
                  <Grid item>{props.textInput('lastName', 'Nom')}</Grid>
                </Grid>
                <Grid item>{props.textInput('email', 'Adresse email')}</Grid>
                <Grid item>{props.textInput('function', 'Fonction')}</Grid>
                <Grid container item spacing={2}>
                  <Grid item>{props.textInput('phone', 'Téléphone fixe')}</Grid>
                  <Grid item>{props.textInput('mobile', 'Téléphone mobile')}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <CardActions style={{marginTop: '30px'}}>
            {
              props.needsSaving && (
                <Button variant="contained" color="primary" onClick={props.save}>
                  Enregistrer
                </Button>
              )
            }
            <Button variant="contained" color="secondary" onClick={props.cancel}>
              Annuler
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    )
  }

  const removeQuestion = (c) => {
    return `Voulez-vous vraiment supprimer le contact ${c.firstName} ${c.lastName} ?`
  }

  const getNewContact = () => {
    return {firstName: '', lastName: '', function: ''}
  }

  const saveBlob = (function () {
    var a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"
    return function (blob, fileName) {
      var url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = fileName
      a.click()
      window.URL.revokeObjectURL(url)
    }
  }())

  const downloadCSV = (meeting) => {
    ajax.get('/api/provider/export/contacts', {responseType: 'arraybuffer'}).then(res => {
      const blob = new Blob([res], { type: 'text/csv' })
      saveBlob(blob, 'annuaire-prestataires.csv')
    })
  }

  if (!user) return null

  return (
    <ResourceList
      resourceType='provider'
      sortResources={sortUsers}
      filterFields={['firstName', 'lastName', 'name', 'email', 'phone', 'mobile', 'function']}
      headers={['Nom', 'Fonction', 'Coordonnées']}
      resourceRow={UserRow}
      resourceListItem={UserListItem}
      pictureFields={['picture']}
      addLabel="Ajouter un contact"
      removeLabel="Suppression d'un contact"
      removeQuestion={removeQuestion}
      getNewResource={getNewContact}
      resourceForm={UserForm}
      noNavigation={props.supplier}
      noActions={user.role !== 'admin'}
      downloadCSV={downloadCSV}
    />
  )
}

export default ProviderAddressBook
