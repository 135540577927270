import React, {useState, useEffect} from "react";
import ajax from '../utils/ajax'
import {TableCell, Button, Grid, Card, Typography, CardContent, CardActions, CardHeader, CardMedia, List, ListItem, ListItemText, ListItemSecondaryAction, Divider, TextField} from '@material-ui/core'
import { useSubheader } from "../../_metronic/layout"
import {Tabs, Tab} from 'react-bootstrap'
import './Order.scss'
import './Article.scss'

export const Order = (props) => {
  const [order, setOrder] = useState(null)
  const [shops, setShops] = useState([])
  const subheader = useSubheader();

  const fetch = () => {
    ajax.get('/api/order/' + props.match.params.id).then(r => {
      let breadcrumbs = []
      breadcrumbs.push({
          text: 'Accueil',
          title: 'Accueil',
          title: 'Accueil',
          href: '/'
      })
      breadcrumbs.push({
          text: r.title,
          title: r.title,
          href: '/commande/' + props.match.params.id
      });
      subheader.setBreadcrumbs(breadcrumbs)
      subheader.setTitle(r.title)
      setOrder(r[0])

      ajax.post('/api/order/view', {_id: props.match.params.id}).then(r => {})
    })

    ajax.get('/api/user/shops').then(res => {
      setShops(res.filter(s => !s.noOrders))
    })
  }
  useEffect(fetch, [])

  if (!order) return null

  const renderGeneralTab = () => {
    return (

      <div style={{display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto', overflowX: 'hidden', padding: '10px'}}>

        {
          order.content && <div dangerouslySetInnerHTML={{__html: order.content.replace(/src=\"api/g, "src=\"https://intranetv2.comptoirdelours.fr/api")}} className='ArticleContent' />
        }

      </div>
    )
  }

  const quantity = (shopId, productId, variantId) => {
    if (!order.answers || !order.answers[shopId]) {
      let article = order.articles.find(art => (art.id === productId))
      if (!variantId) return (article.minQuantity || 0)
      else {
        let variant = article.variants.find(v => (v.id === variantId))
        return (variant.minQuantity || 0)
      }
    }

    if (!variantId) {
      let answer = order.answers[shopId].find(answer => answer.productId === productId)
      if (answer) return answer.quantity
      let article = order.articles.find(art => (art.id === productId))
      return (article.minQuantity || 0)
    }

    let answer = order.answers[shopId].find(answer => (answer.productId === productId && answer.variantId === variantId))
    if (answer) return answer.quantity
    let article = order.articles.find(art => (art.id === productId))
    let variant = article.variants.find(v => (v.id === variantId))
    return (variant.minQuantity || 0)
  }

  const setQuantity = (shopId, productId, variantId, e) => {
    if (isNaN(Number(e.target.value))) return

    let shopAnswers = order.answers[shopId] ? [...order.answers[shopId]] : []

    let index
    if (variantId !== null) index = shopAnswers.findIndex(answer => (answer.productId === productId && answer.variantId === variantId))
    else index = shopAnswers.findIndex(answer => (answer.productId === productId))

    let article = order.articles.find(a => a.id === productId)
    let minQty = Math.min(article.minQuantity || 0, article.availableStock || 0)
    if (variantId) {
      minQty = article.variants.find(v => v.id === variantId).minQuantity || 0
      minQty = Math.min(minQty, article.variants.find(v => v.id === variantId).availableStock || 0)
    }
    if (minQty < 0) minQty = 0

    let answer = {productId: productId, variantId: variantId, quantity: Math.max(minQty, Number(e.target.value))}
    if (index >= 0) shopAnswers[index] = answer
    else shopAnswers.push(answer)

    setOrder(prevState => ({...prevState, answers: {...prevState.answers, [shopId]: shopAnswers}}))
  }

  const formatPrice = (n) => {
    let str = n.toFixed(2)
    if (str.endsWith('.00')) {
      str = str.substr(0, str.length - 3)
    } else if (this.props.locale === 'fr') {
      str = str.replace('.', ',')
    }
    return str
  }

  const renderOrderForm = (shop) => {
    const orderOpen = !order.hasEndDate || new Date(order.endDate).getTime() > Date.now()

    return (
      <div style={{display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto', overflowX: 'hidden', padding: '10px'}}>
        <List>
          {order.articles.map((product, i) => (
            <>
              <ListItem key={'listitem' + product.id} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <ListItemText primary={product.name} secondary={product.description} style={{flexGrow: 1, flexShrink: 1, minWidth: 0}} />
                  {
                    !product.hasVariants && (
                      <>
                        <div style={{flexGrow: 0, flexShrink: 0, marginLeft: '15px'}}>
                          <TextField disabled={!orderOpen} style={{width: '100px'}} size='small' label='Quantité' InputProps={{ inputProps: { min: product.minQuantity || 0 } }} error={product.minQuantity && quantity(shop._id, product.id) > 0 && quantity(shop._id, product.id) < product.minQuantity} type='number' variant='outlined' value={quantity(shop._id, product.id)} onChange={setQuantity.bind(null, shop._id, product.id, null)} />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', fontSize: '0.875rem'}}>
                          {product.unitPrice && <div style={{flexGrow: 0, flexShrink: 0, marginLeft: '15px'}}><b>Prix unitaire HT :&nbsp;</b>{product.unitPrice + ' €'}</div>}
                          {product.minQuantity && <div style={{flexGrow: 0, flexShrink: 0, marginLeft: '15px', color: (product.minQuantity && quantity(shop._id, product.id) > 0 && quantity(shop._id, product.id) < product.minQuantity ? 'rgb(240, 24, 166)' : undefined)}}><b>Quantité min. :&nbsp;</b>{product.minQuantity}</div>}
                        </div>

                      </>
                    )
                  }
                </div>

                {
                  product.hasVariants && (
                    <List subheader="Variantes proposées" dense style={{marginTop: '10px'}}>
                      {
                        product.variants.map(variant => (
                          <ListItem key={'variantlisttem' + variant.id} style={{paddingTop: '15px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <ListItemText primary={variant.name} style={{flexGrow: 1, flexShrink: 1, minWidth: 0}}/>
                            <div style={{flexGrow: 0, flexShrink: 0, marginLeft: '15px'}}>
                              <TextField disabled={!orderOpen} style={{width: '100px'}} size='small' type='number' InputProps={{ inputProps: { min: product.minQuantity ||0 } }} label={'Quantité'} variant='outlined' error={product.minQuantity && quantity(shop._id, product.id, variant.id) > 0 && quantity(shop._id, product.id, variant.id) < variant.minQuantity} value={quantity(shop._id, product.id, variant.id)} onChange={setQuantity.bind(null, shop._id, product.id, variant.id)} />
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', fontSize: '0.875rem'}}>
                              {variant.unitPrice && <div style={{flexGrow: 0, flexShrink: 0, marginLeft: '15px'}}><b>Prix unitaire HT :&nbsp;</b>{variant.unitPrice + ' €'}</div>}
                              {variant.minQuantity && <div style={{flexGrow: 0, flexShrink: 0, marginLeft: '15px', color: (variant.minQuantity && quantity(shop._id, product.id, variant.id) > 0 && quantity(shop._id, product.id, variant.id) < variant.minQuantity ? 'rgb(240, 24, 166)' : undefined)}}><b>Quantité min. :&nbsp;</b>{variant.minQuantity}</div>}
                            </div>
                          </ListItem>
                        ))
                      }
                    </List>
                  )
                }
              </ListItem>

              {i < order.articles.length - 1 && <Divider key={'divider' + i}/>}
            </>
          ))}
        </List>
      </div>

    )
  }

  const renderOrderFormPerpetual = (shop) => {
    const orderOpen = !order.hasEndDate || new Date(order.endDate).getTime() > Date.now()

    return (
      <div style={{display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto', overflowX: 'hidden', padding: '10px'}}>
        <List>
          {order.articles.map((product, i) => (
            <>
              <ListItem key={'listitem' + product.id} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <ListItemText primary={product.name} secondary={product.description} style={{flexGrow: 1, flexShrink: 1, minWidth: 0}} />
                  {
                    !product.hasVariants && (
                      <>
                        <div style={{flexGrow: 0, flexShrink: 0, marginLeft: '15px'}}>
                          <TextField disabled={!orderOpen} style={{width: '100px'}} size='small' label='Quantité' InputProps={{ inputProps: { min: Math.max(0, Math.min(product.minQuantity || 0, product.availableStock)), max: product.availableStock } }} error={product.minQuantity && quantity(shop._id, product.id) > 0 && quantity(shop._id, product.id) < product.minQuantity} type='number' variant='outlined' value={quantity(shop._id, product.id)} onChange={setQuantity.bind(null, shop._id, product.id, null)} />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', fontSize: '0.875rem'}}>
                          {product.unitPrice && <div style={{flexGrow: 0, flexShrink: 0, marginLeft: '15px'}}><b>Prix unitaire HT :&nbsp;</b>{product.unitPrice + ' €'}</div>}
                          {product.minQuantity && <div style={{flexGrow: 0, flexShrink: 0, marginLeft: '15px', color: (product.minQuantity && quantity(shop._id, product.id) > 0 && quantity(shop._id, product.id) < product.minQuantity ? 'rgb(240, 24, 166)' : undefined)}}><b>Quantité min. :&nbsp;</b>{product.minQuantity}</div>}
                          <div style={{flexGrow: 0, flexShrink: 0, marginLeft: '15px', color: (quantity(shop._id, product.id) > product.availableStock ? 'rgb(240, 24, 166)' : undefined)}}><b>Stock disponible :&nbsp;</b>{product.availableStock}</div>
                        </div>

                      </>
                    )
                  }
                </div>

                {
                  product.hasVariants && (
                    <List subheader="Variantes proposées" dense style={{marginTop: '10px'}}>
                      {
                        product.variants.map(variant => (
                          <ListItem key={'variantlisttem' + variant.id} style={{paddingTop: '15px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <ListItemText primary={variant.name} style={{flexGrow: 1, flexShrink: 1, minWidth: 0}}/>
                            <div style={{flexGrow: 0, flexShrink: 0, marginLeft: '15px'}}>
                              <TextField disabled={!orderOpen} style={{width: '100px'}} size='small' type='number' InputProps={{ inputProps: { min: Math.max(0, Math.min(product.minQuantity || 0, variant.availableStock)), max: variant.availableStock } }} label={'Quantité'} variant='outlined' error={product.minQuantity && quantity(shop._id, product.id, variant.id) > 0 && quantity(shop._id, product.id, variant.id) < variant.minQuantity} value={quantity(shop._id, product.id, variant.id)} onChange={setQuantity.bind(null, shop._id, product.id, variant.id)} />
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', fontSize: '0.875rem'}}>
                              {variant.unitPrice && <div style={{flexGrow: 0, flexShrink: 0, marginLeft: '15px'}}><b>Prix unitaire HT :&nbsp;</b>{variant.unitPrice + ' €'}</div>}
                              {variant.minQuantity && <div style={{flexGrow: 0, flexShrink: 0, marginLeft: '15px', color: (variant.minQuantity && quantity(shop._id, product.id, variant.id) > 0 && quantity(shop._id, product.id, variant.id) < variant.minQuantity ? 'rgb(240, 24, 166)' : undefined)}}><b>Quantité min. :&nbsp;</b>{variant.minQuantity}</div>}
                              <div style={{flexGrow: 0, flexShrink: 0, marginLeft: '15px', color: (quantity(shop._id, product.id, variant.id) > variant.availableStock ? 'rgb(240, 24, 166)' : undefined)}}><b>Stock disponible :&nbsp;</b>{variant.availableStock}</div>
                            </div>
                          </ListItem>
                        ))
                      }
                    </List>
                  )
                }
              </ListItem>

              {i < order.articles.length - 1 && <Divider key={'divider' + i}/>}
            </>
          ))}
        </List>
      </div>

    )
  }

  const cancel = () => {
    window.history.back()
  }

  const save = () => {
    /*console.log(JSON.stringify(order.answers, null, 2))
    for (let shopId in order.answers) {
      let answer = order.answers[shopId]
      for (let art of order.articles) {
        if (!art.hasVariants) {
          if (!answer[art.id]) answer[art.id] = art.minQuantity || 0
        } else {
          if (!answer[art.id]) answer[art.id] = {}
          for (let variant of art.variants) {
            if (!answer[art.id][variant.id]) answer[art.id][variant.id] = variant.minQuantity || 0
          }
        }
      }
    }*/

    console.log(JSON.stringify(order.answers, null, 2))
    ajax.post('/api/order/answer', {_id: order._id, answers: order.answers}).then(() => {
      window.history.back()
    })
  }

  const canSave = () => {
    for (let shop of shops) {
      for (let product of order.articles) {
        if (!product.hasVariants) {
          if (quantity(shop._id, product.id) > product.availableStock) return false
          if (product.minQuantity && quantity(shop._id, product.id) > 0 && quantity(shop._id, product.id) < product.minQuantity) return false
        } else {
          for (let variant of product.variants) {
            if (quantity(shop._id, product.id, variant.id) > variant.availableStock) return false
            if (variant.minQuantity && quantity(shop._id, product.id, variant.id) > 0 && quantity(shop._id, product.id, variant.id) < variant.minQuantity) return false
          }
        }
      }
    }

    return true
  }

  const orderOpen = !order.hasEndDate || new Date(order.endDate).getTime() > Date.now()

  return (
    <div className='Order' style={{display: 'flex', flexDirection: 'column', flexGrow: 1, flexShrink: 1, minHeight: 0}}>
      <Card style={{flexGrow: 0, flexShrink: 0, marginBottom: '10px'}}>
        <CardHeader
          title={order.title}
          subheader={order.endDate ? ("Date limite de réponse : " + new Date(order.endDate).toLocaleDateString()) : undefined}
          style={{padding: '8px'}}
        />
      </Card>

      <Card style={{flexGrow: 1, flexShrink: 1, minHeight: 0, marginBottom: '10px'}}>
        <CardContent style={{height: '100%', display: 'flex', flexDirection: 'column', padding: 0, paddingTop: '10px'}}>
          <Tabs defaultActiveKey="general" style={{paddingLeft: '10px', paddingRight: '10px'}}>
            <Tab eventKey='general' title='Infos générales'>
              {renderGeneralTab()}
            </Tab>
            {
              shops.map(shop => (
                <Tab key={shop._id} eventKey={shop._id} title={shop.name}>
                  {order.perpetual ? renderOrderFormPerpetual(shop) : renderOrderForm(shop)}
                </Tab>
              ))
            }
          </Tabs>
        </CardContent>
      </Card>

      {
        <Card style={{marginTop: '10px', flexShrink: 0, flexGrow: 0}}>
          {
            orderOpen
            ? (
              <CardActions>
                <Button variant="contained" color="primary" onClick={save} disabled={!canSave()}>
                  Enregistrer mes quantités
                </Button>
                <Button variant="contained" color="secondary" onClick={cancel}>
                  Annuler
                </Button>
              </CardActions>
            )
            : (
              <CardActions>
                <Button variant="contained" color="secondary" onClick={cancel}>
                  Retour
                </Button>
              </CardActions>
            )
          }
        </Card>
      }
    </div>
  )
}
