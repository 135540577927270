import React, { useState, createContext, useEffect, useContext } from 'react';
import ajax from '../utils/ajax'

const GlobalDataContext = createContext()

export function useGlobalData() {
  return useContext(GlobalDataContext);
}

export function GlobalDataProvider({ children }) {
  const isAuthorized = window.localStorage.getItem('token')
  const [user, setUser] = useState(null)
  const [validationsCount, setValidationsCount] = useState(null)
  const fetch = () => {
    if (!isAuthorized) return

    ajax.get('/api/me').then(user => {
      setUser(user)

      if (user.role === 'admin') {
        ajax.get('/api/validations/count').then(res => {setValidationsCount(res.count)})
      }
    })
  }
  useEffect(fetch, [])

  const value = {user, validationsCount, fetch}

  return <GlobalDataContext.Provider value={value}>{children}</GlobalDataContext.Provider>;
}
