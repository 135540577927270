import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {Products} from "./pages/Products";
import {Brands} from "./pages/Brands";
import {Users} from "./pages/Users0";
import {Affiliates} from "./pages/Affiliates0";
import {Shops} from "./pages/Shops0";
import {Commitees} from "./pages/Commitees0";
import {Articles} from "./pages/Articles";
import {Article} from "./pages/Article";
import {Meetings} from "./pages/Meetings";
import {Deals} from "./pages/Deals";
import {Deal} from "./pages/Deal";
import {Home} from "./pages/Home";
import {Validations} from "./pages/Validations";
import {Suppliers} from "./pages/Suppliers";
import {Orders} from "./pages/Orders";
import {Order} from "./pages/Order";
import {Surveys} from "./pages/Surveys";
import {Survey} from "./pages/Survey";
import {Profile} from "./pages/Profile"
import {AddressBook} from "./pages/AddressBook"
import {ProviderAddressBook} from "./pages/ProviderAddressBook"
import {Catalogs} from "./pages/Catalogs"
import {Questions} from "./pages/Questions"
import {Question} from "./pages/Question"
import {useGlobalData} from "./hooks/useGlobalData"

const SupplierAddressBook = () => { return <AddressBook suppliers /> }

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect
    const globalData = useGlobalData()
    if (!globalData.user) return null

    if (globalData?.user?.role === 'reyt' && window.location.pathname === '/') {
        window.location.replace(window.location.origin + '/fournisseurs')
        return null
    }

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                <ContentRoute path="/produits" component={Products}/>
                <ContentRoute path="/marques" component={Brands}/>
                <ContentRoute path="/catalogues" component={Catalogs}/>
                <ContentRoute path="/utilisateurs" component={Users}/>
                <ContentRoute path="/adherents" component={Affiliates}/>
                <ContentRoute path="/points-de-vente" component={Shops}/>
                <ContentRoute path="/commissions" component={Commitees}/>
                <ContentRoute path="/reunions" component={Meetings}/>
                <ContentRoute path="/articles" component={Articles}/>
                <ContentRoute path="/article/:id" component={Article}/>
                <ContentRoute path="/bonnes-affaires" component={Deals}/>
                <ContentRoute path="/bonne-affaire/:id" component={Deal}/>
                <ContentRoute path="/fournisseurs" component={Suppliers}/>
                <ContentRoute path="/validations" component={Validations}/>
                <ContentRoute path="/commandes" component={Orders}/>
                <ContentRoute path="/commande/:id" component={Order}/>
                <ContentRoute path="/sondages" component={Surveys}/>
                <ContentRoute path="/sondage/:id" component={Survey}/>
                <ContentRoute path="/mon-profil" component={Profile}/>
                <ContentRoute path="/annuaire" component={AddressBook}/>
                <ContentRoute path="/contact-fournisseurs" component={SupplierAddressBook} />
                <ContentRoute path="/contact-prestataires" component={ProviderAddressBook} />
                <ContentRoute path="/faq" component={Questions}/>
                <ContentRoute path="/faq/:id" component={Question}/>
                {globalData.user && <ContentRoute path="/" component={globalData.user.role !== 'reyt' ? Home : Suppliers} />}
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
