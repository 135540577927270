/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./app/App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider
} from "./_metronic/layout";
import {MetronicI18nProvider} from "./_metronic/i18n";
import firebase from "firebase/app"
import "firebase/analytics"
import "firebase/messaging"

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

let render = () => {
  ReactDOM.render(
    <MetronicI18nProvider>
      <MetronicLayoutProvider>
        <MetronicSubheaderProvider>
          <MetronicSplashScreenProvider>
            <App store={store} persistor={persistor} basename={PUBLIC_URL} />
          </MetronicSplashScreenProvider>
        </MetronicSubheaderProvider>
      </MetronicLayoutProvider>
    </MetronicI18nProvider>,
    document.getElementById("root")
  )
}


let firebaseConfig = {
  apiKey: "AIzaSyC0TQ0RZ-ZiGWQCxDh9TgQdvf27wC_J6YI",
  authDomain: "intranet-cdo.firebaseapp.com",
  projectId: "intranet-cdo",
  storageBucket: "intranet-cdo.appspot.com",
  messagingSenderId: "828459338543",
  appId: "1:828459338543:web:b728fe4fc4c25c343cefda",
  measurementId: "G-TJ021DB0X0"
};
// Initialize Firebase
let firebaseApp = firebase.initializeApp(firebaseConfig)
firebase.analytics()
if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging()
  messaging.onMessage((payload) => {
    console.log('Message received. ', payload)
  })
  document.addEventListener('notificationclick', e => { console.log(e) })

  navigator.serviceWorker.register('/fcm-sw.js').then(registration => {
    messaging.useServiceWorker(registration)
    render()
  }).catch((err) => {
    console.log(err)
    render()
  })
} else {
  render()
}
