import axios from 'axios'

let axiosInstance = axios.create({
  baseURL: process.env.PUBLIC_URL,
  validateStatus: (status) => {
    return status >= 200 && status < 500
  }
})

axiosInstance.interceptors.request.use((config) => {
  config.headers.token = window.localStorage.getItem('token')
  return config
}, (error) => {
  console.log(error)
})

axiosInstance.interceptors.response.use((response) => {
  if (response.status === 401) {
    window.localStorage.removeItem('token')
    window.location.replace('/auth/login')
  } else if (response.data && response.data.token) {

    window.localStorage.setItem('token', response.data.token)
  }
  return response.data
}, (error) => {
  console.log(error)
})

export default axiosInstance
