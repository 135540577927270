import React, {useState, useEffect, useRef} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Media.scss'
import {Button} from '@material-ui/core'
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";

const Media = (props) => {
    const [imageNotFound, setImageNotFound] = useState(false)
    const [chosenImage, setChosenImage] = useState(null)
    const fileInputRef = useRef()
    const openFileInput = () => {
        if (fileInputRef) {
            fileInputRef.current.click()
        }
    }

    const setLogo = (e) => {
        let fileReader = new FileReader
        fileReader.onload = () => {
            setChosenImage(fileReader.result)
            setImageNotFound(false)
        }
        fileReader.readAsDataURL(e.target.files[0])
        props.onEdit(e.target.files[0])
    }

    return (
        <div className={'Media' + (props.editable ? ' editable' : '')}>
            <div className='logoContainer' onClick={props.editable ? openFileInput : null}>
                <img className={imageNotFound ? 'error' : ''} src={chosenImage || ('/api/media/' + props._id)} onError={setImageNotFound.bind(null, true)} />
                {imageNotFound && <FontAwesomeIcon icon='image' size="3x" />}
                <input type='file' ref={fileInputRef} style={{display: 'none'}} onChange={setLogo} />
            </div>
            {
              props.editable && props.changeLabel && (
                <Button variant="outlined" color='primary' size="small" style={{width: '100%'}} onClick={openFileInput}>
                  <FontAwesomeIcon icon='download' style={{marginRight: '15px'}} />
                  {props.changeLabel}
                </Button>
              )
            }
            {
              (props.onRemove && props._id && !props.siblingDragged && !props.isDragging) && (
                <a className='btn btn-icon btn-light btn-sm Media-remove-icon' onClick={props.onRemove}>
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
                  </span>
                </a>
              )
            }
        </div>
    )
}

export default Media
