import React, {useState, useEffect} from "react";
import ResourceList from '../components/ResourceList/ResourceList'
import ajax from '../utils/ajax'
import {TableCell, Button, Grid, Card, CardContent, CardActions, Avatar} from '@material-ui/core'
import SVG from "react-inlinesvg"
import {toAbsoluteUrl} from "../../_metronic/_helpers";
import {Deal} from './Deal'
import {Profile} from './Profile'

export const Validations = () => {
  const ValidationRow = (props) => {
    if (props.type === 'deal') {
      return [
        <TableCell key='picture'>{props.picture && <img height={80} src={'/api/media/thumbnail/' + props.picture} />}</TableCell>,
        <TableCell key='title'>{props.title}</TableCell>,
        <TableCell key='author'>
          {
            props.author && (
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Avatar style={{width: '60px', height: '60px'}} alt={props.author.firstName + ' ' + props.author.lastName.toUpperCase()} src={"/api/media/thumbnail/" + props.author.picture} />
                <div style={{marginLeft: '5px'}}>{props.author.firstName + ' ' + props.author.lastName.toUpperCase()}</div>
              </div>
            )
          }
        </TableCell>,
        <TableCell>
          <a className='btn btn-icon btn-light btn-sm' onClick={() => {props.validate(props.type)}}>
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}/>
            </span>
          </a>
          <a className='btn btn-icon btn-light btn-sm' onClick={() => {props.reject(props.type)}}>
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Error-circle.svg")}/>
            </span>
          </a>
        </TableCell>,
        <TableCell>
          <a className='btn btn-icon btn-light btn-sm' onClick={props.edit}>
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")}/>
            </span>
          </a>
        </TableCell>
      ]
    }

    if (props.type === 'user') {
      return [
        <TableCell key='picture'>{props.pendingValidation.picture && <img height={80} src={'/api/media/thumbnail/' + props.pendingValidation.picture} />}</TableCell>,
        <TableCell key='title'>Modifications de profil</TableCell>,
        <TableCell key='author'>
          {
            props.firstName + ' ' + props.lastName.toUpperCase()
          }
        </TableCell>,
        <TableCell>
          <a className='btn btn-icon btn-light btn-sm' onClick={() => {console.log('props.type');props.validate(props.type)}}>
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}/>
            </span>
          </a>
          <a className='btn btn-icon btn-light btn-sm' onClick={() => {props.reject(props.type)}}>
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Error-circle.svg")}/>
            </span>
          </a>
        </TableCell>,
        <TableCell>
          <a className='btn btn-icon btn-light btn-sm' onClick={props.edit}>
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")}/>
            </span>
          </a>
        </TableCell>
      ]
    }
    return null
  }

  const ValidationForm = (props) => {
    if (props.editedResource.type === 'deal') {
      return (
        <Deal {...props.editedResource} validation validate={props.validate.bind(null, props.editedResource.type)} reject={props.reject.bind(null, props.editedResource.type)} />
      )
    }
    if (props.editedResource.type === 'user') {
      let user = {
        picture: props.editedResource.pendingValidation.picture || props.editedResource.picture,
        firstName: props.editedResource.pendingValidation.firstName || props.editedResource.firstName,
        lastName: props.editedResource.pendingValidation.lastName || props.editedResource.lastName,
        phone: props.editedResource.pendingValidation.phone || props.editedResource.phone,
        mobile: props.editedResource.pendingValidation.mobile || props.editedResource.mobile,
        email: props.editedResource.pendingValidation.email || props.editedResource.email
      }
      return (
        <Profile user={user} validation validate={props.validate.bind(null, props.editedResource.type)} reject={props.reject.bind(null, props.editedResource.type)} />
      )
    }
    return null
  }

  const sortValidations = (validations) => {
    validations.sort((a, b) => new Date(b.date).getTime >= new Date(a.date))
  }

  return (
    <>
      <ResourceList
        resourceType='validation'
        filterFields={['name']}
        headers={['Image', 'Titre', 'Auteur', 'Validation', 'Consulter']}
        resourceRow={ValidationRow}
        resourceForm={ValidationForm}
        sortResources={sortValidations}
        validateQuestion={'En validant, ce contenu va être publié. Voulez-vous continuer ?'}
        noActions
      />
    </>

  )
}
