import React, {useState, useEffect} from "react";
import { Button, Table, Card, Form, Col, Modal } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../_metronic/_helpers";
import './Brands.scss'
import ajax from '../utils/ajax'
import Media from '../components/Media/Media'

library.add(fas)

export const Brands = () => {
  const [editedIndex, setEditedIndex] = useState(null)
  const [editedBrand, setEditedBrand] = useState(null)
  const [removedIndex, setRemovedIndex] = useState(null)
  const [creationMode, setCreationMode] = useState(false)
  const [brands, setBrands] = useState(null)
  const [needsSaving, setNeedsSaving] = useState(null)
  const [readyToSave, setReadyToSave] = useState(true)

  const fetch = () => {
    ajax.get('/api/brands').then(b => {
      b.sort((a, b) => a.name.localeCompare(b.name))
      setBrands(b)
    })
  }
  useEffect(fetch, [])

  const handleChange = e => {
    const { name, value } = e.target;
    setEditedBrand(prevState => ({...prevState, [name]: value}))
    setNeedsSaving(true)
  }

  const textInput = (field, placeholder) => {
    return <Form.Control type="text" placeholder={placeholder} value={editedBrand[field] || ''} name={field} onChange={handleChange} />
  }

  const areaInput = (field, placeholder) => {
    return <Form.Control as="textarea" rows="15" placeholder={placeholder} value={editedBrand[field] || ''} name={field} onChange={handleChange} />
  }

  const saveLogo = () => {
    if (!editedBrand.logo || typeof editedBrand.logo === 'string') {
      setReadyToSave(true)
      return
    }

    let data = new FormData()
    data.append('file',editedBrand.logo)
    ajax.put('/api/media', data).then((media) => {
      setEditedBrand(prevState => ({...prevState, logo: media._id}))
      setReadyToSave(true)
    })
  }

  const save = () => {
    if (!readyToSave) {
      saveLogo()
      return
    }
    const method = creationMode ? ajax.put : ajax.post
    method('/api/brand', editedBrand).then(() => {
      fetch()
      setNeedsSaving(false)
      setCreationMode(false)
      setEditedIndex(null)
    })
  }

  useEffect(() => {
    if (readyToSave) save()
  }, [readyToSave])

  const cancel = () => {
    setNeedsSaving(false)
    setCreationMode(false)
    setEditedIndex(null)
  }

  if (editedIndex !== null || creationMode) {
    return (
      <Card>
        <Card.Body>
          <Form>
            <Form.Group>
              <Form.Row style={{marginBottom: '10px'}}>
                <Col>
                  <Form.Label>Nom</Form.Label>
                  {textInput('name', 'Nom de la marque')}
                </Col>
                <Col>
                  <Media _id={editedBrand.logo} editable onSelect={(aaa) => { console.log(aaa) }} onEdit={(img) => { setEditedBrand(prevState => ({...prevState, logo: img})); setReadyToSave(false); setNeedsSaving(true) }} />
                </Col>
              </Form.Row>
              <Form.Row style={{marginBottom: '10px'}}>
                <Form.Label>Slug de l'article CDO</Form.Label>
                {textInput("slugCDO", "Slug de l'article CDO (à recopier depuis le CMS)")}
              </Form.Row>
            </Form.Group>

          </Form>
          <div className='toolbar'>
            {
              needsSaving && (
                <Button variant="primary" size="sm" onClick={save}>
                  Enregistrer
                </Button>
              )
            }
            <Button size="sm" variant="secondary" onClick={cancel}>
              Annuler
            </Button>
          </div>
        </Card.Body>
      </Card>
    )
  }

  const remove = () => {
    ajax.delete('/api/brand/' + brands[removedIndex]._id).then(() => {
      fetch()
      setRemovedIndex(null)
    })
  }

  const edit = (i) => {
    setEditedBrand(brands[i])
    setEditedIndex(i)
  }

  const create = () => {
    setEditedBrand({name: '', slugCDO: ''})
    setCreationMode(true)
  }

  return (
    <>
      <Card>
        <Card.Body>
          <Button style={{marginBottom: '15px'}} variant="primary" onClick={create}>
            <FontAwesomeIcon icon='plus' style={{marginRight: '15px'}} />
            Ajouter une marque
          </Button>
          <Table responsive className="table table-head-custom table-head-bg table-vertical-center">
            <thead>
              <tr className='text-left text-uppercase'>
                <th><span className='text-dark-75'>Logo</span></th>
                <th><span className='text-dark-75'>Nom</span></th>
                <th><span className='text-dark-75'>Slug CDO</span></th>
                <th><span className='text-dark-75'>Actions</span></th>
              </tr>
            </thead>
            <tbody>
              {
                brands && brands.map((b, i) => (
                  <tr key={b._id}>
                    <td><img height={80} src={'/api/media/thumbnail/' + b.logo} /></td>
                    <td>{b.name}</td>
                    <td>{b.slugCDO}</td>
                    <td>
                      <a className='btn btn-icon btn-light btn-sm' onClick={edit.bind(null, i)}>
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}/>
                        </span>
                      </a>
                      <a className='btn btn-icon btn-light btn-sm' onClick={setRemovedIndex.bind(null, i)}>
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
                        </span>
                      </a>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal show={removedIndex !== null}>
        <Modal.Header>
          <Modal.Title>Suppression d'une marque</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {removedIndex !== null && <p>{"Voulez-vous vraiment supprimer la marque " + brands[removedIndex].name + " ?"}</p>}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={setRemovedIndex.bind(null, null)}>Annuler</Button>
          <Button variant="primary" onClick={remove}>Supprimer</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Brands
