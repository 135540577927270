import React, {useState, useEffect} from "react";
import {TextField, Button, Grid, Card, CardContent, CardActions, Typography} from '@material-ui/core'
import Media from '../components/Media/Media'
import {useGlobalData} from '../hooks/useGlobalData'
import * as EmailValidator from 'email-validator'
import ajax from '../utils/ajax'
import {Modal} from 'react-bootstrap';

export const Profile = (props) => {
  const globalData = useGlobalData()
  const [user, setUser] = useState(props.validation ? props.user : globalData.user)
  const [needsSaving, setNeedsSaving] = useState(false)
  const [errors, setErrors] = useState({})
  const [validationModalOpen, setValidationModalOpen] = useState(false)

  const savePicture = (cb) => {
    if (!user.newPicture) {
      cb(null)
      return
    }

    let data = new FormData()
    data.append('file', user.newPicture)
    ajax.put('/api/media', data).then((media) => {
      cb(media._id)
    })
  }

  const validate = () => {
    let errors = {}
    let phone
    if (user.phone) phone = user.phone.trim().replace(/ |\.|-/g, '')
    let mobile
    if (user.mobile) mobile = user.mobile.trim().replace(/ |\.|-/g, '')

    if (phone !== undefined && phone !== '' && ((phone.length !== 10) || isNaN(phone) || !phone.startsWith('0'))) errors.phone = true
    if (mobile !== undefined && mobile !== "" && ((mobile.length !== 10) || isNaN(mobile) || !mobile.startsWith('0'))) errors.mobile = true
    if (user.firstName !== undefined && user.firstName === '') errors.firstName = true
    if (user.lastName !== undefined && user.lastName === '') errors.lastName = true
    if (user.email !== undefined && user.email === '') errors.email = true
    console.log(user.email.trim())
    if (user.email !== undefined && !EmailValidator.validate(user.email.trim(), {minDomainAtoms: 2})) errors.email = true

    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const save = () => {
    if (!validate()) return
    let newProfile = {
      firstName: user.firstName && user.firstName.trim(),
      lastName: user.lastName && user.lastName.trim(),
      emails: user.email && user.email.trim(),
      phone: user.phone && user.phone.trim().replace(/ |\.|-/g, ''),
      mobile: user.mobile && user.mobile.trim().replace(/ |\.|-/g, '')
    }

    savePicture((mediaId) => {
      if (mediaId) newProfile.picture = mediaId
      ajax.post('/api/user/pending', newProfile).then((res) => {
        if (res.needsValidation) {
          setValidationModalOpen(true)
        }
        else window.location.replace('/')
      })
    })
  }

  const handleChange = e => {
    const { name, value } = e.target;
    setUser(prevState => ({...prevState, [name]: value}))
    setNeedsSaving(true)
  }

  const helperText = {
    firstName: 'Prénom requis',
    lastName: 'Nom requis',
    email: 'Adresse email invalide',
    phone: 'Numéro invalide',
    mobile: 'Numéro invalide'
  }

  const textInput = (field, placeholder, fullWidth) => {
    return <TextField disabled={props.validation} size='small' fullWidth label={placeholder} variant='outlined' value={user[field] || ''} name={field} onChange={handleChange} error={errors[field]} helperText={errors[field] && helperText[field]} />
  }

  if (globalData.user && !user) {
    setUser(props.validation ? props.user : globalData.user)
  }
  if (!user) return null

  return (
    <div className='Profile' style={{display: 'flex', flexDirection: 'column', flexGrow: 1, flexShrink: 1, minHeight: 0}}>
      <Card style={{flexGrow: 1, flexShrink: 1, minHeight: 0, marginBottom: '10px'}}>
        <CardContent style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
          <Grid container item direction='column' xs={12} md={12} spacing={2}>
            <Grid item>
              <Typography variant='h6'>Mon profil</Typography>
            </Grid>
            <Grid container item direction='row' alignItems='center' spacing={2}>
              <Grid item xs={12} md={3}>
                <Media _id={user.picture} editable={!props.validation} changeLabel='Choisir une photo' onEdit={(img) => { setUser(prevState => ({...prevState, newPicture: img})); setNeedsSaving(true) }} />
              </Grid>
              <Grid item xs={12} md={9} container spacing={2} direction='column' style={{maxWidth: '700px'}}>
                <Grid container item spacing={2}>
                  <Grid item style={{flexGrow: 1}}>{textInput('firstName', 'Prénom')}</Grid>
                  <Grid item style={{flexGrow: 1}}>{textInput('lastName', 'Nom')}</Grid>
                </Grid>
                <Grid item>{textInput('email', 'Adresse email')}</Grid>
                <Grid container item spacing={2}>
                  <Grid item style={{flexGrow: 1}}>{textInput('phone', 'Téléphone fixe')}</Grid>
                  <Grid item style={{flexGrow: 1}}>{textInput('mobile', 'Téléphone mobile')}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {
        needsSaving && (
          <Card style={{marginTop: '10px', flexShrink: 0, flexGrow: 0}}>
            <CardActions>
              <Button variant="contained" color="primary" onClick={save}>
                Enregistrer
              </Button>
              <Button variant="contained" color="secondary" onClick={() => {window.location.replace('/')}}>
                Annuler
              </Button>
            </CardActions>
          </Card>
        )
      }
      {
        props.validation && (
          <Card style={{marginTop: '10px', flexShrink: 0, flexGrow: 0}}>
            <CardActions>
              <Button variant="contained" color="primary" onClick={() => { props.validate('user') }}>
                Valider
              </Button>
              <Button variant="contained" color="secondary" onClick={() => { props.reject('user') }}>
                Rejeter
              </Button>
            </CardActions>
          </Card>
        )
      }

      <Modal show={validationModalOpen}>
        <Modal.Header>
          <Modal.Title>Validation requise</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid container item direction='column' spacing={2}>
            <Grid item>
              Votre modification de profil nécessite une validation de la part d'un administrateur Comptoir de l'Ours. Une réponse vous sera apportée très prochainement.
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{marginRight: '10px'}} variant="contained" color="secondary" onClick={() => { setValidationModalOpen(false); window.location.replace('/')} }>OK</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
