import React, {useEffect} from "react"
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {TextField, Grid, Typography} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import LocationOnIcon from '@material-ui/icons/LocationOn';

const PlacesAutocomplete = (props) => {
  const {
    ready,
    value,
    onChange,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  useEffect(() => {
    setValue(props.address)
  }, [])

  const handleInput = (newValue) => {
    setValue(newValue);
  };

  const handleSelect = description => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log("📍 Coordinates: ", { lat, lng });
        props.onChange(description, lat, lng)
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
  };

  return (
    <Autocomplete value={value} onChange={(event, newValue, reason) => {
        if (reason === 'select-option') handleSelect(newValue)
        else handleInput(newValue)
      }}
      openOnFocus
      onClose={(e, reason) => {if (reason !== 'select-option') setValue(props.address)}}
      noOptionsText='Aucune adresse trouvée'
      onInputChange={(e, newValue) => { handleInput(newValue) }} renderInput={(params) => <TextField {...params} label="Adresse" variant="outlined" placeholder='Rechercher une adresse' />} options={data.map(place => place.description)}
      renderOption={(option) => (
        <Grid container alignItems="center">
          <Grid item>
            <LocationOnIcon style={{marginRight: '5px'}} />
          </Grid>
          <Grid item xs>
            {option}
          </Grid>
        </Grid>
      )}
    />
  );
}

export default PlacesAutocomplete
