import React, {useState, useEffect} from "react";
import { Table, Form, Col, Modal } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../_metronic/_helpers";
import './Affiliates.scss'
import ajax from '../utils/ajax'
import Media from '../components/Media/Media'
import {TextField, Grid, Paper, Card, CardContent, CardActions, Typography, Button, TableCell, TableRow} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import async from 'async'
import {useGlobalData} from '../hooks/useGlobalData'
import ResourceList from '../components/ResourceList/ResourceList'

library.add(fas)

const ContactForm = (props) => {
  const [editMode, setEditMode] = useState(false)

  let contact = props.contact
  if (contact && typeof contact === 'string') {
    contact = props.usersById[contact]
  }

  if (contact && ! editMode) {
    return (
      <Grid container item direction='column' xs={12} md={6} spacing={2}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px'}}>
          <Typography variant='h6'>{props.title}</Typography>
          {
            props.user && props.user.role === 'admin' && (
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <a className='btn btn-icon btn-light btn-sm' style={{marginLeft: '10px'}} onClick={() => { setEditMode(true) }}>
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}/>
                  </span>
                </a>
                <a className='btn btn-icon btn-light btn-sm' style={{marginLeft: '10px'}} onClick={props.onChange.bind(null, null)}>
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
                  </span>
                </a>
              </div>
            )
          }
        </div>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <div style={{marginRight: '10px'}}><Media _id={contact.picture} /></div>
          <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, flexShrink: 1, minWidth: 0}}>
            <div>{contact.firstName + ' ' + contact.lastName}</div>
            <a href={"mailto:" + contact.email}>{contact.email}</a>
            {contact.phone && <div>{contact.phone}</div>}
            {contact.mobile && <div>{contact.mobile}</div>}
          </div>
        </div>
      </Grid>
    )
  } else {
    if (props.user && props.user.role === 'admin') {
      return (
        <Grid container item direction='column' xs={12} md={6} spacing={2}>
          <Typography variant='h6'>{props.title}</Typography>
          <Autocomplete options={props.users.map(u => u._id)} getOptionLabel={(opt) => (props.usersById[opt].firstName + ' ' + props.usersById[opt].lastName)} value={(props.contact && props.contact._id) || null} onChange={(e, u) => { props.onChange(u); setEditMode(false) }} renderInput={(params) => <TextField {...params} label="Utilisateur" variant="outlined" />} />
        </Grid>
      )
    } else {
      return (
        <Grid container item direction='column' xs={12} md={6} spacing={2}>
          <Typography variant='h6'>{props.title}</Typography>
          <Typography style={{fontStyle: 'italic'}}>Non renseigné</Typography>
        </Grid>
      )
    }

  }
}

export const Affiliates = () => {
  const [editedIndex, setEditedIndex] = useState(null)
  const [editedResource, setEditedResource] = useState(null)
  const [removedIndex, setRemovedIndex] = useState(null)
  const [creationMode, setCreationMode] = useState(false)
  const [resources, setResources] = useState(null)
  const [needsSaving, setNeedsSaving] = useState(null)
  const [users, setUsers] = useState(null)
  const [usersById, setUsersById] = useState(null)
  const [shopsByAffiliates, setShopsByAffiliates] = useState({})

  const globalData = useGlobalData()
  const user = globalData.user

  const fetch = () => {
    ajax.get('/api/users').then(r => {
      r.sort((a, b) => a.lastName.localeCompare(b.lastName))
      setUsers(r)
      let byId = {}
      r.forEach(user => { byId[user._id] = user })
      setUsersById(byId)
    })
    ajax.get('/api/shops').then(r => {
      let byAffiliates = {}
      r.sort((a, b) => a.name.localeCompare(b.name))
      r.forEach(shop => {
        if (shop.affiliate) {
          if (!byAffiliates[shop.affiliate]) byAffiliates[shop.affiliate] = []
          byAffiliates[shop.affiliate].push(shop)
        }
      })
      setShopsByAffiliates(byAffiliates)
    })
  }
  useEffect(fetch, [])

  const saveBlob = (function () {
    var a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"
    return function (blob, fileName) {
      var url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = fileName
      a.click()
      window.URL.revokeObjectURL(url)
    }
  }())

  const downloadCSV = (meeting) => {
    ajax.get('/api/affiliates/csv', {responseType: 'arraybuffer'}).then(res => {
      const blob = new Blob([res], { type: 'text/csv' })
      saveBlob(blob, 'adherents.csv')
    })
  }

  const AffiliateRow = (props) => {
    const formatPhone = (phone) => {
      let phoneBak = phone
      if (!phone || phone.length === 0) return null
      phone = phone.replace(/\s+/g, '')
      phone = phone.replace(/\./g, '')
      phone = phone.replace(/\//g, '')
      if (!phone || phone.length === 0) return null
      if (phone.length === 9 && !phone.startsWith('0')) phone = '0' + phone
      if (phone.startsWith('33')) phone = '0' + phone.slice(2)
      if (!phone.startsWith('0')) phone = '+' + phone
      if (phone.startsWith('00')) phone = '+' + phone.slice(2)
      if (phone.startsWith('+')) return phone
      if (!phone || phone.length !== 10) {
        if (phone) console.log(phone, phoneBak)
        return phoneBak
      }

      return phone.slice(0, 2) + ' ' + phone.slice(2, 4) + ' ' + phone.slice(4, 6) + ' ' + phone.slice(6, 8) + ' ' + phone.slice(8, 10)
    }

    return [
      <TableCell key='logo' style={{maxWidth: '100px'}}>{props.logo && <img height={100} src={'/api/media/thumbnail/' + props.logo._id} />}</TableCell>,
      <TableCell key='name' style={{maxWidth: '200px'}}>{props.name}</TableCell>,
      <TableCell key='manager'>
        {props.dirigeant && (
          <div className='row'>
            <div>
              <div style={{marginBottom: '7px'}}>
                {props.dirigeant.firstName + ' ' + props.dirigeant.lastName}
              </div>
              <div style={{marginBottom: '3px'}}>
                <FontAwesomeIcon icon='at' style={{marginRight: '2px'}} />
                <a href={'mailto:' + props.dirigeant.email}>{props.dirigeant.email}</a>
              </div>
              <div style={{marginBottom: '3px'}}>
                <FontAwesomeIcon icon='phone' style={{marginRight: '7px'}} />
                {formatPhone(props.dirigeant.phone) || 'Non renseigné'}
              </div>
              <div>
                <FontAwesomeIcon icon='mobile-alt' style={{width: '13px', marginRight: '7px'}} />
                {formatPhone(props.dirigeant.mobile) || 'Non renseigné'}
              </div>
            </div>
          </div>
        )}
      </TableCell>,
      <TableCell key='shopCount'>
        {(shopsByAffiliates && shopsByAffiliates[props._id]) ? shopsByAffiliates[props._id].length : 0}
      </TableCell>
    ]
  }

  const AffiliateForm = (props) => {
    return (
      <Card style={{display: 'flex', flexDirection: 'column'}}>
        <CardContent style={{flexShrink: 1, flexGrow: 1, minHeight: 0, display: 'flex', flexDirection: 'column', overflowX: 'hidden', overflowY: 'auto'}}>
          <Grid container wrap='nowrap' direction='column' spacing={5}>
            <Grid container item direction="column" alignItems='center' spacing={2}>
              {
                user.role === 'admin' && (
                  <Grid item container direction="row" spacing={2}>
                    <Grid item><Media _id={props.editedResource.logo && props.editedResource.logo._id} editable onEdit={(img) => { props.setEditedResource(prevState => ({...prevState, logo: img})); props.setNeedsSaving(true) }} /></Grid>
                    <Grid item>
                      <Button style={{marginBottom: '15px'}} variant="contained" color="primary" onClick={() => { props.setEditedResource(prevState => ({...prevState, logo: null})); props.setNeedsSaving(true) }}>
                        <FontAwesomeIcon icon='trash' style={{marginRight: '15px'}} />
                        Supprimer le logo
                      </Button>
                    </Grid>
                  </Grid>
                )
              }
              {
                user.role === 'admin'
                ? <Grid item>{props.textInput('name', 'Nom', user)}</Grid>
                : (
                  <Grid item container direction="column" alignItems='center' spacing={2}>
                    <Grid item><img width={120} height={120} style={{objectFit: 'contain', objectPosition: 'center'}} src={'/api/media/thumbnail' + props.editedResource.logo._id} /></Grid>
                    <Grid item><Typography variant='h5'>{props.editedResource.name}</Typography></Grid>
                  </Grid>
                )
              }
            </Grid>
            <Grid container item direction='row' justify='center' spacing={8}>
              <ContactForm user={user} users={users} usersById={usersById} title='Dirigeant' contact={props.editedResource.dirigeant} onChange={(contact) => { props.setEditedResource(prevState => ({...prevState, dirigeant: contact})); props.setNeedsSaving(true) }} />
              <ContactForm user={user} users={users} usersById={usersById} title='Assitant(e) de direction' contact={props.editedResource.assistantDirection} onChange={(contact) => { props.setEditedResource(prevState => ({...prevState, assistantDirection: contact})); props.setNeedsSaving(true) }} />
              <ContactForm user={user} users={users} usersById={usersById} title='Service comptabilité' contact={props.editedResource.comptabilite} onChange={(contact) => { props.setEditedResource(prevState => ({...prevState, comptabilite: contact})); props.setNeedsSaving(true) }} />
              <ContactForm user={user} users={users} usersById={usersById} title='Service commercial et marketing' contact={props.editedResource.commercial} onChange={(contact) => { props.setEditedResource(prevState => ({...prevState, commercial: contact})); props.setNeedsSaving(true) }} />
              <ContactForm user={user} users={users} usersById={usersById} title='Référent totems / mur de couleurs' contact={props.editedResource.numerique} onChange={(contact) => { props.setEditedResource(prevState => ({...prevState, numerique: contact})); props.setNeedsSaving(true) }} />
              <ContactForm user={user} users={users} usersById={usersById} title='Responsable diffusion newsletters internes' contact={props.editedResource.newsletter} onChange={(contact) => { props.setEditedResource(prevState => ({...prevState, newsletter: contact})); props.setNeedsSaving(true) }} />
            </Grid>
          </Grid>

          {
            user && user.role === 'admin'
            ? (
              <CardActions style={{marginTop: '30px'}}>
                {
                  props.needsSaving && (
                    <Button variant="contained" color="primary" onClick={props.save}>
                      Enregistrer
                    </Button>
                  )
                }
                <Button variant="contained" color="secondary" onClick={props.cancel}>
                  Annuler
                </Button>
              </CardActions>
            )
            : (
              <CardActions style={{marginTop: '30px'}}>
                <Button variant="contained" color="secondary" onClick={props.cancel}>
                  Retour à la liste
                </Button>
              </CardActions>
            )
          }
        </CardContent>
      </Card>
    )
  }

  const removeQuestion = (aff) => {
    return `Voulez-vous vraiment supprimer l'adhérent' ${aff.name} ?`
  }

  const sortAffiliates = (affiliates) => {
    affiliates.sort((a, b) => a.name.localeCompare(b.name))
  }

  const getNewAffiliate = () => {
    return ({name: '', shops: []})
  }

  if (!user || !usersById || !shopsByAffiliates) return null

  return (
    <>
      <ResourceList
        resourceType='affiliate'
        filterFields={['name']}
        addLabel="Ajouter un adhérent"
        removeLabel="Suppression d'un adhérent"
        removeQuestion={removeQuestion}
        headers={['Logo', 'Nom', 'Dirigeant', 'Nb. de magasins']}
        resourceRow={AffiliateRow}
        resourceForm={AffiliateForm}
        pictureFields={['logo']}
        getNewResource={getNewAffiliate}
        sortResources={sortAffiliates}
        downloadCSV={downloadCSV}
      />
    </>
  )
}

export default Affiliates
