import React, {useState, useEffect} from "react";
import {Formik, Field} from "formik";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {Input} from "../../../../_metronic/_partials/controls";
import * as auth from "../_redux/authRedux";
import {register} from "../_redux/authCrud";
import ajax from '../../../utils/ajax'

function Registration(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const fetch = () => {
    ajax.get('/api/user/verified/' + props.match.params.emailVerificationToken).then(res => {
      if (res.verified) {
        window.location = '/'
      }
    })
  }
  useEffect(fetch, [])

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (meta, fieldname) => {
    let result = "form-control form-control-solid h-auto py-5 px-6 ";
    if (meta.touched && meta.error) {
      result += " is-invalid"
    }

    if (meta.touched && !meta.error) {
      result += " is-valid"
    }

    return result;
  }

  return (
      <div className="login-form">
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">
            {
              props.passwordChange
              ? "NOUVEAU MOT DE PASSE"
              : <FormattedMessage id="AUTH.REGISTER.TITLE" />
            }
          </h3>
          {!props.passwordChange && <p className="text-muted font-weight-bold">Bienvenue sur l'Intranet Comptoir de l'Ours</p>}
          {!props.passwordChange && <p className="text-muted font-weight-bold">Pour activer votre accès, veuillez entrer ci-dessous un mot de passe de votre choix (min. 6 caractères).</p>}
          {props.passwordChange &&  <p className="text-muted font-weight-bold">Veuillez entrer ci-dessous un nouveau mot de passe de votre choix (min. 6 caractères).</p>}
        </div>

        <Formik
          initialValues={{
            password: "",
            confirmPassword: ""
          }}
          validate={values => {
            const errors = {};

            if (!values.password) {
              errors.password = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (values.password.length < 6) {
              errors.password = intl.formatMessage({
                id: "AUTH.VALIDATION.MIN_LENGTH_FIELD"
              });
            }

            if (!values.confirmPassword) {
              errors.confirmPassword = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (values.password !== values.confirmPassword) {
              errors.confirmPassword =
                "Les 2 mots de passe ne correspondent pas.";
            }

            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            enableLoading();

            register(
              props.match.params.emailVerificationToken,
              values.password
            )
              .then(({ data: { accessToken } }) => {
                props.register(accessToken);
                disableLoading();
              })
              .catch(() => {
                setSubmitting(false);
                disableLoading();
              });
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValidating,
            isValid
          }) => (
            <form
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
              className="form"
            >
              {status && (
                <div role="alert" className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">{status}</div>
                </div>
              )}

              <div className="form-group">
                {/* <TextField
                  type="password"
                  margin="normal"
                  label="Password"
                  className="form-control form-control-solid rounded"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                /> */}
                <Field
                  type="text"
                  name="password"
                  component={Input}
                  placeholder="Password"
                  label="Password"
                >
                  {({ field, form, meta }) => (
                      <div>
                        <input
                            type="text" {...field}
                            className={`${getInputClasses(meta)}`}
                            placeholder="Mot de passe"/>
                        {meta.touched &&
                        meta.error && <div className="error invalid-feedback">{meta.error}</div>}
                      </div>
                  )}
                </Field>
              </div>

              <div className="form-group">
                {/* <TextField
                  type="password"
                  margin="normal"
                  label="Confirm Password"
                  className="form-control form-control-solid rounded"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirmPassword}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                /> */}
                <Field
                  type="text"
                  name="confirmPassword"
                  component={Input}
                  placeholder="Confirm Password"
                  label="Confirm Password"
                >
                  {({ field, form, meta }) => (
                      <div>
                        <input
                            type="text" {...field}
                            className={`${getInputClasses(meta)}`}
                            placeholder="Confirmer le mot de passe"/>
                        {meta.touched &&
                        meta.error && <div className="error invalid-feedback">{meta.error}</div>}
                      </div>
                  )}
                </Field>
              </div>

              <div className="form-group d-flex flex-wrap flex-center">
                <button
                    type="submit"
                    disabled={isSubmitting || isValidating || !isValid || !values.password}
                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  <span className={`${loading ? "pr-3" : ""}`}>Valider</span>
                  {loading && (
                      <span className="spinner-border text-light"></span>
                  )}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
