import React, {useState, useEffect, useRef} from "react";
import Media from '../components/Media/Media'
import {Radio, RadioGroup, FormControl, FormControlLabel, FormGroup, FormLabel, Table, TableHead, TableRow, TableBody, TableContainer, TextField, Grid, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Card, CardContent, CardActions, CardAvatar, CardHeader, Typography, Button, TableCell, List, ListItem, ListItemAvatar, ListItemText, Avatar, Divider, ListItemIcon, ListItemSecondaryAction, Checkbox} from '@material-ui/core'
import { AvatarGroup, TabPanel } from '@material-ui/lab'
import ResourceList from '../components/ResourceList/ResourceList'
import ajax from '../utils/ajax'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../_metronic/_helpers";
import async from 'async'
import {Modal} from 'react-bootstrap';
import {Tabs, Tab} from 'react-bootstrap'
import {AddressBook} from './AddressBook'

const saveBlob = (function () {
  var a = document.createElement("a")
  document.body.appendChild(a)
  a.style = "display: none"
  return function (blob, fileName) {
    var url = window.URL.createObjectURL(blob)
    a.href = url
    //a.target = '_blank'
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  }
}())

const mobileSize = 720

export const Suppliers = () => {
  const [suppliers, setSuppliers] = useState(null)
  const [documentsToAdd, setDocumentsToAdd] = useState([])
  const fetch = () => {

  }
  useEffect(fetch, [])

  const downloadPDF = (url, title) => {
    ajax.get(url, {responseType: 'arraybuffer'}).then(res => {
      const blob = new Blob([res], { type: 'application/pdf' })
      saveBlob(blob, title + '.pdf')
    })
  }

  const FRForm = (props) => {
    const handleChange = e => {
      const { name, value } = e.target;
      props.setFR(prevState => ({...prevState, [name]: value}))
    }

    const toggle = e => {
      const {name} = e.target
      props.setFR(prevState => ({...prevState, [name]: !props.FR[name]}))
    }

    const textInput = (field, placeholder, fullWidth) => {
      return <TextField size='small' fullWidth={fullWidth} label={placeholder} variant='outlined' value={props.FR[field] || ''} name={field} onChange={handleChange} />
    }

    const numericInput = (field, placeholder, fullWidth) => {
      return <TextField size='small' type='number' fullWidth={fullWidth} label={placeholder} variant='outlined' value={props.FR[field] || ''} name={field} onChange={handleChange} />
    }

    const areaInput = (field, placeholder, fullWidth) => {
      return <TextField size='small' multiline rows={10} fullWidth={fullWidth} label={placeholder} variant='outlined' value={props.FR[field] || ''} name={field} onChange={handleChange} />
    }

    return (
      <Modal size="xl" scrollable show={props.show}>
        <Modal.Header>
          <Modal.Title>Fiche référencement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid container item direction='column' spacing={2}>
            <Grid item>{numericInput('year', 'Année')}</Grid>

            <Grid item style={{marginTop: '30px'}}><Typography variant='h6'>Conditions Générales de Vente</Typography></Grid>
            <Grid item>{areaInput('remisesTarifGeneral', 'Remises sur tarif général', true)}</Grid>
            <Grid item>{areaInput('tarifsPrixNets', 'Tarifs prix nets', true)}</Grid>
            <Grid item>{areaInput('hausses', 'Hausses de tarif', true)}</Grid>
            <Grid item>{areaInput('baisses', 'Baisses de tarif', true)}</Grid>
            <Grid container item direction='row' spacing={2}>
              <Grid item>{textInput('franco', 'Franco', true)}</Grid>
              <Grid item>{textInput('francoCorse', 'Franco Corse', true)}</Grid>
            </Grid>
            <Grid item>{textInput('fraisPort', 'Frais de port', true)}</Grid>
            <Grid item>{textInput('commandeMin', 'Commande minimum', true)}</Grid>
            <Grid item>{textInput('delaiLivraison', 'Délai de livraison', true)}</Grid>
            <Grid item>{textInput('delaiReglement', 'Délais de règlement', true)}</Grid>
            <Grid item>{textInput('escompte', 'Escompte accordé', true)}</Grid>
            <Grid item>{textInput('remiseImplantation', "Remise d'implantation", true)}</Grid>

            <Grid item style={{marginTop: '30px'}}><Typography variant='h6'>BFA accordées aux distributeurs CDO - HBF</Typography></Grid>
            <Grid item>{areaInput('remisesDistributeur', 'Remises distributeur', true)}</Grid>

            <Grid item style={{marginTop: '30px'}}><Typography variant='h6'>Prestations accordées à la centrale HBF</Typography></Grid>
            <Grid item>{numericInput('gestionReferencement', 'Gestion référencement (%)')}</Grid>

            <Grid item style={{marginTop: '30px'}}><Typography variant='h6'>Autres prestations</Typography></Grid>
            <Grid container item direction='row' spacing={2}>
              <Grid item>
                <FormControl>
                  <FormLabel>Participation à l'animation des totems</FormLabel>
                  <RadioGroup name='trimestresTotems' onChange={handleChange} value={Number(props.FR.trimestresTotems)}>
                    <FormControlLabel value={0} control={<Radio />} label='Non' />
                    <FormControlLabel value={1} control={<Radio />} label='1 trimestre (300 € HT)' />
                    <FormControlLabel value={2} control={<Radio />} label='2 trimestres (500 € HT)' />
                    <FormControlLabel value={4} control={<Radio />} label='4 trimestres (900 € HT)' />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid container item direction='column' spacing={2}>
                <Grid item>
                  <FormControl>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox name='salonHBF' checked={props.FR.salonHBF} onChange={toggle} />} label='Participation au salon HBF' />
                      <FormControlLabel control={<Checkbox name='catalogueAccessoires' checked={props.FR.catalogueAccessoires} onChange={toggle} />} label='Participation au catalogue accessoires' />
                      <FormControlLabel control={<Checkbox name='syneo' checked={props.FR.syneo} onChange={toggle} />} label='Fournisseur Syneo' />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item>{textInput('amenagementCDO', "Participation mètre linéraire aménagement intérieur CDO", true)}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{marginRight: '10px'}} variant="contained" color="secondary" onClick={props.close}>Annuler</Button>
          <Button variant="contained" color="primary" onClick={() => { props.save(); props.close() }}>Enregistrer</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const DocumentInput = (props) => {
    const fileInputRef = useRef()
    const [FRChoiceVisible, setFRChoiceVisible] = useState(false)
    const [FRFormVisible, setFRFormVisible] = useState(false)
    const [FR, setFR] = useState(null)

    const openFileInput = () => {
      if (fileInputRef) {
        fileInputRef.current.click()
      }
    }

    const showFRChoice = () => { setFRChoiceVisible(true) }
    const createFR = () => {
      setFR({
        year: new Date().getFullYear() + 1,
        remisesTarifGeneral: '',
        tarifsPrixNets: '',
        hausses: '',
        baisses: '',
        franco: '',
        francoCorse: '',
        fraisPort: '',
        commandeMin: '',
        delaiLivraison: '',
        modeReglement: '',
        delaiReglement: '',
        escompte: '',
        remiseImplantation: '',
        remisesDistributeur: '',
        gestionReferencement: 3,
        trimestresTotems: 0,
        salonHBF: false,
        catalogueAccessoires: false,
        amenagementCDO: '',
        syneo: false
      })
      setFRFormVisible(true)
    }

    const addFR = () => {
      let docs = [...documentsToAdd]
      docs.push({
        type: 'fiches-de-referencement',
        date: new Date(),
        title: 'Fiche de référencement ' + FR.year,
        extension: 'pdf',
        FR: FR,
        id: new Date().getTime()
      })
      setDocumentsToAdd(docs)
      props.setNeedsSaving(true)
    }

    return (
      <>
        <a className='btn btn-icon btn-light btn-sm' onClick={props.type === 'fiches-de-referencement' ? showFRChoice : openFileInput}>
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}/>
          </span>
        </a>
        <input type='file' ref={fileInputRef} style={{display: 'none'}} onChange={props.onChange} />
        {
          props.type === 'fiches-de-referencement' && (
            <Modal show={FRChoiceVisible}>
              <Modal.Header>
                <Modal.Title>Ajout d'une fiche référencement</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Grid container item direction='column' alignItems='center' spacing={2}>
                  <Grid item>
                    <Button variant="contained" color="primary" onClick={() => { setFRChoiceVisible(false); openFileInput() }}>
                      <FontAwesomeIcon icon='file' style={{marginRight: '15px'}} />
                      Importer un fichier
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary" onClick={() => { setFRChoiceVisible(false); createFR()}}>
                      <FontAwesomeIcon icon='pen' style={{marginRight: '15px'}} />
                      Saisir dans un formulaire
                    </Button>
                  </Grid>
                </Grid>
              </Modal.Body>
            </Modal>
          )
        }
        {
          props.type === 'fiches-de-referencement' && (
            <FRForm new show={FRFormVisible} FR={FR || {}} setFR={setFR} close={setFRFormVisible.bind(null, false)} save={addFR} />
          )
        }
      </>
    )
  }

  const SupplierRow = (props) => {
    let website = ''
    if (props.mainContact && props.mainContact.website) {
      website = props.mainContact.website
      if (!website.startsWith('http')) {
        website = 'http://' + website
      }
    }
    return [
      <TableCell key='logo' style={{maxWidth: '50%'}}>{props.logo && <img style={{maxWidth: '260px', minWidth: 0, maxHeight: '70px', minHeight: 0, objectFit: 'contain', objectPosition: 'center'}} src={'/api/media/thumbnail/' + props.logo} />}</TableCell>,
      <TableCell key='name'>{props.name}</TableCell>,
      <TableCell key='website'>
        {props.mainContact && props.mainContact.website && <a href={website} target='_blank'>Site web</a>}
      </TableCell>,
      <TableCell key='logoSyneo' style={{maxWidth: '50%'}}>
        {props.logoSyneo && (
          <>

            <img style={{maxWidth: '260px', minWidth: 0, maxHeight: '70px', minHeight: 0, objectFit: 'contain', objectPosition: 'center'}} src={'/api/media/thumbnail/' + props.logoSyneo} />
          </>
        )}
      </TableCell>
    ]
  }

  const SupplierListItem = (props) => {
    let website = ''
    if (props.mainContact && props.mainContact.website) {
      website = props.mainContact.website
      if (!website.startsWith('http')) {
        website = 'http://' + website
      }
    }

    return (
      <ListItem style={{height: '77px', paddingRight: 0, cursor: 'pointer'}} onClick={props.onClick}>
        <ListItemText style={{width: '60%'}} primary={props.name} secondary={website.length > 0 ? <a href={website}>Site web</a> : undefined} />
        <ListItemSecondaryAction style={{paddingTop: '8px', paddingBottom: '8px', boxSizing: 'border-box', width: '40%', height: '100%', display: 'flex', flexDirection: 'row-reverse'}} onClick={props.onClick}>
          <div style={{width: '50%', height: '100%', backgroundPosition: 'center right', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundImage: 'url(/api/media/' + props.logo + ')'}} />
          <div style={{width: '50%', height: '100%', backgroundPosition: 'center right', marginRight: '5px', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundImage: 'url(/api/media/' + props.logoSyneo + ')'}} />
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  const SupplierForm = (props) => {
    const [editedFile, setEditedFile] = useState(null)
    const [FR, setFR] = useState({})
    const [fileCategoryOpen, setFileCategoryOpen] = useState({})
    const [fileFilter, setFileFilter] = useState('')

    const updateArrayElement = (field, i, e) => {
      const { name, value } = e.target;
      let array = [...props.editedResource[field]]
      array[i] = value
      props.setEditedResource(prevState => ({...prevState, [field]: array}))
      props.setNeedsSaving(true)
    }

    const addFile = (type) => {
      let items = [...props.editedResource.items, '']
      let reports = [...props.editedResource.reports, '']
      props.setEditedResource(prevState => ({...prevState, items: items, reports: reports}))
      props.setNeedsSaving(true)
    }

    const removeFile = (type, file) => {
      if (file._id) {
        let files = [...props.editedResource.files.filter(f => f._id !== file._id)]
        props.setEditedResource(prevState => ({...prevState, files: files}))
        props.setNeedsSaving(true)
      } else {
        let docsToAdd = [...documentsToAdd.filter(d => d.id !== file.id)]
        setDocumentsToAdd(docsToAdd)
        props.setNeedsSaving(true)
      }
    }

    const saveFR = () => {
      let doc
      let docIndex = documentsToAdd.findIndex(d => d.id === editedFile.id)
      if (docIndex < 0) {
        docIndex = props.editedResource.files.findIndex(d => d._id === editedFile._id)
        doc = {...props.editedResource.files[docIndex]}
      } else {
        doc = {...documentsToAdd[docIndex]}
      }
      doc.FR = FR

      if (editedFile._id) {
        let docs = [...props.editedResource.files]
        docs[docIndex] = doc
        props.setEditedResource(prevState => ({...prevState, files: docs}))
      } else {
        let docs = [...documentsToAdd]
        docs[docIndex] = doc
        setDocumentsToAdd(docs)
      }

      setEditedFile(null)
      props.setNeedsSaving(true)
    }

    const renderFiles = (type, files) => {
      if (files.length === 0) return <div style={{marginBottom: '30px', fontStyle: 'italic', marginLeft: '30px'}}>Aucun document</div>

      return (
        <>
          <TableContainer key={type + '_files'} style={{marginBottom: '30px'}}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{width: '110px', paddingRight: 0}}>DATE D'AJOUT</TableCell>
                  <TableCell>DOCUMENT</TableCell>
                  {(props.user.role === 'admin' || props.user.role === 'formateur') && <TableCell style={{width: '150px'}}></TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {files.map((f, i) => (
                  <TableRow>
                    <TableCell style={{paddingRight: 0}}>{new Date(f.date).toLocaleDateString()}</TableCell>
                    <TableCell>
                      {
                        f.media
                        ? <a href={(process.env.REACT_APP_BASE_URL || 'http://localhost:3001') + '/api/supplier/' + props.editedResource._id + '/download/' + f.media + '/' + encodeURIComponent(f.title)} target='_blank' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 0}}>
                            {(f.extension.toLowerCase() === 'xls' || f.extension.toLowerCase() === 'xlsx' || f.extension.toLowerCase() === 'xlsm') && <FontAwesomeIcon icon='file-excel' style={{color: 'black', fontSize: '16px', marginRight: '10px'}} />}
                            {(f.extension.toLowerCase() === 'pdf') && <FontAwesomeIcon icon='file-pdf' style={{color: 'black', fontSize: '16px', marginRight: '10px'}} />}
                            {(f.extension.toLowerCase() === 'doc' || f.extension.toLowerCase() === 'docx') && <FontAwesomeIcon icon='file-word' style={{color: 'black', fontSize: '16px', marginRight: '10px'}} />}
                            {f.title}
                          </a>
                        : (f._id
                          ? (
                            <a style={{cursor: 'pointer', color: '#6993FF'}} target="_blank" href={(process.env.REACT_APP_BASE_URL || 'http://localhost:3001') + '/api/supplier/' + props.editedResource._id + '/fr/' + f._id + '/' + (f.file ? f.file.name : f.title) + ' - ' + props.editedResource.name}>
                              <FontAwesomeIcon icon='file-pdf' style={{color: 'black', fontSize: '16px', marginRight: '10px'}} />
                              {(f.file ? f.file.name : f.title)}
                            </a>
                          )
                          : (f.file ? f.file.name : f.title)
                        )
                      }
                    </TableCell>
                    {
                      (props.user.role === 'admin' || props.user.role === 'formateur') && (
                        <TableCell>
                          <>
                            <a className='btn btn-icon btn-light btn-sm' style={{visibility: f.FR ? undefined : 'hidden'}} onClick={() => { setEditedFile(f); setFR({...f.FR}) }}>
                              <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}/>
                              </span>
                            </a>
                            <a className='btn btn-icon btn-light btn-sm' onClick={removeFile.bind(null, type, f)}>
                              <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
                              </span>
                            </a>
                          </>
                        </TableCell>
                      )
                    }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <FRForm new show={editedFile !== null} FR={FR} setFR={setFR} close={setEditedFile.bind(null, null)} save={saveFR} />
        </>
      )
    }

    const renderContactsTab = () => {
      return <AddressBook suppliers supplier={props.editedResource._id} />
    }

    const fileTypes = {
      'fiches-de-referencement': "Fiches de référencement",
      'catalogues-produits': "Catalogues",
      'fiches-tarifaires': "Fiches tarifaires",
      'fiches-techniques': 'Fiches techniques',
      "fiches-de-donnees-de-securite": "Fiches de données de sécurité",
      'autre-fichier': "Autres fichiers"
    }

    const addDocument = (type, e) => {
      let docs = [...documentsToAdd]
      for (let file of e.target.files) {
        let date = new Date()
        docs.push({file: file, type: type, date: date, id: date.getTime()})
      }
      setDocumentsToAdd(docs)
      props.setNeedsSaving(true)
    }

    const toggleFileCategory = (cat) => {
      let open = {...fileCategoryOpen}
      open[cat] = !open[cat]
      setFileCategoryOpen(open)
    }

    const renderFilesTab = () => {
      return (
        <div style={{display: 'flex', flexDirection: 'column', padding: '20px'}}>
          {Object.keys(fileTypes).map(type => {
            if (type === "fiches-de-referencement" && props.user.role !== 'admin' && props.user.role !== "adherent" && props.user.role !== "commercial+fr" && props.user.role !== "formateur" && props.user.role !== "admin") return null
            const toggle = toggleFileCategory.bind(null, type)

            let resourceFiles = props.editedResource.files || []
            resourceFiles = [...resourceFiles]
            resourceFiles = resourceFiles.map((f, i) => {return {...f, index: i} })
            let rgx = new RegExp(fileFilter, 'gi')
            let files = documentsToAdd.filter(file => (file.type === type)).reverse()
            if (type === 'fiches-techniques' || type === "fiches-de-donnees-de-securite") {
              files = files.concat(...resourceFiles.filter(f => f.type === type).sort((a, b) => { return a.title.localeCompare(b.title) }))
            } else {
              files = files.concat(...resourceFiles.filter(f => f.type === type).sort((a, b) => { return new Date(b.date).getTime() - new Date(a.date).getTime()}))
            }
            files = files.filter(f => (fileFilter.length === 0 || (f.name && f.name.match(rgx)) || (f.title && f.title.match(rgx))))

            if (props.user.role !== 'admin' && props.user.role !== "formateur" && files.length === 0) return null
            return (
              <>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  {
                    (props.user.role !== 'admin' && props.user.role !== "formateur") && (
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', marginBottom: '20px'}} onClick={toggle}>
                        {fileFilter.length === 0 && <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")} style={{transform: fileCategoryOpen[type] ? 'rotate(90deg)' : 'rotate(0)', transition: 'transform 0.2s linear'}} />}
                        <Typography variant='h6'>{fileTypes[type]}</Typography>
                        <div className='fileCount'>{}</div>
                      </div>
                    )
                  }

                  {
                    (props.user.role === 'admin' || props.user.role === "formateur") && (
                      (
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', marginBottom: '20px'}}>
                          {fileFilter.length === 0 && <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")} onClick={toggle} style={{transform: fileCategoryOpen[type] ? 'rotate(90deg)' : 'rotate(0)', transition: 'transform 0.2s linear'}} />}
                          <Typography style={{marginRight: '10px'}} onClick={toggle} variant='h6'>{fileTypes[type]}</Typography>
                          {fileCategoryOpen[type] && <DocumentInput type={type} onChange={addDocument.bind(null, type)} setNeedsSaving={props.setNeedsSaving} />}
                        </div>
                      )

                    )
                  }
                </div>
                {(fileFilter.length > 0 || fileCategoryOpen[type]) && renderFiles(type, files)}
              </>
            )
          })}

          {
            (props.user.role === 'admin' || props.user.role === 'formateur')
            && (
              <CardActions style={{marginTop: '30px'}}>
                {
                  props.needsSaving && (
                    <Button variant="contained" color="primary" onClick={() => {
                      props.save(() => {
                        setDocumentsToAdd([])
                      })
                    }}>
                      Enregistrer
                    </Button>
                  )
                }
                <Button variant="contained" color="secondary" onClick={() => {
                    props.cancel()
                    setDocumentsToAdd([])
                }}>
                  Annuler
                </Button>
              </CardActions>
            )
          }
        </div>
      )
    }

    return (
      <Card style={props.style}>
        {
          props.user.role !== 'admin' && (
            <CardHeader
              avatar={<img width={120} style={{objectFit: 'contain', objectPosition: 'center', height: 'auto'}} src={"/api/media/thumbnail/" + props.editedResource.logo} />}
              title={<Typography variant='h5'>{props.editedResource.name}</Typography>}
            />
          )
        }
        <CardContent>
          <Grid container item direction='column' spacing={5}>
            <Grid container item direction="column" alignItems={props.user.role === 'admin' ? 'center' : 'flex-start'} spacing={2}>
              {
                props.user.role === 'admin'
                ? (
                  <>
                    <Grid item container direction="row" alignItems='center' style={{justifyContent: "center"}} spacing={2}>
                      <Grid item direction="column">
                        <Grid item style={{textAlign: "center"}}>Logo</Grid>
                        <Grid item><Media _id={props.editedResource.logo}
                          editable
                          onEdit={(img) => { props.setEditedResource(prevState => ({...prevState, logo: img})); props.setReadyToSave(false); props.setNeedsSaving(true) }}
                          onRemove={(img) => { props.setEditedResource(prevState => ({...prevState, logo: null})); props.setReadyToSave(false); props.setNeedsSaving(true) }}
                        /></Grid>
                      </Grid>
                      <Grid item direction="column">
                        <Grid item style={{textAlign: "center"}}>Logo Syneo</Grid>
                        <Grid item><Media _id={props.editedResource.logoSyneo}
                          editable onEdit={(img) => { props.setEditedResource(prevState => ({...prevState, logoSyneo: img})); props.setReadyToSave(false); props.setNeedsSaving(true) }}
                          onRemove={(img) => { props.setEditedResource(prevState => ({...prevState, logoSyneo: null})); props.setReadyToSave(false); props.setNeedsSaving(true) }}
                        /></Grid>
                      </Grid>
                    </Grid>
                    <Grid item>{props.textInput('name', 'Nom')}</Grid>
                  </>
                )
                : (
                  props.editedResource.logoSyneo && (<img width={60} style={{objectFit: 'contain', objectPosition: 'center', height: 'auto', marginLeft: '20px'}} src={"/api/media/thumbnail/" + props.editedResource.logoSyneo} />)
                )
              }

            </Grid>
            <Grid>
              <Tabs defaultActiveKey="files" style={{padding: '20px', paddingBottom: 0}}>
                <Tab eventKey='files' title='Documents'>
                  <TextField style={{margin: '20px'}} type='search' label={"Rechercher..."} size='small' variant='outlined' value={fileFilter || ''} onChange={(e) => {setFileFilter(e.target.value)}} onKeyDown={e => {if (e.key === 'Enter') e.target.blur()}} />
                  {renderFilesTab()}
                </Tab>
                <Tab eventKey='contacts' title='Contacts' disabled={props.needsSaving}>
                  {renderContactsTab()}
                </Tab>
              </Tabs>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  const getNewSupplier = () => {
    return {contacts: [], files: [], name: "Nouveau fournisseur"}
  }

  const removeQuestion = (c) => {
    return `Voulez-vous vraiment supprimer le fournisseur ${c.name} ?`
  }

  const sortSuppliers = (suppliers) => {
    suppliers.sort((a, b) => a.name.localeCompare(b.name))
  }

  return (
    <ResourceList
      resourceType='supplier'
      filterFields={['name']}
      addLabel="Ajouter un fournisseur"
      removeLabel="Suppression d'un fournisseur"
      removeQuestion={removeQuestion}
      headers={['Logo', 'Nom', 'Site web', "Logo Syneo"]}
      resourceRow={SupplierRow}
      resourceListItem={SupplierListItem}
      resourceForm={SupplierForm}
      pictureFields={['logo', 'logoSyneo']}
      getNewResource={getNewSupplier}
      sortResources={sortSuppliers}
      documentsToAdd={documentsToAdd}
    />
  )
}

export default Suppliers
