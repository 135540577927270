import React, {useState, useEffect} from "react";
import ajax from '../utils/ajax'
import {TableCell, Button, Grid, Card, Typography, CardContent, CardActions, CardHeader, CardMedia, Avatar} from '@material-ui/core'
import { useSubheader } from "../../_metronic/layout"
import './Article.scss'

export const Article = (props) => {
  const [article, setArticle] = useState(null)
  const subheader = useSubheader();

  const fetch = () => {
    ajax.get('/api/article/' + props.match.params.id).then(r => {
      let breadcrumbs = []
      breadcrumbs.push({
          text: 'Accueil',
          title: 'Accueil',
          title: 'Accueil',
          href: '/'
      })
      breadcrumbs.push({
          text: r.title,
          title: r.title,
          href: '/article/' + props.match.params.id
      });
      subheader.setBreadcrumbs(breadcrumbs)
      subheader.setTitle(r.title)
      setArticle(r)

      ajax.post('/api/article/view', {_id: props.match.params.id}).then(r => {})
    })
  }
  useEffect(fetch, [])

  if (!article) return null

  return (
    <>
      <Card style={{flexGrow: 0, flexShrink: 0}}>
        <CardHeader
          avatar={<Avatar alt={article.author.firstName + ' ' + article.author.lastName.toUpperCase()} src={"/api/media/thumbnail/" + article.author.picture} />}
          title={article.author.firstName + ' ' + article.author.lastName.toUpperCase()}
          subheader={new Date(article.date).toLocaleDateString()}
          style={{padding: '8px'}}
        />
      </Card>

      <Card style={{overflowY: 'auto', flexGrow: 1, flexShrink: 1, minHeight: 0, overflowY: 'auto', marginTop: '10px'}}>
        <CardContent style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap-reverse'}}>
          {
            article.picture && (
              <img
                src={'/api/media/' + article.picture}
                style={{minWidth: '300px', maxWidth: '450px', width: '40%', height: 'auto', marginRight: '10px', marginBottom: '10px'}}
              />
            )
          }
          <div dangerouslySetInnerHTML={{__html: article.content.replace(/src=\"api/g, "src=\"https://intranetv2.comptoirdelours.fr/api")}} className='ArticleContent' />
        </CardContent>
      </Card>

      {
        props.validation && (
          <Card style={{marginTop: '10px', flexShrink: 0, flexGrow: 0}}>
            <CardActions>
              <Button variant="contained" color="primary" onClick={props.validate}>
                Valider
              </Button>
              <Button variant="contained" color="secondary" onClick={props.reject}>
                Rejeter
              </Button>
            </CardActions>
          </Card>
        )
      }
    </>
  )
}
