import React, {useState, useEffect} from "react"
import {Form, Modal} from 'react-bootstrap';
import {TextField, Grid, Divider, Paper, Card, CardContent, CardActions, Button, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography, List, Select, MenuItem} from '@material-ui/core'
import {Tabs, Tab} from 'react-bootstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SVG from "react-inlinesvg";
import ajax from '../../utils/ajax'
import './ResourceList.scss'
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import async from 'async'
import {useGlobalData} from '../../hooks/useGlobalData'
import {useWindowSize} from '../../hooks/useWindowSize'
import {withRouter} from "react-router-dom"
import { isValid } from "date-fns";

library.add(fas)

const mobileSize = 720

export const ResourceList = (props) => {
  const [editedResource, setEditedResource] = useState(null)
  const [removedItem, setRemovedItem] = useState(null)
  const [creationMode, setCreationMode] = useState(false)
  const [resources, setResources] = useState(null)
  const [needsSaving, setNeedsSaving] = useState(null)
  const [readyToSave, setReadyToSave] = useState(true)
  const [filter, setFilter] = useState('')
  const [filteredResources, setFilteredResources] = useState([])
  const [segmentationValue, setSegmentationValue] = useState(false)
  const [validating, setValidating] = useState(false)
  const [validatingType, setValidatingType] = useState(null)
  const [rejecting, setRejecting] = useState(false)
  const [rejectCause, setRejectCause] = useState("")
  const [month, setMonth] = useState(new Date().getMonth())
  const [year, setYear] = useState(new Date().getFullYear())
  const windowSize = useWindowSize()

  const globalData = useGlobalData()
  const user = globalData.user

  const fetch = () => {
    if (!user) return

    if (props.getApi) {
      ajax.get('/api/' + props.getApi).then(r => {
        if (props.sortResources) {
          props.sortResources(r)
        }
        setResources(r)
      })
    }
    else if (user.role === 'admin' || !props.normalUserApi) {
      ajax.get('/api/' + props.resourceType + 's').then(r => {
        if (props.sortResources) {
          props.sortResources(r)
        }
        setResources(r)
      })
    } else {
      ajax.get('/api/' + props.normalUserApi).then(r => {
        if (props.sortResources) {
          props.sortResources(r)
        }
        setResources(r)
      })
    }
  }
  useEffect(fetch, [user])

  const fetchSingle = (id) => {

    if (!user) return

    let url = ''
    if (props.getApi) {
      url = '/api/' + props.getApi + '/' + id
    }
    else if (user.role === 'admin' || !props.normalUserApi) {
      url = '/api/' + props.resourceType + '/' + id
    } else {
      url = '/api/' + props.normalUserApi + '/' + id
    }

    ajax.get(url).then(r => {
      let res = [...resources]
      for (let i = 0; i < res.length; ++i) {
        console.log(r)
        if (res[i]._id === r[0]._id) {
          res[i] = r[0]
          break
        }
      }
      console.log(res)

      if (props.sortResources) {
        props.sortResources(res)
      }
      setResources(res)
    })
  }

  const handleURL = () => {
    if (!resources || props.noNavigation) return

    let path = props.history.location.pathname.split('/').filter(elt => elt !== '')
    if (path.length === 2) {
      if (path[1] === 'create') {
        setEditedResource(props.getNewResource ? props.getNewResource() : {})
        setCreationMode(true)
      } else if (/^[a-f\d]{24}$/i.test(path[1])) {
        const resource = resources.find(r => r._id === path[1])
        if (resource) setEditedResource(resource)
      }
    } else if (path.length === 1) {
      setNeedsSaving(false)
      setCreationMode(false)
      setEditedResource(null)
    }
  }
  useEffect(() => {
    handleURL()
    return props.history.listen(handleURL)
  }, [resources])

  const saveMedia = (fieldPath, cb) => {
    let path = fieldPath.split('.')
    let obj = editedResource
    console.log(path, obj)
    path.forEach(field => { obj = (obj && obj[field]) })
    if (!obj || typeof obj === 'string') {
      cb(null)
      return
    }

    let data = new FormData()
    data.append('file', obj)
    ajax.put('/api/media', data).then((media) => {
      cb(media._id)
    })
  }

  const saveMedias = (res, cb) => {
    if (!props.pictureFields) {
      cb(res)
      return
    }

    async.forEachSeries(props.pictureFields, (fieldPath, cb2) => {
      saveMedia(fieldPath, (mediaId) => {
        if (mediaId) {
          console.log(mediaId)
          let path = fieldPath.split('.')
          let obj = res
          for (let i = 0; i < path.length - 1; ++i) {
            obj = obj[path[i]]
          }
          obj[path[path.length - 1]] = mediaId
        }
        cb2()
      })
    }, () => {
      cb(res)
    })
  }

  const saveDocuments = (cb) => {
    let res = {...editedResource}
    if (!props.documentsToAdd) {
      cb(res)
      return
    }

    let files = res.files ? [...res.files] : []

    async.forEachSeries(props.documentsToAdd, (doc, cb2) => {
      if (!doc.file) {
        files.push(doc)
        cb2()
        return
      }

      let data = new FormData()
      data.append('file', doc.file)
      ajax.put('/api/document', data).then((media) => {
        let nameSplit = doc.file.name.split('.')
        let extension = ''
        let name = doc.file.name
        if (nameSplit.length > 1) {
          extension = nameSplit.pop()
          name = nameSplit.join('')
        }
        files.push({type: doc.type, media: media._id, date: new Date(), title: name, extension: extension})
        cb2()
      })
    }, () => {
      let res = {...editedResource}
      res.files = files
      cb(res)
    })

  }

  const save = (cb) => {
    saveDocuments((res) => {
      saveMedias(res, (res) => {
        const method = creationMode ? ajax.put : ajax.post
        if (!creationMode && props.excludeFromPost) {
          for (let key of props.excludeFromPost) {
            delete res[key]
          }
        }
        method('/api/' + props.resourceType, res).then(() => {
          fetch()
          if (props.noNavigation) {
            setNeedsSaving(false)
            setCreationMode(false)
            setEditedResource(null)
          } else {
            const path = props.history.location.pathname.split('/').filter(elt => elt !== '')
            props.history.push('/' + path[0])
          }
          if (cb && typeof cb === 'function') cb()
        })
      })
    })
  }

  useEffect(() => {
    if (!resources) return
    if (filter === '' && !props.dateIndex && !props.tabSegmentation) {
      setFilteredResources(resources)
      return
    }
    let filtered = []
    let rgx = new RegExp(filter, 'gi')
    let fields = props.filterFields
    for (let r of resources) {
      let found = false
      for (let field of fields) {
        let parts = field.split('.')
        let value = r
        parts.forEach(key => {if (value[key]) value = value[key] })
        if (typeof value === 'string' && value.match(rgx)) {
          filtered.push(r)
          found = true
          break
        }
        if (found) continue
      }
    }
    if (props.dateIndex) {
      filtered = filtered.filter(r => new Date(r.date).getMonth() === month && new Date(r.date).getFullYear() === year)
    }
    if (props.tabSegmentation) {
      filtered = filtered.filter(r => {
        let val = r[props.tabSegmentation[0]]
        if (val === null || val === undefined) val = false
        return (val === segmentationValue)
      })
    }

    setFilteredResources(filtered)
  }, [filter, month, year, resources, segmentationValue])

  const edit = (res) => {
    if (props.noNavigation) {
      setEditedResource(res)
    } else {
      const path = props.history.location.pathname.split('/').filter(elt => elt !== '')
      props.history.push('/' + [path[0], res._id].join('/'))
    }
  }

  const cancel = () => {
    if (props.noNavigation) {
      setNeedsSaving(false)
      setCreationMode(false)
      setEditedResource(null)
    } else {
      const path = props.history.location.pathname.split('/').filter(elt => elt !== '')
      props.history.push('/' + path[0])
    }
  }

  const remove = () => {
    ajax.delete('/api/' + props.resourceType + '/' + removedItem._id).then(() => {
      fetch()
      setRemovedItem(null)
    })
  }
  const create = () => {
    if (props.noNavigation) {
      setEditedResource(props.getNewResource ? props.getNewResource() : {})
      setCreationMode(true)
    } else {
      const path = props.history.location.pathname.split('/').filter(elt => elt !== '')
      props.history.push('/' + [path[0], 'create'].join('/'))
    }
  }

  const validate = () => {
    ajax.post('/api/validation/validate', {id: validating, type: validatingType}).then(r => {
      setValidating(false)
      cancel()
      fetch()
      globalData.fetch()
    })
  }

  const reject = () => {
    ajax.post('/api/validation/reject', {id: rejecting, rejectCause: rejectCause, type: validatingType}).then(r => {
      setRejectCause("")
      setRejecting(false)
      cancel()
      fetch()
      globalData.fetch()
    })
  }

  const renderTable = () => {
    return (
      <TableContainer style={{flexShrink: 1, flexGrow: 1, minHeight: 0}}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {props.headers.map((label, i) => <TableCell key={i} style={{zIndex: 100}}>{label.toUpperCase()}</TableCell>)}
              {
                (!props.noActions && (user.role === 'admin' || !props.noNormalUserAction)) && (
                  <TableCell style={{zIndex: 100}}>{props.actionsLabel || "ACTIONS"}</TableCell>
                )
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredResources.map((r, i) => (
              <TableRow key={r._id}>
                {
                  <props.resourceRow {...r} fetchSingle={fetchSingle}
                    edit={edit.bind(null, r)}
                    validate={(type) => {console.log(type); setValidating(r._id); setValidatingType(type)}}
                    reject={(type) => {console.log(type); setRejecting(r._id); setValidatingType(type)}}
                    fetch={fetch}
                    user={user}
                  />
                }
                {
                  (!props.noActions && (user.role === 'admin' || !props.noNormalUserAction)) && (
                    ((user && user.role === 'admin') || (user && user.role === 'marketing' && props.allowMarketing) || (props.editMineOnly && r.author && r.author._id === user._id) || (props.editMineOnly && user.affiliate.indexOf(r.affiliate) >= 0))
                    ? (
                      <TableCell>
                        <a className='btn btn-icon btn-light btn-sm' onClick={edit.bind(null, r)}>
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}/>
                          </span>
                        </a>
                        {
                          !(props.noDelete && !user.role === 'admin') && (
                            <a className='btn btn-icon btn-light btn-sm' onClick={setRemovedItem.bind(null, r)}>
                              <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
                              </span>
                            </a>
                          )
                        }

                      </TableCell>
                    )
                    : (
                      <TableCell>
                        {
                          !props.noNormalView && (
                            <a className='btn btn-icon btn-light btn-sm' onClick={edit.bind(null, r)}>
                              <span className="svg-icon svg-icon-md svg-icon-primary">
                                <img className='blue-icon' src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")} />
                              </span>
                            </a>
                          )
                        }
                      </TableCell>
                    )
                  )
                }
              </TableRow>
            ))}

          </TableBody>
        </Table>
      </TableContainer>

    )
  }

  const renderList = () => {
    return (
      <List style={{flexShrink: 1, flexGrow: 1, minHeight: 0, width: '100%', overflowY: 'auto'}}>
        {filteredResources.map((r, i) => (
          <React.Fragment key={r._id}>
            <props.resourceListItem {...r}
              onClick={edit.bind(null, r)}
            />
          {i < filteredResources.length - 1 && <Divider />}
        </React.Fragment>
      ))}
      </List>
    )
  }

  const renderCardList = () => {
    return (
      <Grid spacing={2} direction='column'>
        {filteredResources.map((r, i) => (
          <Grid item key={r._id}>
            <props.resourceCard {...r} />
          </Grid>
        ))}
      </Grid>
    )
  }

  const handleChange = e => {
    const { name, value } = e.target;
    setEditedResource(prevState => ({...prevState, [name]: value}))
    setNeedsSaving(true)
  }

  const textInput = (field, placeholder, fullWidth) => {
    return <TextField size='small' fullWidth={fullWidth} label={placeholder} variant='outlined' value={editedResource[field] || ''} name={field} onChange={handleChange} />
  }

  const areaInput = (field, placeholder, style) => {
    return <TextField size='small' style={style} multiline={true} rows={20} label={placeholder} variant='outlined' value={editedResource[field] || ''} name={field} onChange={handleChange} />
  }

  const validatingModal = () => {
    return (
      <Modal show={validating}>
        <Modal.Header>
          <Modal.Title>Validation</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {props.validateQuestion}
        </Modal.Body>

        <Modal.Footer>
          <Button style={{marginRight: '10px'}} variant="contained" color="secondary" onClick={() => {setValidating(false)}}>Non</Button>
          <Button variant="contained" color="primary" onClick={validate}>Oui</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const rejectingModal = () => {
    return (
      <Modal large show={rejecting}>
        <Modal.Header>
          <Modal.Title>Refus</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <TextField label={"Motif du refus"} rows={4} fullWidth multiline variant='outlined' value={rejectCause} onChange={(e) => {setRejectCause(e.target.value)}} />
          <div style={{marginTop: '10px'}}>Voulez-vous refuser à l'auteur la publication de ce contenu ? (Le motif de refus saisi ci-dessus lui sera communiqué.)</div>
        </Modal.Body>

        <Modal.Footer>
          <Button style={{marginRight: '10px'}} variant="contained" color="secondary" onClick={() => {setRejecting(false); setRejectCause("")}}>Non</Button>
          <Button variant="contained" color="primary" onClick={reject}>Oui</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const previousMonth = () => {
    if (month - 1 < 0) {
      setYear(year - 1)
      setMonth(11)
    } else {
      setMonth(month - 1)
    }
  }

  const nextMonth = () => {
    if (month + 1 > 11) {
      setYear(year + 1)
      setMonth(0)
    } else {
      setMonth(month + 1)
    }
  }

  const availableYears = () => {
    let currentYear = new Date().getFullYear()
    let years = []
    for (let i = 2020; i <= currentYear + 1; ++i) {
      years.push(i)
    }
    return years
  }

  if (!user) return null

  if (editedResource || creationMode) {
    return (
      <>
        {
          !creationMode && (
            <a onClick={cancel} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px'}}>
              <span className="svg-icon svg-icon-md svg-icon-primary" style={{marginRight: '5px'}}>
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-left.svg")}/>
              </span>
              <span><Typography variant='h6'>Retour à la liste</Typography></span>
            </a>
          )
        }
        <props.resourceForm
          user={user}
          editedResource={editedResource}
          setEditedResource={setEditedResource}
          creationMode={creationMode}
          setReadyToSave={setReadyToSave}
          needsSaving={needsSaving}
          setNeedsSaving={setNeedsSaving}
          textInput={textInput}
          areaInput={areaInput}
          save={save}
          cancel={cancel}
          edit={edit}
          validate={(type) => {console.log(type); setValidating(editedResource._id); setValidatingType(type)}}
          reject={(type) => {console.log(type); setRejecting(editedResource._id); setValidatingType(type)}}
          style={{overflowY: 'auto'}}
        />

      {validatingModal()}
      {rejectingModal()}
    </>
    )
  }

  return (
    <>
      <Card style={{display: 'flex', flexDirection: 'column'}}>
        <CardContent style={{flexShrink: 1, flexGrow: 1, minHeight: 0, display: 'flex', flexDirection: 'column'}}>
          <Grid container direction='column' spacing={2} wrap='nowrap' style={{flexShrink: 1, flexGrow: 1, minHeight: 0, display: 'flex', flexDirection: 'column'}}>
            <Grid container item direction='row' alignItems='center' spacing={2} style={{flexShrink: 0, flexGrow: 0}}>
              {
                props.addLabel && (user.role === 'admin' || (props.editMineOnly && !props.noNormalAdd) || (user && user.role === 'marketing' && props.allowMarketing)) && (
                  <Grid item>
                    <Button variant="contained" color="primary" onClick={create}>
                      <FontAwesomeIcon icon='plus' style={{marginRight: '15px'}} />
                      {props.addLabel}
                    </Button>
                  </Grid>
                )
              }
              {
                props.downloadCSV && (
                  <Grid item>
                    <Button variant="contained" color="secondary" onClick={props.downloadCSV}>
                      <FontAwesomeIcon icon='download' style={{marginRight: '15px'}} />
                      Télécharger la liste
                    </Button>
                  </Grid>
                )
              }
              <Grid item>
                <TextField label={"Rechercher..."} type='search' fullWidth size='small' variant='outlined' value={filter || ''} onChange={(e) => {setFilter(e.target.value)}} />
              </Grid>
            </Grid>

            {
              props.dateIndex && (
                <Grid item container direction='row' alignItems='center' spacing={3} style={{justifyContent: 'center', marginBottom: '10px'}}>
                  {!(month === 0 && year === 2020) && (
                    <a style={{cursor: 'pointer', marginRight: '10px'}} onClick={previousMonth}>
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-left.svg")} />
                    </a>
                  )}
                  <Grid item>
                    <Select value={month} onChange={e => setMonth(e.target.value)} style={{width: '130px', textAlign: 'center'}}>
                      <MenuItem value={0}>Janvier</MenuItem>
                      <MenuItem value={1}>Février</MenuItem>
                      <MenuItem value={2}>Mars</MenuItem>
                      <MenuItem value={3}>Avril</MenuItem>
                      <MenuItem value={4}>Mai</MenuItem>
                      <MenuItem value={5}>Juin</MenuItem>
                      <MenuItem value={6}>Juillet</MenuItem>
                      <MenuItem value={7}>Août</MenuItem>
                      <MenuItem value={8}>Septembre</MenuItem>
                      <MenuItem value={9}>Octobre</MenuItem>
                      <MenuItem value={10}>Novembre</MenuItem>
                      <MenuItem value={11}>Décembre</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item>
                    <Select value={year} onChange={e => setYear(e.target.value)}>
                      {
                        availableYears().map((year) => <MenuItem value={year}>{year}</MenuItem>)
                      }
                    </Select>
                  </Grid>
                  {
                    !(month === 11 && year === (new Date().getFullYear() + 1)) && (
                      <a style={{cursor: 'pointer', marginLeft: '10px'}} onClick={nextMonth}>
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")} />
                      </a>
                    )
                  }


                </Grid>
              )
            }

            {
              props.tabSegmentation ? (
                <Tabs defaultActiveKey="false" onSelect={(tab) => {setSegmentationValue(tab === 'true')}}>
                  <Tab eventKey='false' title={props.tabSegmentation[1]}>
                    {(windowSize.width <= mobileSize && user.role !== 'admin' && props.resourceListItem !== undefined)
                      ? renderList()
                      : (windowSize.width <= mobileSize && user.role !== 'admin' && props.resourceCard !== undefined) ? renderCardList() : renderTable()
                    }
                  </Tab>
                  <Tab eventKey='true' title={props.tabSegmentation[2]}>
                    {(windowSize.width <= mobileSize && user.role !== 'admin' && props.resourceListItem !== undefined)
                      ? renderList()
                      : (windowSize.width <= mobileSize && user.role !== 'admin' && props.resourceCard !== undefined) ? renderCardList() : renderTable()
                    }
                  </Tab>
                </Tabs>
              )
              : (
                (windowSize.width <= mobileSize && user.role !== 'admin' && props.resourceListItem !== undefined)
                  ? renderList()
                  : (windowSize.width <= mobileSize && user.role !== 'admin' && props.resourceCard !== undefined) ? renderCardList() : renderTable()
              )
            }
          </Grid>
        </CardContent>
      </Card>

      <Modal show={removedItem !== null}>
        <Modal.Header>
          <Modal.Title>{props.removeLabel}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {removedItem !== null && <p>{props.removeQuestion(removedItem)}</p>}
        </Modal.Body>

        <Modal.Footer>
          <Button style={{marginRight: '10px'}} variant="contained" color="secondary" onClick={setRemovedItem.bind(null, null)}>Annuler</Button>
          <Button variant="contained" color="primary" onClick={remove}>Supprimer</Button>
        </Modal.Footer>
      </Modal>

      {validatingModal()}
      {rejectingModal()}
    </>
  )
}

export default withRouter(ResourceList)
