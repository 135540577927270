import React, {useState, useEffect} from "react";
import {FormControl, FormControlLabel, FormGroup, FormLabel, RadioGroup, Radio, Checkbox, Paper, Divider, TableCell, Button, Grid, Card, Typography, CardContent, CardActions, CardHeader, CardMedia, Avatar, List, ListItem, ListItemText, ListItemSecondaryAction} from '@material-ui/core'

const twoDigitString = (n) => {
  if (n < 10) return ('0' + n.toString())
  return n.toString()
}
const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
const months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
const shortDateString = (date, noDay) => {
  return (noDay ? '' : days[date.getDay()]) + ' ' + twoDigitString(date.getDate()) + '/' + twoDigitString(date.getMonth() + 1) + '/' + date.getFullYear()
}
const longDateString = (date) => {
  return days[date.getDay()] + ' ' + date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear()
}
const timeString = (date) => {
  return twoDigitString(date.getHours()) + ':' + twoDigitString(date.getMinutes())
}

export const AccomodationForm = (props) => {
  const toggle = (dateStr, e) => {
    let {name} = e.target
    let options = {...props.options}
    options[dateStr] = {...options[dateStr], [name]: !options[dateStr][name]}
    props.setOptions(options)
  }

  const setTypeChambre = (dateStr, e) => {
    let options = {...props.options}
    options[dateStr] = {...options[dateStr], typeChambre: e.target.value}
    props.setOptions(options)
  }

  const optionsArray = []
  const availableOptionsArray = []
  for (let opt in props.options) {
    optionsArray.push({...props.options[opt], date: opt})
  }
  optionsArray.sort((a, b) => a.date.localeCompare(b.date))
  if (props.availableOptions) {
    for (let opt in props.availableOptions) {
      availableOptionsArray.push({...props.availableOptions[opt], date: opt})
    }
    availableOptionsArray.sort((a, b) => a.date.localeCompare(b.date))
  }

  const dateLabel = (dateStr) => {
    let date = longDateString(new Date(Number(dateStr.substr(0, 4)), Number(dateStr.substr(4, 2)) - 1, Number(dateStr.substr(6))))
    date = date.substr(0, 1).toUpperCase() + date.substr(1)
    return date
  }

  const renderDayOption = (day) => {
    if (!props.allEnabled) {
      if (!props.availableOptions[day.date].selected) return null
    }

    let _toggle = toggle.bind(null, day.date)
    let _setTypeChambre = setTypeChambre.bind(null, day.date)

    return (
      <FormControl key={day.date}>
        <FormGroup>
          {
            props.allEnabled
            ? <Typography variant='subtitle1' style={{marginBottom: '8px'}}>{dateLabel(day.date)}</Typography>
            : <FormControlLabel control={<Checkbox name='selected' checked={day.selected} onChange={_toggle} />} label={dateLabel(day.date)} />
          }
          {
            (props.allEnabled || day.selected) && (
              <div style={{marginTop: ((props.allEnabled || props.availableOptions[day.date].matin || props.availableOptions[day.date].midi || props.availableOptions[day.date].soir || props.availableOptions[day.date].nuitee) ? '-15px' : undefined), marginLeft: '10px'}}>
                <FormGroup row>
                  {(props.allEnabled || props.availableOptions[day.date].matin) && <FormControlLabel control={<Checkbox name={'matin'} checked={day.matin} onChange={_toggle} />} label='Petit-déjeuner' />}
                  {(props.allEnabled || props.availableOptions[day.date].midi) && <FormControlLabel control={<Checkbox name={'midi'} checked={day.midi} onChange={_toggle} />} label='Déjeuner' />}
                  {(props.allEnabled || props.availableOptions[day.date].soir) && <FormControlLabel control={<Checkbox name={'soir'} checked={day.soir} onChange={_toggle} />} label='Diner' />}
                  {(props.allEnabled || props.availableOptions[day.date].nuitee) && <FormControlLabel control={<Checkbox name={'nuitee'} checked={day.nuitee} onChange={_toggle} />} label='Nuitée' />}
                </FormGroup>
              </div>
            )
          }
          {
            (day.selected && day.nuitee && !props.allEnabled) && (
              <div style={{marginTop: '-15px', marginLeft: '10px'}}>
                <RadioGroup row name={'typeChambre'} onChange={_setTypeChambre} value={day.typeChambre}>
                  <FormControlLabel value="single" control={<Radio />} label='Chambre single' />
                  <FormControlLabel value="twin" control={<Radio />} label='Chambre twin' />
                  <FormControlLabel value="double" control={<Radio />} label='Chambre double' />
                </RadioGroup>
              </div>
            )
          }
        </FormGroup>
      </FormControl>
    )
  }

  return (
    <Grid container item spacing={2} direction='column'>
      {optionsArray.map(renderDayOption)}
    </Grid>
  )
}
