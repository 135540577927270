import React, {useState, useEffect} from "react";
import ajax from '../utils/ajax'
import {TableCell, Button, Grid, Card, Typography, CardContent, CardActions, CardHeader, FormControl, FormGroup, RadioGroup, Checkbox, Radio, FormControlLabel, TextField} from '@material-ui/core'
import { useSubheader } from "../../_metronic/layout"
import {Tabs, Tab} from 'react-bootstrap'
import './Survey.scss'
import './Article.scss'

export const Survey = (props) => {
  const [survey, setSurvey] = useState(null)
  const [shops, setShops] = useState([])
  const subheader = useSubheader();

  const fetch = () => {
    ajax.get('/api/survey/' + props.match.params.id).then(r => {
      let breadcrumbs = []
      breadcrumbs.push({
          text: 'Accueil',
          title: 'Accueil',
          title: 'Accueil',
          href: '/'
      })
      breadcrumbs.push({
          text: r.title,
          title: r.title,
          href: '/sondage/' + props.match.params.id
      });
      subheader.setBreadcrumbs(breadcrumbs)
      subheader.setTitle(r.title)
      setSurvey(r[0])

      ajax.post('/api/survey/view', {_id: props.match.params.id}).then(r => {})
    })

    ajax.get('/api/user/shops').then(res => {
      setShops(res)
    })
  }
  useEffect(fetch, [])

  if (!survey) return null

  const renderGeneralTab = () => {
    return (

      <div style={{display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto', overflowX: 'hidden', padding: '10px'}}>

        {
          survey.content && <div dangerouslySetInnerHTML={{__html: survey.content.replace(/src=\"api/g, "src=\"https://intranetv2.comptoirdelours.fr/api")}} className='ArticleContent' />
        }

      </div>
    )
  }

  const setAnswer = (shopId, i, value, choiceIndex) => {
    if (survey.questions[i].type === 'number' && isNaN(Number(value))) return
    console.log(value)
    if (survey.questions[i].type === 'number') value = Number(value)

    let shopAnswers = survey.answers[shopId] ? [...survey.answers[shopId]] : []
    console.log(shopAnswers, value)
    if (shopAnswers.length === 0) {
      for (let a = 0; a < survey.questions.length; ++a) shopAnswers.push(undefined)
    }
    if (survey.questions[i].type === 'multiple-choice') {
      if (!shopAnswers[i]) {
        shopAnswers[i] = []
        for (let a = 0; a < survey.questions[i].options.choices.length; ++a) shopAnswers[i].push(false)
      }
      shopAnswers[i][choiceIndex] = value
    } else {
      shopAnswers[i] = value
    }

    console.log(shopAnswers)
    setSurvey(prevState => ({...prevState, answers: {...prevState.answers, [shopId]: shopAnswers}}))
  }

  const value = (shop, i) => {
    if (survey.answers && survey.answers[shop] && survey.answers[shop][i]) return survey.answers[shop][i]
    return undefined
  }

  const renderQuestion = (shop, question, i) => {
    return (
      <div style={{display: 'flex', flexDirection: 'column', marginBottom: '40px'}}>
        <div style={{marginBottom: '10px', fontWeight: (question.options.mandatory ? 700 : 400)}}>{question.title + (question.options.mandatory ? ' *' : '')}</div>
        {question.type === 'string' && (
          <TextField fullWidth variant="outlined" size='small' value={value(shop, i) ? survey.answers[shop][i] : ''} onChange={e => { setAnswer(shop, i, e.target.value) }} />
        )}
        {question.type === 'text' && (
          <TextField fullWidth rows={4} multiline variant="outlined" value={value(shop, i) ? survey.answers[shop][i] : ''} onChange={e => { setAnswer(shop, i, e.target.value) }} />
        )}
        {question.type === 'number' && (
          <TextField fullWidth type='number' variant="outlined" size='small'
          InputProps={{ inputProps: {
            min: question.options.hasMinValue ? question.options.minValue : undefined,
            max: question.options.hasMaxValue ? question.options.maxValue : undefined
          } }}
          error={question.options.hasMinValue && value(shop, i) !== undefined && value(shop, i) < question.options.minValue || question.options.hasMaxValue && value(shop, i) !== undefined && value(shop, i) > question.options.maxValue}
          value={value(shop, i) ? value(shop, i) : (question.type === 'number' ? 0 : '')} onChange={e => { setAnswer(shop, i, e.target.value) }} />
        )}
        {
          (question.type === 'single-choice') && (
            <FormControl>
              <FormGroup>
                <RadioGroup onChange={(e) => {console.log(e.target); setAnswer(shop, i, e.target.value) }} value={value(shop, i)}>
                  {question.options.choices.map((choice, choiceIndex) => {
                    return <FormControlLabel key={choiceIndex} value={choice} control={<Radio />} label={choice} />
                  })}
                </RadioGroup>
              </FormGroup>
            </FormControl>
          )
        }
        {
          (question.type === 'multiple-choice') && (
            <FormControl>
              <FormGroup>
                {question.options.choices.map((choice, choiceIndex) => {
                  return <FormControlLabel key={choiceIndex} value={value(shop, i) && value(shop, i)[choiceIndex]} control={<Checkbox />} label={choice} onChange={e => { setAnswer(shop, i, e.target.checked, choiceIndex) }} />
                })}
              </FormGroup>
            </FormControl>
          )
        }
      </div>
    )
  }

  const renderSurveyForm = (shop) => {
    const surveyOpen = !survey.hasEndDate || new Date(survey.endDate).getTime() > Date.now()


    return (
      <div style={{display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto', overflowX: 'hidden', padding: '10px'}}>
        {survey.questions.map((question, i) => { return renderQuestion(shop._id, question, i) })}
      </div>
    )
  }

  const cancel = () => {
    window.history.back()
  }

  const save = () => {
    console.log(JSON.stringify(survey.answers, null, 2))
    ajax.post('/api/survey/answer', {_id: survey._id, answers: survey.answers}).then(() => {
      window.history.back()
    })
  }

  const canSave = () => {
    console.log(survey)
    for (let shop of shops) {
      for (let i = 0; i < survey.questions.length; ++i) {
        if (survey.questions[i].options.mandatory && (!survey.answers[shop._id] || survey.answers[shop._id][i] === undefined || survey.answers[shop._id][i] === '')) return false
        if (survey.questions[i].type === 'number' && survey.questions[i].options.hasMinValue && survey.answers[shop._id][i] < survey.questions[i].options.minValue) return false
        if (survey.questions[i].type === 'number' && survey.questions[i].options.hasMaxValue && survey.answers[shop._id][i] > survey.questions[i].options.maxValue) return false
      }
    }

    return true
  }

  const surveyOpen = !survey.hasEndDate || new Date(survey.endDate).getTime() > Date.now()

  if (!shops) return null

  return (
    <div className='Survey' style={{display: 'flex', flexDirection: 'column', flexGrow: 1, flexShrink: 1, minHeight: 0}}>
      <Card style={{flexGrow: 0, flexShrink: 0, marginBottom: '10px'}}>
        <CardHeader
          title={survey.title}
          subheader={survey.endDate ? ("Date limite de réponse : " + new Date(survey.endDate).toLocaleDateString()) : undefined}
          style={{padding: '8px'}}
        />
      </Card>

      <Card style={{flexGrow: 1, flexShrink: 1, minHeight: 0, marginBottom: '10px'}}>
        <CardContent style={{height: '100%', display: 'flex', flexDirection: 'column', padding: 0, paddingTop: '10px'}}>
          <Tabs defaultActiveKey="general" style={{paddingLeft: '10px', paddingRight: '10px'}}>
            <Tab eventKey='general' title='Infos générales'>
              {renderGeneralTab()}
            </Tab>
            {
              shops.map(shop => (
                <Tab key={shop._id} eventKey={shop._id} title={shop.name}>
                  {renderSurveyForm(shop)}
                </Tab>
              ))
            }
          </Tabs>
        </CardContent>
      </Card>

      {
        <Card style={{marginTop: '10px', flexShrink: 0, flexGrow: 0}}>
          {
            surveyOpen
            ? (
              <CardActions>
                <Button variant="contained" color="primary" onClick={save} disabled={!canSave()}>
                  Enregistrer mes réponses
                </Button>
                <Button variant="contained" color="secondary" onClick={cancel}>
                  Annuler
                </Button>
              </CardActions>
            )
            : (
              <CardActions>
                <Button variant="contained" color="secondary" onClick={cancel}>
                  Retour
                </Button>
              </CardActions>
            )
          }
        </Card>
      }
    </div>
  )
}
