import React, {useState, useEffect} from "react";
import {Table, Form, Col, Modal } from 'react-bootstrap';
import {TableCell, Button, Grid, Card, CardContent, CardActions, Avatar, Typography, Divider, Radio, RadioGroup, FormControl, FormControlLabel, FormGroup, FormLabel, Checkbox, List, ListItem, ListItemAvatar, ListItemText} from '@material-ui/core'
import './Users.scss'
import ajax from '../utils/ajax'
import Media from '../components/Media/Media'
import ResourceList from '../components/ResourceList/ResourceList'
import { Editor } from '@tinymce/tinymce-react'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import frLocale from "date-fns/locale/fr"
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Articles = () => {
  const [userViewsDetail, setUserViewsDetail] = useState(null)

  const status = (article) => {
    let str = ""
    if (article.status === 'published') str += "Publié"
    else if (article.status === 'unpublished') str += "Non publié"

    if (article.hasStartDate && article.hasEndDate) {
      str += " du " + new Date(article.startDate).toLocaleDateString() + " au " + new Date(article.endDate).toLocaleDateString()
    } else if (article.hasStartDate) {
      str += " à partir du " + new Date(article.startDate).toLocaleDateString()
    } else if (article.hasEndDate) {
      str += " jusqu'au " + new Date(article.endDate).toLocaleDateString()
    }
    return str
  }


  const sortArticles = (articles) => {
    articles.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
  }

  const ArticleRow = (props) => {
    const visible = props.status === 'published' && (!props.hasStartDate || new Date(props.startDate).getTime() <= Date.now()) && (!props.hasEndDate || new Date(props.endDate).getTime() >= Date.now())
    return [
      <TableCell key='picture'>{props.picture && <img height={80} src={'/api/media/' + props.picture} />}</TableCell>,
      <TableCell key='title'>{props.title}</TableCell>,
      <TableCell key='author'>
        {
          props.author && <Avatar style={{width: '60px', height: '60px'}} alt={props.author.firstName + ' ' + props.author.lastName.toUpperCase()} src={"/api/media/thumbnail/" + props.author.picture} />
        }
      </TableCell>,
      <TableCell key='status'>
        {visible && <div style={{marginBottom: '5px', color: "#17c191", textTransform: "uppercase"}}>
          <FontAwesomeIcon icon='check' style={{marginRight: '10px'}} />
          Visible
        </div>}
        {status(props)}
        {
          props.user.role === 'admin' && (
            <div style={{marginTop: '5px'}}>
              <FontAwesomeIcon icon='eye' style={{marginRight: '10px'}} />
              <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setUserViewsDetail(props.userViews.sort((a, b) => a.lastName.localeCompare(b.lastName)))}>{(props.userViews ? props.userViews.length : 0) + (props.userViews && props.userViews.length > 1 ? " vues" : " vue")}</span>
            </div>
          )
        }
      </TableCell>
    ]
  }



  const ArticleForm = (props) => {
    const handleEditorChange = (content, editor) => {
      let article = {...props.editedResource}
      article.content = content
      props.setEditedResource(article)
      props.setNeedsSaving(true)
     }

    const renderEditor = () => {
      return (
        <Editor value={props.editedResource.content && props.editedResource.content.replace(/src=\"api/g, "src=\"https://intranetv2.comptoirdelours.fr/api")} onEditorChange={handleEditorChange} apiKey='1a44ucvt07bi0x6k4rxvdbzc2im5lghbn2ojkkf0bp6cbquj'
        init={{
          width: '100%',
          height: '100%',
          language: 'fr_FR',
          language_url: '/langs/fr_FR.js',
          plugins: 'image preview autolink save link media table charmap hr anchor advlist wordcount help paste',
          menubar: 'file edit view insert format tools table help',
          toolbar: 'undo redo | bold italic underline strikethrough | formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | preview | insertfile image media template link anchor',
          content_css: [
              '//fonts.googleapis.com/css?family=Poppins|Open+Sans:400,700&display=swap',
            ],
          content_style: 'h1 {font-family: Poppins; font-size: 30px; color: rgba(0,0,0,0.87); font-weight: 700; text-transform: uppercase; text-align: center; margin-bottom: 20px!important;}' +
              'h2 {font-family: Poppins; font-size: 20px; font-weight: 700; line-height: 33px; color: rgba(0,0,0,0.87);}' +
              'p {font-family: Open Sans; font-weight: 400; font-size: 14px; line-height: 25px; color: rgba(0,0,0,0.87); margin-bottom: 20px;}' +
              'body {max-width: 100%; padding: 10px 20px; font-family: Open Sans;}' +
              'img {max-width: 100%; height: auto; margin-bottom: 20px;}' +
              '.caption {color: red;}',
          block_formats: 'Titre principal=h1; Titre de section=h2; Paragraphe=p',
          style_formats: {title: 'Légende', format: 'caption'},
          formats: {
            caption: { block: 'div', classes: 'caption' }
          },
          paste_as_text: true,
          images_upload_handler: (blobInfo, success, failure) => {
            let data = new FormData()
            data.append('file', blobInfo.blob(), blobInfo.filename())
            ajax.put('/api/media', data).then(response => {
              success(response.path)
            }).catch(e => {
              failure()
            })
          }
        }}

        />
      )
    }

    const toggle = e => {
      const {name} = e.target

      let defaultStartDate = new Date()
      defaultStartDate.setHours(0)
      defaultStartDate.setMinutes(0)
      defaultStartDate.setSeconds(0)
      defaultStartDate.setMilliseconds(0)

      let defaultEndDate = new Date()
      defaultEndDate.setHours(23)
      defaultEndDate.setMinutes(59)
      defaultEndDate.setSeconds(59)
      defaultEndDate.setMilliseconds(999)

      props.setEditedResource(prevState => ({...prevState, startDate: (props.editedResource.startDate || defaultStartDate), endDate: (props.editedResource.endDate || defaultEndDate), [name]: !props.editedResource[name]}))
      props.setNeedsSaving(true)
    }

    const handleChange = e => {
      const { name, value } = e.target;
      props.setEditedResource(prevState => ({...prevState, [name]: value}))
      props.setNeedsSaving(true)
    }

    const setStartDate = (date) => {
      date.setHours(0)
      date.setMinutes(0)
      date.setSeconds(0)
      date.setMilliseconds(0)
      props.setEditedResource(prevState => ({...prevState, startDate: date}))
      props.setNeedsSaving(true)
    }
    const setEndDate = (date) => {
      date.setHours(23)
      date.setMinutes(59)
      date.setSeconds(59)
      date.setMilliseconds(999)
      props.setEditedResource(prevState => ({...prevState, endDate: date}))
      props.setNeedsSaving(true)
    }

    return (
      <Card style={{height: '100%'}}>
        <CardContent style={{height: '100%'}}>
          <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center'}}>
              <div style={{marginBottom: '10px', flexGrow: 1, flexShrink: 1, minWidth: 0, marginRight: '10px'}}>{props.textInput('title', 'Titre', true)}</div>
              <Media style={{marginBottom: '10px'}} _id={props.editedResource.picture} editable onEdit={(img) => { props.setEditedResource(prevState => ({...prevState, picture: img})); props.setReadyToSave(false); props.setNeedsSaving(true) }} />
            </div>
            <Grid container item direction='column' spacing={2}>
              <Grid item><Typography variant='h6'>Publication</Typography></Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                <Grid item>
                  <FormControl>
                    <FormGroup>
                      <RadioGroup row name='status' onChange={handleChange} value={props.editedResource.status}>
                        <FormControlLabel value='published' control={<Radio />} label='Publié' />
                        <FormControlLabel value='unpublished' control={<Radio />} label='Non publié' />
                      </RadioGroup>
                    </FormGroup>
                    <FormGroup row={true}>
                      <FormControlLabel control={<Checkbox name='hasStartDate' disabled={props.editedResource.status === 'unpublished'} checked={props.editedResource.hasStartDate} onChange={toggle} />} label='Visible à partir du' />
                      <KeyboardDatePicker
                        disabled={!props.editedResource.hasStartDate || props.editedResource.status === 'unpublished'}
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Date de début"
                        value={props.editedResource.startDate || new Date()}
                        onChange={setStartDate}
                        style={{width: 'unset'}}
                      />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name='hasEndDate' disabled={props.editedResource.status === 'unpublished'} checked={props.editedResource.hasEndDate} onChange={toggle} />} label="Visible jusqu'au" />
                      <KeyboardDatePicker
                        disabled={!props.editedResource.hasEndDate || props.editedResource.status === 'unpublished'}
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Date de fin"
                        value={props.editedResource.endDate || new Date()}
                        onChange={setEndDate}
                        style={{width: 'unset'}}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <div style={{width: '100%', height: '100%', flexGrow: 1, flexShrink: 1, minHeight: 0}}>
              <Typography variant='h6'>Contenu de l'article</Typography>
              {renderEditor()}
            </div>

            <CardActions style={{marginTop: '30px'}}>
              {
                props.needsSaving && (
                  <Button variant="contained" color="primary" onClick={props.save}>
                    Enregistrer
                  </Button>
                )
              }
              <Button variant="contained" color="secondary" onClick={props.cancel}>
                Annuler
              </Button>
            </CardActions>
          </div>
        </CardContent>
      </Card>
    )
  }

  const removeQuestion = (article) => {
    return `Voulez-vous vraiment supprimer l'article\n \"${article.title}\" ?`
  }

  const getNewResource = () => {
    return {
      title: 'Nouvel article',
      date: new Date(),
      status: 'published',
      hasStartDate: false,
      hasEndDate: false
    }
  }

  return (
    <>
      <ResourceList
        resourceType='article'
        sortResources={sortArticles}
        filterFields={['title']}
        addLabel="Nouvel article"
        removeLabel="Suppression d'un article"
        removeQuestion={removeQuestion}
        headers={['Couverture', 'Titre', 'Auteur', 'Statut']}
        resourceRow={ArticleRow}
        resourceForm={ArticleForm}
        pictureFields={['picture']}
        getNewResource={getNewResource}
      />

    <Modal scrollable show={userViewsDetail !== null}>
        <Modal.Header>
          <Modal.Title>
            Utilisateurs ayant ouvert l'article
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: 0}}>
          {
            userViewsDetail && (
              <List dense>
                {
                  userViewsDetail.map((user, i) => {
                    console.log(user)
                    const fullName = user.firstName + ' ' + user.lastName.toUpperCase()
                    return (
                      <>
                        <ListItem key={i}>
                          <ListItemAvatar><Avatar alt={fullName} src={'/api/media/thumbnail/' + user.picture} /></ListItemAvatar>
                          <ListItemText primary={fullName} />
                        </ListItem>
                        <Divider />
                      </>
                    )
                  })
                }
              </List>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button style={{marginRight: '10px'}} variant="contained" color="secondary" onClick={() => { setUserViewsDetail(null) }}>Fermer</Button>
        </Modal.Footer>
      </Modal>
    </>

  )
}

export default Articles
