/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {useState} from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {Layout} from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import {GlobalDataProvider} from "./hooks/useGlobalData"
import ajax from './utils/ajax'
import firebase from "firebase/app"
import "firebase/messaging"


export function Routes() {
    const [fcmToken, setFcmToken] = useState(null)
    const [validationsCount, setValidationsCount] = useState(null)
    const isAuthorized = window.localStorage.getItem('token')

    const getFcmToken = () => {
      const messaging = firebase.messaging();

      messaging.getToken({vapidKey: 'BGAqydvP4TsZFnBvjGEjJdh92LXD63duWE2GN7AyQhvTJlJVZ8CDjO8e5c4kzEZEu0VZmRJqqtummXiOFpQjHEc'}).then((currentToken) => {
        if (currentToken) {
          setFcmToken(currentToken)
          ajax.post('/api/user/fcmToken', {fcmToken: currentToken}).then(() => {})
        } else {
          // Show permission request.
          console.log('No registration token available. Request permission to generate one.');
          // Show permission UI.
          Notification.requestPermission().then(status => {
            if (status === 'granted') getFcmToken()
          })
          //setTokenSentToServer(false);
        }
      }).catch((err) => {
        console.log(err)
      })
    }

    if (firebase.messaging.isSupported() && isAuthorized && !fcmToken) {
      getFcmToken()
    } else {
      //console.log(firebase.messaging.isSupported(), isAuthorized, fcmToken)
    }

    return (
        <Switch>
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/auth" to="/"/>
            )}

            <Route path="/error" component={ErrorsPage}/>
            <Route path="/logout" component={Logout}/>


            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login"/>
            ) : (
                <GlobalDataProvider>
                  <Layout validationsCount={validationsCount}>
                    <BasePage />
                  </Layout>
                </GlobalDataProvider>
            )}
        </Switch>
    );
}
