import React, {useState, useEffect} from "react";
import {Table, Form, Col, Modal } from 'react-bootstrap';
import {TableCell, Button, Grid, Card, CardContent, CardActions, CardHeader, Avatar, TextField, Typography} from '@material-ui/core'
import './Users.scss'
import ajax from '../utils/ajax'
import Media from '../components/Media/Media'
import ResourceList from '../components/ResourceList/ResourceList'
import { Autocomplete } from '@material-ui/lab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { IconButton } from "@material-ui/core"
import DeleteIcon from '@material-ui/icons/Delete';
import {useGlobalData} from '../hooks/useGlobalData'
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { Editor } from '@tinymce/tinymce-react'

library.add(fas)

const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
const twoDigitString = (n) => {
  if (n < 10) return ('0' + n.toString())
  return n.toString()
}
const shortDateString = (date, noDay) => {
  return (noDay ? '' : days[date.getDay()]) + ' ' + twoDigitString(date.getDate()) + '/' + twoDigitString(date.getMonth() + 1) + '/' + date.getFullYear()
}

export const Questions = (props) => {
  const [questions, setQuestions] = useState(null)
  const fetch = () => {
    if (props.match.params.id) {
      ajax.post('/api/question/view', {_id: props.match.params.id}).then(r => {})
    }
  }
  useEffect(fetch, [])

  const globalData = useGlobalData()
  const user = globalData.user


  const QuestionRow = (props) => {

    return (
      [
        <TableCell>
          <div style={{overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 5, WebkitBoxOrient: 'vertical'}}>
            {props.question}
          </div>
        </TableCell>,
        <TableCell style={{minWidth: '15vw'}}>
          {props.status === 'pending' && "En attente de réponse"}
          {props.status === 'answered' && ("Réponse publiée le " + shortDateString(new Date(props.answerDate), true))}
        </TableCell>
      ]
    )
  }

  const QuestionForm = (props) => {
    const handleEditorChange = (content, editor) => {
      props.setEditedResource(prevState => ({...prevState, answer: content, status: 'answered', answerDate: new Date(), answerAuthor: user._id}))
      props.setNeedsSaving(true)
     }

    const renderEditor = () => {
      return (
        <Editor value={props.editedResource.answer && props.editedResource.answer.replace(/src=\"api/g, "src=\"https://intranetv2.comptoirdelours.fr/api")} onEditorChange={handleEditorChange} apiKey='1a44ucvt07bi0x6k4rxvdbzc2im5lghbn2ojkkf0bp6cbquj'
        init={{
          width: '100%',
          height: '100%',
          language: 'fr_FR',
          language_url: '/langs/fr_FR.js',
          plugins: 'image preview autolink save link media table charmap hr anchor advlist wordcount help paste',
          menubar: 'file edit view insert format tools table help',
          toolbar: 'undo redo | bold italic underline strikethrough | formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | preview | insertfile image media template link anchor',
          content_css: [
              '//fonts.googleapis.com/css?family=Poppins|Open+Sans:400,700&display=swap',
            ],
          content_style: 'h1 {font-family: Poppins; font-size: 30px; color: rgba(0,0,0,0.87); font-weight: 700; text-transform: uppercase; text-align: center; margin-bottom: 20px!important;}' +
              'h2 {font-family: Poppins; font-size: 20px; font-weight: 700; line-height: 33px; color: rgba(0,0,0,0.87);}' +
              'p {font-family: Open Sans; font-weight: 400; font-size: 14px; line-height: 25px; color: rgba(0,0,0,0.87); margin-bottom: 20px;}' +
              'body {max-width: 100%; padding: 10px 20px; font-family: Open Sans;}' +
              'img {max-width: 100%; height: auto; margin-bottom: 20px;}' +
              '.caption {color: red;}',
          block_formats: 'Titre principal=h1; Titre de section=h2; Paragraphe=p',
          style_formats: {title: 'Légende', format: 'caption'},
          formats: {
            caption: { block: 'div', classes: 'caption' }
          },
          paste_as_text: true,
          images_upload_handler: (blobInfo, success, failure) => {
            let data = new FormData()
            data.append('file', blobInfo.blob(), blobInfo.filename())
            ajax.put('/api/media', data).then(response => {
              success(response.path)
            }).catch(e => {
              failure()
            })
          }
        }}

        />
      )
    }

    return (
      <Card style={{overflowY: 'auto'}}>
        <CardContent>
          {
            (!props.creationMode && user.role !== 'admin') && (
              <CardContent>
                <Card>
                  {
                    props.editedResource.questionAuthor && props.editedResource.questionAuthor.firstName && (
                      <CardHeader
                        avatar={<Avatar alt={props.editedResource.questionAuthor.firstName + ' ' + props.editedResource.questionAuthor.lastName.toUpperCase()} src={"/api/media/thumbnail/" + props.editedResource.questionAuthor.picture} />}
                        title={props.editedResource.questionAuthor.firstName + ' ' + props.editedResource.questionAuthor.lastName.toUpperCase()}
                        subheader={shortDateString(new Date(props.editedResource.questionDate), true)}
                        style={{padding: '8px'}}
                        action={
                          <IconButton style={{pointerEvents: "none"}}>
                            <ContactSupportIcon />
                          </IconButton>
                        }
                      />
                    )
                  }
                  <CardContent>
                    <div style={{whiteSpace: 'pre-wrap'}}>{props.editedResource.question}</div>
                  </CardContent>
                </Card>

                {
                  props.editedResource.answer && (
                    <Card style={{marginTop: '20px', border: '2px solid #464E5F'}}>
                      <CardHeader
                        avatar={<Avatar alt={props.editedResource.answerAuthor.firstName + ' ' + props.editedResource.answerAuthor.lastName.toUpperCase()} src={"/api/media/thumbnail/" + props.editedResource.answerAuthor.picture} />}
                        title={props.editedResource.answerAuthor.firstName + ' ' + props.editedResource.answerAuthor.lastName.toUpperCase()}
                        subheader={shortDateString(new Date(props.editedResource.questionDate), true)}
                        style={{padding: '8px'}}
                      />
                      <CardContent>
                        <div dangerouslySetInnerHTML={{__html: props.editedResource.answer.replace(/src=\"api/g, "src=\"https://intranetv2.comptoirdelours.fr/api")}} className='ArticleContent' />
                      </CardContent>
                    </Card>
                  )
                }
              </CardContent>
            )
          }
          {
            (!props.creationMode && user.role === 'admin') && (
              <CardContent>
                <Card>
                  <CardHeader
                    avatar={<Avatar alt={props.editedResource.questionAuthor.firstName + ' ' + props.editedResource.questionAuthor.lastName.toUpperCase()} src={"/api/media/thumbnail/" + props.editedResource.questionAuthor.picture} />}
                    title={props.editedResource.questionAuthor.firstName + ' ' + props.editedResource.questionAuthor.lastName.toUpperCase()}
                    subheader={shortDateString(new Date(props.editedResource.questionDate), true)}
                    style={{padding: '8px'}}
                    action={
                      <IconButton style={{pointerEvents: "none"}}>
                        <ContactSupportIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    {props.areaInput('question', "Question", {width: '100%'})}
                  </CardContent>
                </Card>
                <Card>
                  <CardContent style={{height: '500px'}}>
                    <Typography variant='h6'>Votre réponse :</Typography>
                    {renderEditor()}
                  </CardContent>
                </Card>

              </CardContent>
            )
          }

          {
            props.creationMode && (
              <>
                <div style={{marginBottom: '20px'}}>
                  <div style={{fontWeight: 700}}>Adressez via ce formulaire vos questions, demandes de renseignements ou remarques. Un administrateur de l'Intranet Comptoir de l'Ours vous répondra dans les meilleurs délais.</div>
                  <div style={{fontStyle: 'italic'}}>NB : Votre question et sa réponse pourront éventuellement être publiées sur l'Intranet afin d'informer l'ensemble des adhérents.</div>
                </div>
                <Grid container item direction='column' xs={12} md={12} spacing={2}>
                  {props.areaInput('question', "Votre question")}
                </Grid>
              </>
            )
          }


          {
            (user.role === 'admin' || props.creationMode) && (
              <CardActions style={{marginTop: '30px'}}>
                {
                  props.needsSaving && (
                    <Button variant="contained" color="primary" onClick={props.save}>
                      Envoyer
                    </Button>
                  )
                }
                <Button variant="contained" color="secondary" onClick={props.cancel}>
                  Annuler
                </Button>
              </CardActions>
            )
          }

        </CardContent>
      </Card>
    )
  }

  const removeQuestion = (user) => {
    return `Voulez-vous vraiment supprimer l'utilisateur ${user.firstName} ${user.lastName} ?`
  }

  const getNewResource = () => {
    return {question: '', questionDate: new Date(), status: 'pending', questionAuthor: user._id}
  }

  const sortQuestions = (a, b) => {
    if (!a || !b) return 0
    return new Date(b.date).getTime() - new Date(a.date).getTime()
  }

  if (!user) return null

  return (
    <ResourceList
      resourceType='question'
      filterFields={['question']}
      addLabel="Poser une question"
      removeLabel="Suppression d'une question"
      removeQuestion={removeQuestion}
      headers={['Question', 'Statut']}
      resourceRow={QuestionRow}
      resourceForm={QuestionForm}
      editMineOnly
      sortResources={sortQuestions}
      getNewResource={getNewResource}
      actionsLabel="CONSULTER"
    />
  )
}

export default Questions
