import React, { PureComponent } from 'react'
import './Media.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";

class Media extends PureComponent {
  constructor (p) {
    super(p)
    this.videos = []
    this.state = {hover: false}
  }

  renderPicture () {
    let thumbnailCn = 'Media-thumbnail'
    if (this.props.selected) {
      thumbnailCn += ' selected'
    }

    const thumbnailUrl = "url('" + (process.env.PUBLIC_URL || '') + '/api/media/' + this.props._id + "')"
    return (
      <div className={thumbnailCn} onMouseEnter={() => { this.setState({hover: true}) }} onMouseLeave={() => { this.setState({hover: false}) }}>
        <div className={'Media-thumbnail-image' + ((!this.props.siblingDragged && (this.props.isDragging || this.state.hover)) ? ' hover' : '')} style={{backgroundImage: thumbnailUrl}} />
        {
          (this.props.onRemove && !this.props.siblingDragged && !this.props.isDragging) && (
            <a className='btn btn-icon btn-light btn-sm Media-remove-icon' onClick={this.props.onRemove}>
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
              </span>
            </a>
          )
        }
      </div>
    )
  }

  renderVideo () {
    let thumbnailCn = 'Media-thumbnail'
    if (this.props.selected) {
      thumbnailCn += ' selected'
    }

    const thumbnailUrl = (process.env.PUBLIC_URL || '') + '/api/media/' + this.props._id
    return (
      <div className={thumbnailCn} onMouseEnter={() => { this.setState({hover: true}); this.video.play() }} onMouseLeave={() => { this.setState({hover: false}); this.video.pause(); this.video.currentTime = 0 }}>
        <video ref={(ref) => { this.video = ref }} className={(!this.props.siblingDragged && (this.props.isDragging || this.state.hover)) ? 'hover' : ''} src={thumbnailUrl} loop muted />
        {
          (this.props.onRemove && !this.props.siblingDragged && !this.props.isDragging) && (
            <a className='btn btn-icon btn-light btn-sm Media-remove-icon' onClick={this.props.onRemove}>
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
              </span>
            </a>
          )
        }
      </div>
    )
  }

  render () {
    let highlightStyle = {}
    if (this.props.highlight) {
      highlightStyle.backgroundColor = this.props.highlight
    }

    let cn = 'Media2'
    if (this.props.onClick) {
      cn += ' Media-pointer'
    }
    if (this.props.inverted) {
      cn += ' inverted'
    }
    if (this.props.compact) {
      cn += ' compact'
    }

    let content = (
      <div className={cn} onClick={this.props.onClick}>
        {
          this.props.uploadProgress !== undefined
          ? (
            <div className='Media-thumbnail'>
              <div className='uploadIndicator' style={{position: 'absolute', width: '100%', height: '100%'}}>
                <div>Envoi</div>
                <div>{Math.floor(this.props.uploadProgress * 100).toString() + ' %'}</div>
                <a className='btn btn-icon btn-light btn-sm Media-remove-icon' onClick={this.props.onRemove}>
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
                  </span>
                </a>
              </div>
            </div>
          )
          : ((this.props.type === 'image')
            ? this.renderPicture()
            : this.renderVideo())
        }
        {
          !this.props.isDragging && !this.props.compact && this.props.name && (
            <div className='Media-name'>
              {this.props.name}
            </div>
          )
        }
      </div>
    )
    return content
  }
}

export default Media
