import React, {useState, useEffect} from "react";
import {Modal} from 'react-bootstrap';
import {TableContainer, Box, Collapse, IconButton, TableHead, TableBody, Table, TableRow, TableCell, TextField, TextArea, Button, Grid, Card, CardContent, CardActions, Avatar, Typography, Radio, RadioGroup, FormControl, FormControlLabel, FormGroup, FormLabel, Checkbox, CheckboxList, List, ListItem, ListItemAvatar, ListItemText, Divider} from '@material-ui/core'
import './Users.scss'
import ajax from '../utils/ajax'
import Media from '../components/Media/Media'
import ResourceList from '../components/ResourceList/ResourceList'
import { Editor } from '@tinymce/tinymce-react'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import frLocale from "date-fns/locale/fr"
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Tabs, Tab} from 'react-bootstrap'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../_metronic/_helpers";
import {useGlobalData} from '../hooks/useGlobalData'
import './Orders.scss'

const saveBlob = (function () {
  var a = document.createElement("a")
  document.body.appendChild(a)
  a.style = "display: none"
  return function (blob, fileName) {
    var url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = fileName
    a.type = "text/csv"
    a.click()
    window.URL.revokeObjectURL(url)
  }
}())

const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
const twoDigitString = (n) => {
  if (n < 10) return ('0' + n.toString())
  return n.toString()
}
const shortDateString = (date, noDay) => {
  return (noDay ? '' : days[date.getDay()]) + ' ' + twoDigitString(date.getDate()) + '/' + twoDigitString(date.getMonth() + 1) + '/' + date.getFullYear()
}

let productNames = {}

const PerpetualDetailsRow = (props) => {
  const globalData = useGlobalData()
  const user = globalData.user

  const [done, setDone] = useState(false)
  const [open, setOpen] = useState(false)

  const setOrderDone = () => {
    ajax.post('/api/order/markDone/' + props.id + '/' + props.shopId + '/' + props.index).then(() => {
      setDone(true)
    })
  }

  for (let art of props.articles) {
    let prod = props.products.find(a => a.id === art.productId)
    if (art.variantId) {
      if (!productNames[art.productId]) productNames[art.productId] = {}
      productNames[art.productId][art.variantId] = prod.name + ' (' + prod.variants.find(v => v.id === art.variantId).name + ')'
    } else {
      productNames[art.productId] = prod.name
    }
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{shortDateString(new Date(props.articles[0].date), true)}</TableCell>
        <TableCell>{props.shopName}</TableCell>
        <TableCell>{props.articles.reduce((total, art) => (total + art.quantity), 0)}</TableCell>
        <TableCell>
          {
            done || props.articles.find(art => art.done)
            ? (
              <div className='row aligned-center'>
                <FontAwesomeIcon icon='check' style={{marginRight: '15px'}} />
                Traité
              </div>
            )
            : (
                user.role === 'admin'
                ? (
                  <Button variant="outlined" color='primary' size="small" style={{marginTop: '5px', marginRight: '10px'}} onClick={setOrderDone}>
                    <FontAwesomeIcon icon='check' style={{marginRight: '15px'}} />
                    Marquer comme traité
                  </Button>
                )
              : (
                <div className='row aligned-center'>
                  <FontAwesomeIcon icon='hourglass-half' style={{marginRight: '15px'}} />
                  Traitement en cours
                </div>
              )
            )
          }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Article</TableCell>
                    <TableCell>Quantité</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    props.articles.map((article, i) => (
                      <TableRow key={i}>
                        <TableCell>{article.variantId ? productNames[article.productId][article.variantId] : productNames[article.productId]}</TableCell>
                        <TableCell>{article.quantity}</TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export const Orders = () => {
  const [userViewsDetail, setUserViewsDetail] = useState(null)
  const [perpetualDetails, setPerpetualDetails] = useState(null)
  const [openPerpetualOrder, setOpenPerpetualOrder] = useState(null)

  const globalData = useGlobalData()
  const user = globalData.user
  const [shops, setShops] = useState([])
  const [shopsById, setShopsById] = useState([])
  const [fetchSingle, setFetchSingle] = useState(null)

  const fetch = () => {
    ajax.get('/api/user/shops').then(res => {
      setShops(res.filter(s => !s.noOrders))
      let byId = {}
      for (let shop of res.filter(s => !s.noOrders)) {
        byId[shop._id] = shop
      }
      setShopsById(byId)
    })
  }
  useEffect(fetch, [])

  const status = (article) => {
    let str = ""
    if (article.status === 'published') str += "Publié"
    else if (article.status === 'unpublished') str += "Non publié"

    if (article.hasStartDate && article.hasEndDate) {
      str += " du " + new Date(article.startDate).toLocaleDateString() + " au " + new Date(article.endDate).toLocaleDateString()
    } else if (article.hasStartDate) {
      str += " à partir du " + new Date(article.startDate).toLocaleDateString()
    } else if (article.hasEndDate) {
      str += " jusqu'au " + new Date(article.endDate).toLocaleDateString()
    }
    return str
  }


  const sortOrders = (orders) => {
    if (user && user.role === 'admin') {
      orders.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    } else {
      orders.sort((a, b) => new Date(b.startDate || b.date).getTime() - new Date(a.endDate || b.date).getTime())
    }
  }

  const toggleOpenPerpetualOrder = (o) => {
    setOpenPerpetualOrder((openPerpetualOrder && openPerpetualOrder._id === o.id) ? null : o)
  }

  const perpetualAnswersCount = (perpetualAnswers => {
    let answers = Object.values(perpetualAnswers)
    let count = 0
    for (let shopAnswer of answers) {
      for (let answer of shopAnswer) {
        if (answer.find(art => art.done)) break
        ++count
      }
    }

    return count
  })

  const OrderRow = (props) => {
    const downloadCSV = () => {
      ajax.get('/api/order/' + props._id + '/csv', {responseType: 'arraybuffer'}).then(res => {
        const blob = new Blob([res], { type: 'text/csv' })
        saveBlob(blob, props.title + ' - BDC.csv')
      })
    }

    const visible = props.status === 'published' && (!props.hasStartDate || new Date(props.startDate).getTime() <= Date.now()) && (!props.hasEndDate || new Date(props.endDate).getTime() >= Date.now())
    const answerCount = props.perpetualAnswers ? perpetualAnswersCount(props.perpetualAnswers) : Object.keys(props.answers).length
    let aaa = 12//props.fetchSingle
    return [
      <TableCell key='title'>{props.title}</TableCell>,
      <TableCell key='status'>
        {visible && <div style={{marginBottom: '5px', color: "#17c191", textTransform: "uppercase"}}>
          <FontAwesomeIcon icon='check' style={{marginRight: '10px'}} />
          Visible
        </div>}
        {status(props)}
      </TableCell>,
      <TableCell key='answers'>
        {
          !props.perpetualAnswers && (
            <>
            <div style={{marginBottom: '5px', textTransform: "uppercase"}}>
              <FontAwesomeIcon icon='reply' style={{marginRight: '10px'}} />
              {answerCount.toString() + ' ' + (answerCount > 1 ? 'réponses' : 'réponse')}
            </div>
            </>
          )
        }
        {
          props.perpetualAnswers && answerCount > 0 && (
            <>
              <div style={{marginBottom: '5px', textTransform: "uppercase"}}>
                <FontAwesomeIcon icon='reply' style={{marginRight: '10px'}} />
                <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {setFetchSingle(() => props.fetchSingle.bind(null, props._id)); setPerpetualDetails(props) }}>{answerCount.toString() + ' ' + (answerCount > 1 ? 'commandes à traiter' : 'commandes à traiter')}</span>
              </div>
            </>
          )
        }
        {
          props.perpetualAnswers && (
            <>
              <div style={{marginBottom: '5px'}}>
                Pas de nouvelle commande
              </div>
            </>
          )
        }

        {
          props.user.role === 'admin' && (
            <div style={{marginTop: '5px'}}>
              <FontAwesomeIcon icon='eye' style={{marginRight: '10px'}} />
              <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setUserViewsDetail(props.userViews.sort((a, b) => a.lastName.localeCompare(b.lastName)))}>{(props.userViews ? props.userViews.length : 0) + (props.userViews && props.userViews.length > 1 ? " vues" : " vue")}</span>
            </div>
          )
        }
        {
          !props.perpetualAnswers && (
            <Button variant="outlined" color='primary' size="small" style={{marginTop: '5px', marginRight: '10px'}} onClick={downloadCSV}>
              <FontAwesomeIcon icon='download' style={{marginRight: '15px'}} />
              Bon de commande
            </Button>
          )
        }
        {
          props.perpetualAnswers && (
            <Button variant="outlined" color='primary' size="small" style={{marginTop: '5px', marginRight: '10px'}} onClick={downloadCSV}>
              <FontAwesomeIcon icon='download' style={{marginRight: '15px'}} />
              Historique des commandes
            </Button>
          )
        }

      </TableCell>
    ]
  }

  const OrderRowAdherent = (props) => {
    let hasAnswered = false
    for (let shop of shops) {
      if (props.answers[shop._id]) {
        hasAnswered = true
        break
      }
    }

    const orderOpen = !props.hasEndDate || new Date(props.endDate).getTime() > Date.now()

    if (!hasAnswered && !orderOpen) return null

    return [
      <TableCell key='title'>{props.title}</TableCell>,
      <TableCell key='date'>
        {props.hasEndDate && new Date(props.endDate).toLocaleDateString()}
        {!props.hasEndDate && "Aucune date limite définie"}
      </TableCell>,
      <TableCell key='reponse'>
        {
          !props.perpetual ? (
            !hasAnswered
            ? (
              <a href={"/commande/" + props._id}>
                <Button variant="outlined" color='primary' size="small"  style={{marginRight: '10px'}}>
                  <FontAwesomeIcon icon='user-edit' style={{marginRight: '15px'}} />
                  Passer ma commande
                </Button>
              </a>

            )
            : (orderOpen ?
              (
                <a href={"/commande/" + props._id}>
                  <Button variant="outlined" color='secondary' size="small"  style={{marginRight: '10px'}}>
                    <FontAwesomeIcon icon='user-check' style={{marginRight: '15px'}} />
                    Modifier ma commande
                  </Button>
                </a>
              )
              : (
                <div style={{display: "flex", flexDirection: 'column', alignItems: "flex-start"}}>
                  <div>Commande close</div>
                  <a href={"/commande/" + props._id} style={{marginLeft: 0}}>
                    <Button variant="outlined" color='black' size="small">
                      Revoir ma commande
                    </Button>
                  </a>
                </div>
              )
            )
          )
          : (
            orderOpen && (
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <a href={"/commande/" + props._id}>
                  <Button variant="outlined" color='primary' size="small"  style={{marginRight: '10px'}}>
                    <FontAwesomeIcon icon='user-edit' style={{marginRight: '15px'}} />
                    Passer une commande
                  </Button>

                </a>
                <Button variant="outlined" size="small" onClick={() => { setPerpetualDetails(props) }}>
                  Voir mes commandes
                </Button>
              </div>
            )
          )
        }

      </TableCell>
    ]
  }

  const OrderForm = (props) => {
    const [openArticleIndex, setOpenArticleIndex] = useState(null)

    const handleEditorChange = (content, editor) => {
      let article = {...props.editedResource}
      article.content = content
      props.setEditedResource(article)
      props.setNeedsSaving(true)
     }

    const renderEditor = () => {
      return (
        <Editor value={props.editedResource.content && props.editedResource.content.replace(/src=\"api/g, "src=\"https://intranetv2.comptoirdelours.fr/api")} onEditorChange={handleEditorChange} apiKey='1a44ucvt07bi0x6k4rxvdbzc2im5lghbn2ojkkf0bp6cbquj'
        init={{
          width: '100%',
          height: '100%',
          language: 'fr_FR',
          language_url: '/langs/fr_FR.js',
          plugins: 'image preview autolink save link media table charmap hr anchor advlist wordcount help paste',
          menubar: 'file edit view insert format tools table help',
          toolbar: 'undo redo | bold italic underline strikethrough | formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | preview | insertfile image media template link anchor',
          content_css: [
              '//fonts.googleapis.com/css?family=Poppins|Open+Sans:400,700&display=swap',
            ],
          content_style: 'h1 {font-family: Poppins; font-size: 30px; color: rgba(0,0,0,0.87); font-weight: 700; text-transform: uppercase; text-align: center; margin-bottom: 20px!important;}' +
              'h2 {font-family: Poppins; font-size: 20px; font-weight: 700; line-height: 33px; color: rgba(0,0,0,0.87);}' +
              'p {font-family: Open Sans; font-weight: 400; font-size: 14px; line-height: 25px; color: rgba(0,0,0,0.87); margin-bottom: 20px;}' +
              'body {max-width: 100%; padding: 10px 20px; font-family: Open Sans;}' +
              'img {max-width: 100%; height: auto; margin-bottom: 20px;}' +
              '.caption {color: red;}',
          block_formats: 'Titre principal=h1; Titre de section=h2; Paragraphe=p',
          style_formats: {title: 'Légende', format: 'caption'},
          formats: {
            caption: { block: 'div', classes: 'caption' }
          },
          paste_as_text: true,
          images_upload_handler: (blobInfo, success, failure) => {
            let data = new FormData()
            data.append('file', blobInfo.blob(), blobInfo.filename())
            ajax.put('/api/media', data).then(response => {
              success(response.path)
            }).catch(e => {
              failure()
            })
          }
        }}

        />
      )
    }

    const toggle = e => {
      const {name} = e.target

      let defaultStartDate = new Date()
      defaultStartDate.setHours(0)
      defaultStartDate.setMinutes(0)
      defaultStartDate.setSeconds(0)
      defaultStartDate.setMilliseconds(0)

      let defaultEndDate = new Date()
      defaultEndDate.setHours(23)
      defaultEndDate.setMinutes(59)
      defaultEndDate.setSeconds(59)
      defaultEndDate.setMilliseconds(999)

      props.setEditedResource(prevState => ({...prevState, startDate: (props.editedResource.startDate || defaultStartDate), endDate: (props.editedResource.endDate || defaultEndDate), [name]: !props.editedResource[name]}))
      props.setNeedsSaving(true)
    }

    const handleChange = e => {
      const { name, value } = e.target;
      props.setEditedResource(prevState => ({...prevState, [name]: value}))
      props.setNeedsSaving(true)
    }

    const setStartDate = (date) => {
      date.setHours(0)
      date.setMinutes(0)
      date.setSeconds(0)
      date.setMilliseconds(0)
      props.setEditedResource(prevState => ({...prevState, startDate: date}))
      props.setNeedsSaving(true)
    }
    const setEndDate = (date) => {
      date.setHours(23)
      date.setMinutes(59)
      date.setSeconds(59)
      date.setMilliseconds(999)
      props.setEditedResource(prevState => ({...prevState, endDate: date}))
      props.setNeedsSaving(true)
    }

    const renderGeneralTab = () => {
      return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto', overflowX: 'hidden', padding: '10px'}}>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center'}}>
            <div style={{marginBottom: '10px', flexGrow: 1, flexShrink: 1, minWidth: 0, marginRight: '10px'}}>{props.textInput('title', 'Titre', true)}</div>
          </div>
          <Grid container item direction='column' spacing={2}>
            <Grid item><Typography variant='h6'>Publication</Typography></Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
              <Grid item>
                <FormControl>
                  <FormGroup>
                    <RadioGroup row name='status' onChange={handleChange} value={props.editedResource.status}>
                      <FormControlLabel value='published' control={<Radio />} label='Publié' />
                      <FormControlLabel value='unpublished' control={<Radio />} label='Non publié' />
                    </RadioGroup>
                  </FormGroup>
                  <FormGroup row={true}>
                    <FormControlLabel control={<Checkbox name='hasStartDate' disabled={props.editedResource.status === 'unpublished'} checked={props.editedResource.hasStartDate} onChange={toggle} />} label='Visible à partir du' />
                    <KeyboardDatePicker
                      disabled={!props.editedResource.hasStartDate || props.editedResource.status === 'unpublished'}
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Date de début"
                      value={props.editedResource.startDate || new Date()}
                      onChange={setStartDate}
                      style={{width: 'unset'}}
                    />
                  </FormGroup>
                  <FormGroup row>
                    <FormControlLabel control={<Checkbox name='hasEndDate' disabled={props.editedResource.status === 'unpublished'} checked={props.editedResource.hasEndDate} onChange={toggle} />} label="Date limite de réponse" />
                    <KeyboardDatePicker
                      disabled={!props.editedResource.hasEndDate || props.editedResource.status === 'unpublished'}
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Date limite de réponse"
                      value={props.editedResource.endDate || new Date()}
                      onChange={setEndDate}
                      style={{width: 'unset'}}
                    />
                  </FormGroup>
                  <FormGroup row>
                    <FormControlLabel control={<Checkbox name='perpetual' checked={props.editedResource.perpetual} onChange={toggle} />} label="Commande perpétuelle" />
                  </FormGroup>
                </FormControl>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <div style={{width: '100%', height: '300px', flexGrow: 0, flexShrink: 0, marginBottom: '50px', marginTop: '10px'}}>
            <Typography variant='h6'>Description de la commande</Typography>
            {renderEditor()}
          </div>
        </div>
      )
    }

    const addArticle = () => {
      props.setEditedResource(prevState => ({...prevState, articles: [...props.editedResource.articles, {id: Date.now(), name: "", description: "", hasVariants: false, variants: [], unitPrice: ""} ]}))
      props.setNeedsSaving(true)
    }

    const addVariant = (i) => {
      let articles = [...props.editedResource.articles]
      articles[i].variants.push({id: Date.now(), name: "", unitPrice: ""})
      props.setEditedResource(prevState => ({...prevState, articles: articles}))
      props.setNeedsSaving(true)
    }

    const removeVariant = (i, j) => {
      let articles = [...props.editedResource.articles]
      articles[i].variants.splice(j, 1)
      props.setEditedResource(prevState => ({...prevState, articles: articles}))
      props.setNeedsSaving(true)
    }

    const handleArticleChange = (i, e) => {
      const { name, value } = e.target;
      console.log(name, value)
      if ((name === 'availableStock' && value < 0) || (name === 'minQuantity' && value < 1)) return
      console.log('ooo')
      let articles = [...props.editedResource.articles]
      articles[i][name] = value
      props.setEditedResource(prevState => ({...prevState, articles: articles}))
      props.setNeedsSaving(true)
    }

    const handleVariantChange = (i, j, e) => {
      const { name, value } = e.target;
      if ((name === 'availableStock' || name === 'minQuantity') && value < 0) return
      let articles = [...props.editedResource.articles]
      articles[i].variants[j][name] = value
      props.setEditedResource(prevState => ({...prevState, articles: articles}))
      props.setNeedsSaving(true)
    }

    const toggleArticleChange = (i, e) => {
      let {name} = e.target
      let articles = [...props.editedResource.articles]
      articles[i][name] = !articles[i][name]
      props.setEditedResource(prevState => ({...prevState, articles: articles}))
      props.setNeedsSaving(true)
      setOpenArticleIndex(articles[i][name] ? i : (openArticleIndex === i ? null : openArticleIndex))
    }

    const removeArticle = (i) => {
      let articles = [...props.editedResource.articles]
      articles.splice(i, 1)
      props.setEditedResource(prevState => ({...prevState, articles: articles}))
      props.setNeedsSaving(true)
    }

    const toggleOpenArticleIndex = (i) => {
      setOpenArticleIndex(openArticleIndex === i ? null : i)
    }

    const renderArticleRow = (article, i) => {
      return (
        <>
        <TableRow key={i}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" style={{visibility: (article.hasVariants ? undefined : 'hidden')}} onClick={toggleOpenArticleIndex.bind(null, i)}>
              {openArticleIndex === i ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </TableCell>
          <TableCell>
            <TextField size='small' fullWidth variant='outlined' value={article.name} name='name' onChange={handleArticleChange.bind(null, i)} />
          </TableCell>
          <TableCell>
            <TextField size='small' multiline rows={4} fullWidth variant='outlined' value={article.description} name='description' onChange={handleArticleChange.bind(null, i)} />
          </TableCell>
          <TableCell align='center'>
            <FormGroup>
              <FormControlLabel control={<Checkbox name={'hasVariants'} checked={article.hasVariants} onChange={toggleArticleChange.bind(null, i)} />} />
            </FormGroup>
          </TableCell>
          <TableCell>
            <TextField style={{visibility: (article.hasVariants ? 'hidden' : undefined)}} size='small' fullWidth variant='outlined' value={article.unitPrice} name='unitPrice' onChange={handleArticleChange.bind(null, i)} />
          </TableCell>
          <TableCell>
            <TextField style={{visibility: (article.hasVariants ? 'hidden' : undefined)}} type='number' size='small' fullWidth variant='outlined' value={article.minQuantity || 0} name='minQuantity' onChange={handleArticleChange.bind(null, i)} />
          </TableCell>
          {
            props.editedResource.perpetual && (
              <TableCell>
                <TextField style={{visibility: (article.hasVariants ? 'hidden' : undefined)}} type='number' size='small' fullWidth variant='outlined' value={article.availableStock || 0} name='availableStock' onChange={handleArticleChange.bind(null, i)} />
              </TableCell>
            )
          }
          <TableCell>
            <a className='btn btn-icon btn-light btn-sm' onClick={removeArticle.bind(null, i)}>
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
              </span>
            </a>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={openArticleIndex === i} timeout="auto" unmountOnExit>
              <Box margin={1} style={{paddingBottom: '20px', paddingLeft: '10px'}}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item>
                    <Typography variant='h6'>Variantes proposées</Typography>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary" size='small' onClick={addVariant.bind(null, i)}>
                      <FontAwesomeIcon icon='plus' style={{marginRight: '10px'}}/>
                      Ajouter une variante
                    </Button>
                  </Grid>
                </Grid>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nom</TableCell>
                      <TableCell>Prix unitaire HT</TableCell>
                      <TableCell>Quantité min.</TableCell>
                      {props.editedResource.perpetual && <TableCell>Stock disponible</TableCell>}
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      article.variants.map((variant, j) => {
                        return (
                          <TableRow key={j}>
                            <TableCell>
                              <TextField size='small' margin='dense' fullWidth variant='outlined' value={variant.name} name='name' onChange={handleVariantChange.bind(null, i, j)} />
                            </TableCell>
                            <TableCell>
                              <TextField size='small' margin='dense' fullWidth variant='outlined' value={variant.unitPrice} name='unitPrice' onChange={handleVariantChange.bind(null, i, j)} />
                            </TableCell>
                            <TableCell>
                              <TextField size='small' margin='dense' fullWidth type='number' variant='outlined' value={variant.minQuantity || 0} name='minQuantity' onChange={handleVariantChange.bind(null, i, j)} />
                            </TableCell>
                            {props.editedResource.perpetual && (
                              <TableCell>
                                <TextField size='small' margin='dense' fullWidth type='number' variant='outlined' value={variant.availableStock || 0} name='availableStock' onChange={handleVariantChange.bind(null, i, j)} />
                              </TableCell>
                            )}
                            <TableCell>
                              <a className='btn btn-icon btn-light btn-sm' onClick={removeVariant.bind(null, i, j)}>
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
                                </span>
                              </a>
                            </TableCell>
                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        </>
      )
    }

    const renderArticlesTab = () => {
      return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%', overflowY: 'auto', overflowX: 'hidden', padding: '10px'}}>
          <Grid container direction='row' style={{marginTop: '10px', marginBottom: '10px'}}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={addArticle}>
                <FontAwesomeIcon icon='plus' style={{marginRight: '15px'}} />
                Ajouter un article
              </Button>
            </Grid>
          </Grid>

          <TableContainer>
            <Table stickyHeader size='small'>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>NOM</TableCell>
                  <TableCell>DESCRIPTION</TableCell>
                  <TableCell>PLUSIEURS VARIANTES&nbsp;?</TableCell>
                  <TableCell>PRIX UNITAIRE HT</TableCell>
                  <TableCell>QUANTITÉ MIN.</TableCell>
                  {props.editedResource.perpetual && <TableCell>STOCK DISPONIBLE</TableCell>}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {props.editedResource.articles.map(renderArticleRow)}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )
    }

    return (
      <div className='Orders' style={{display: 'flex', flexDirection: 'column', flexGrow: 1, flexShrink: 1, minHeight: 0}}>
        <Card style={{flexGrow: 1, flexShrink: 1, minHeight: 0, marginBottom: '10px'}}>
          <CardContent style={{height: '100%', display: 'flex', flexDirection: 'column', padding: 0, paddingTop: '10px'}}>
            <Tabs defaultActiveKey="general" style={{paddingLeft: '10px', paddingRight: '10px'}}>
              <Tab eventKey='general' title='Général'>
                {renderGeneralTab()}
              </Tab>
              <Tab eventKey='articles' title='Articles'>
                {renderArticlesTab()}
              </Tab>
            </Tabs>
          </CardContent>
        </Card>
        <Card style={{flexGrow: 0, flexShrink: 0}}>
          <CardActions>
            {
              props.needsSaving && (
                <Button variant="contained" color="primary" onClick={props.save}>
                  Enregistrer
                </Button>
              )
            }
            <Button variant="contained" color="secondary" onClick={props.cancel}>
              Annuler
            </Button>
          </CardActions>
        </Card>
      </div>
    )
  }

  const removeQuestion = (article) => {
    return `Voulez-vous vraiment supprimer la commande\n \"${article.title}\" ?`
  }

  const getNewResource = () => {
    return {
      title: 'Nouvelle commande',
      date: new Date(),
      status: 'published',
      hasStartDate: false,
      hasEndDate: false,
      articles: [],
      answers: {},
      userViews: []
    }
  }

  const perpetualDetailsRows = (answers) => {
    let rows = []
    for (let shopId in answers) {
      let i = 0
      for (let answer of answers[shopId]) {
        if (!answer.find(art => art.done)) {
          rows.push({articles: answer, shopName: shopsById[shopId]?.name, shopId: shopId, index: i})
        }
        ++i
      }
    }
    rows.sort((a, b) => (new Date(b.articles[0].date).getTime() - new Date(a.articles[0].date).getTime()))

    return rows
  }

  return (
    <>
      <ResourceList
        resourceType='order'
        filterFields={['title']}
        sortResources={(user && user.role === 'admin') ? sortOrders : undefined}
        addLabel="Nouvelle commande"
        removeLabel="Suppression d'une commande"
        removeQuestion={removeQuestion}
        headers={(user && user.role !== 'admin') ? ['Titre', 'Date limite de réponse', 'Votre réponse'] : ['Titre', 'Statut', "Réponses"]}
        resourceRow={(user && user.role === 'admin') ? OrderRow : OrderRowAdherent}
        resourceForm={OrderForm}
        getNewResource={getNewResource}
        normalUserApi={'orders/public'}
        tabSegmentation={['perpetual', "Commandes uniques", "Commandes récurrentes"]}
        noNormalUserAction
        excludeFromPost={['answers']}
      />
      <Modal scrollable show={userViewsDetail !== null}>
        <Modal.Header>
          <Modal.Title>
            Utilisateurs ayant ouvert le bon de commande
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: 0}}>
          {
            userViewsDetail && (
              <List dense>
                {
                  userViewsDetail.map((user, i) => {
                    const fullName = user.firstName + ' ' + user.lastName.toUpperCase()
                    return (
                      <>
                        <ListItem key={i}>
                          <ListItemAvatar><Avatar alt={fullName} src={'/api/media/thumbnail/' + user.picture} /></ListItemAvatar>
                          <ListItemText primary={fullName} />
                        </ListItem>
                        <Divider />
                      </>
                    )
                  })
                }
              </List>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button style={{marginRight: '10px'}} variant="contained" color="secondary" onClick={() => { setUserViewsDetail(null) }}>Fermer</Button>
        </Modal.Footer>
      </Modal>

      <Modal scrollable size='xl' show={perpetualDetails !== null}>
        <Modal.Header>
          <Modal.Title>
            {perpetualDetails && perpetualDetails.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: 0}}>
          {
            perpetualDetails && (
              <Table dense>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Date</TableCell>
                    <TableCell>Point de vente</TableCell>
                    <TableCell>Articles commandés</TableCell>
                    {perpetualDetails.perpetualAnswers && <TableCell></TableCell>}
                  </TableRow>
                </TableHead>
                {
                  perpetualDetailsRows(perpetualDetails.perpetualAnswers).map((answer, i) => {
                    return <PerpetualDetailsRow {...answer} products={perpetualDetails.articles} key={i} id={perpetualDetails._id} />
                  })
                }
              </Table>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button style={{marginRight: '10px'}} variant="contained" color="secondary" onClick={() => { setPerpetualDetails(null); if (fetchSingle) fetchSingle() }}>Fermer</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Orders
