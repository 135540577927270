import axios from "axios"

export const LOGIN_URL = "/api/user/token";
export const REGISTER_URL = "/api/verify_user";
export const REQUEST_PASSWORD_URL = "/api/user/request_password_reset";

export const ME_URL = "/api/me";

let axiosInstance = axios.create({
  baseURL: process.env.PUBLIC_URL,
  validateStatus: (status) => {
    return status >= 200 && status < 500
  }
})

axiosInstance.interceptors.request.use((config) => {
  config.headers.token = window.localStorage.getItem('token')
  return config
}, (error) => {
  console.log(error)
})

axiosInstance.interceptors.response.use((response) => {
  if (response.status === 401) {
    console.log(window.location)
    if (!window.location.href.endsWIth('/auth/login')) {
      window.localStorage.removeItem('token')
      window.location.replace('/auth/login')
    }
  } else if (response.data.token) {
    window.localStorage.setItem('token', response.data.token)
    window.location.replace('/')
  }
  return response.data
}, (error) => {
  console.log(error)
})

export function login(email, password) {
  return axiosInstance.post(LOGIN_URL, { email, password });
}

export function register(emailVerificationToken, password) {
  return axiosInstance.post(REGISTER_URL, { emailVerificationToken, password });
}

export function requestPassword(email) {
  return axiosInstance.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axiosInstance.get(ME_URL);
}
