import React, {useState, useEffect, useRef} from "react";
import { Table, Form, Col, Modal } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../_metronic/_helpers";
import './Affiliates.scss'
import ajax from '../utils/ajax'
import Media from '../components/Media/Media'
import Media2 from '../components/Media2/Media'
import {TextField, Grid, Paper, Card, CardContent, CardActions, Typography, Button, TableCell, RadioGroup, Radio, FormControl, FormLabel, FormControlLabel, Switch, Checkbox} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { cb } from "react-syntax-highlighter/dist/esm/styles/prism";
import async from 'async'
import {useGlobalData} from '../hooks/useGlobalData'
import ResourceList from '../components/ResourceList/ResourceList'
import PlacesAutocomplete from '../components/PlacesAutocomplete/PlacesAutocomplete'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr"
import { Editor } from '@tinymce/tinymce-react'

library.add(fas)

const days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche']

const OpeningHours = (props) => {
  let dates = []
  for (let i = 0; i < 4; ++i) {
    dates.push(new Date())
    dates[i].setHours(props.hours[i][0])
    dates[i].setMinutes(props.hours[i][1])
  }

  return (
    <Grid container wrap='nowrap' direction='column' spacing={0}>
      <Grid container item wrap='nowrap' direction='row' spacing={2}>
        <Grid item>
          <FormControlLabel
            control={<Switch checked={props.open} onChange={props.toggleOpen} color="secondary" />}
            label={days[props.dayIndex]}
          />
        </Grid>
        {
          props.open && (
            <Grid item>
              <FormControlLabel
                control={<Checkbox checked={props.continuousDay} onChange={props.toggleContinuousDay} color="secondary" />}
                label={'Journée continue'}
              />
            </Grid>
          )
        }
      </Grid>
      {
        props.open && (
          <Grid container item wrap='nowrap' direction='row' spacing={1}>
              <Grid item>
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label={"Heure d'ouverture" + (!props.continuousDay ? " (matin)" : '')}
                  okLabel="OK"
                  cancelLabel="Annuler"
                  ampm={false}
                  value={dates[0]}
                  onChange={date => props.setHour(0, date)}
                  inputProps={{
                    step: 300
                  }}
                />
              </Grid>
              <Grid item>
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label={"Heure de fermeture" + (!props.continuousDay ? " (matin)" : '')}
                  okLabel="OK"
                  cancelLabel="Annuler"
                  ampm={false}
                  value={dates[1]}
                  onChange={date => props.setHour(1, date)}
                  inputProps={{
                    step: 300
                  }}
                />
              </Grid>
              {
                !props.continuousDay && (
                  <>
                    <Grid item>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="Heure d'ouverture (après-midi)"
                        okLabel="OK"
                        cancelLabel="Annuler"
                        ampm={false}
                        value={dates[2]}
                        onChange={date => props.setHour(2, date)}
                        inputProps={{
                          step: 300
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="Heure de fermeture (après-midi)"
                        okLabel="OK"
                        cancelLabel="Annuler"
                        ampm={false}
                        value={dates[3]}
                        onChange={date => props.setHour(3, date)}
                        inputProps={{
                          step: 300
                        }}
                      />
                    </Grid>
                  </>
                )
              }
            </Grid>
        )
      }
    </Grid>
  )
}

export const Shops = () => {
  const [affiliates, setAffiliates] = useState(null)
  const [affiliateList, setAffiliateList] = useState(null)
  const [users, setUsers] = useState(null)
  const [usersById, setUsersById] = useState(null)

  const globalData = useGlobalData()
  const user = globalData.user

  const fetch = () => {
    let script_tag = document.createElement('script');
    script_tag.setAttribute("type","text/javascript");
    script_tag.setAttribute("src","https://maps.googleapis.com/maps/api/js?key=AIzaSyC0TQ0RZ-ZiGWQCxDh9TgQdvf27wC_J6YI&v=3.exp&libraries=places");
    (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script_tag);

    ajax.get('/api/affiliates').then(r => {
      let aff = {}
      for (let affiliate of r) {
        aff[affiliate._id] = affiliate
      }
      setAffiliates(aff)
      r.sort((a, b) => a.name.localeCompare(b.name))
      setAffiliateList(r)
    })
    ajax.get('/api/users').then(r => {
      r.sort((a, b) => a.lastName.localeCompare(b.lastName))
      setUsers(r)
      let byId = {}
      r.forEach(user => { byId[user._id] = user })
      setUsersById(byId)
    })
  }
  useEffect(fetch, [])

  const saveBlob = (function () {
    var a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"
    return function (blob, fileName) {
      var url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = fileName
      a.click()
      window.URL.revokeObjectURL(url)
    }
  }())

  const downloadCSV = (meeting) => {
    ajax.get('/api/shops/csv', {responseType: 'arraybuffer'}).then(res => {
      const blob = new Blob([res], { type: 'text/csv' })
      saveBlob(blob, 'points_de_vente.csv')
    })
  }

  const removeQuestion = (shop) => {
    return `Voulez-vous vraiment supprimer le point de vente ${shop.name} ?`
  }

  const getNewShop = () => {
    return ({name: '', type: '', address: ''})
  }

  const sortShops = (shops) => {
    shops.sort((a, b) => a.name.localeCompare(b.name))
  }

  /*const ShopListItem = (props) => {
    return (

    )
  }*/

  const ShopRow = (props) => {
    const formatPhone = (phone) => {
      let phoneBak = phone
      if (!phone || phone.length === 0) return null
      phone = phone.replace(/\s+/g, '')
      phone = phone.replace(/\./g, '')
      phone = phone.replace(/\//g, '')
      if (!phone || phone.length === 0) return null
      if (phone.length === 9 && !phone.startsWith('0')) phone = '0' + phone
      if (phone.startsWith('33')) phone = '0' + phone.slice(2)
      if (!phone.startsWith('0')) phone = '+' + phone
      if (phone.startsWith('00')) phone = '+' + phone.slice(2)
      if (phone.startsWith('+')) return phone
      if (!phone || phone.length !== 10) {
        if (phone) console.log(phone, phoneBak)
        return phoneBak
      }

      return phone.slice(0, 2) + ' ' + phone.slice(2, 4) + ' ' + phone.slice(4, 6) + ' ' + phone.slice(6, 8) + ' ' + phone.slice(8, 10)
    }

    return [
      <TableCell key='logo' style={{maxWidth: '138px'}}>{props.logo && <img height={100} src={'/api/media/thumbnail/' + props.logo} />}</TableCell>,
      <TableCell key='name' style={{maxWidth: '200px'}}>{props.name}</TableCell>,
      <TableCell key='type'>{props.type}</TableCell>,
      <TableCell key='affiliate'>{affiliates && affiliates[props.affiliate] && affiliates[props.affiliate].name}</TableCell>,
      <TableCell key='address' style={{maxWidth: '300px'}}>{props.address ? props.address.replace(', France', '') : ''}</TableCell>,
      <TableCell key='manager'>
        {props.manager && usersById[props.manager] && (
          <div className='row'>
            <div>
              <div style={{marginBottom: '7px'}}>
                {usersById[props.manager].firstName + ' ' + usersById[props.manager].lastName}
              </div>
              <div style={{marginBottom: '3px'}}>
                <FontAwesomeIcon icon='at' style={{marginRight: '2px'}} />
                <a href={'mailto:' + usersById[props.manager].email}>{usersById[props.manager].email}</a>
              </div>
              <div style={{marginBottom: '3px'}}>
                <FontAwesomeIcon icon='phone' style={{marginRight: '7px'}} />
                {formatPhone(usersById[props.manager].phone) || 'Non renseigné'}
              </div>
              <div>
                <FontAwesomeIcon icon='mobile-alt' style={{width: '13px', marginRight: '7px'}} />
                {formatPhone(usersById[props.manager].mobile) || 'Non renseigné'}
              </div>
            </div>
          </div>
        )}
      </TableCell>
    ]
  }

  const ShopForm = (props) => {
    const imageInputRef = useRef()
    if (!users || !usersById) return null

    if (!props.editedResource.openingHours) {
      props.editedResource.openingHours = []
      for (let i = 0; i < 7; ++i) {
        props.editedResource.openingHours.push({dayIndex: i, open: true, continuousDay: false, hours: [[8, 0], [12, 0], [14, 0], [18, 0]]})
      }
    }

    const handleEditorChange = (content, editor) => {
      let article = {...props.editedResource}
      article.description = content
      props.setEditedResource(article)
      props.setNeedsSaving(true)
     }

    const renderEditor = () => {
      return (
        <Editor value={props.editedResource.description && props.editedResource.description.replace(/src=\"api/g, "src=\"https://intranetv2.comptoirdelours.fr/api")} onEditorChange={handleEditorChange} apiKey='1a44ucvt07bi0x6k4rxvdbzc2im5lghbn2ojkkf0bp6cbquj'
        init={{
          width: '100%',
          height: '600px',
          language: 'fr_FR',
          language_url: '/langs/fr_FR.js',
          plugins: 'image preview autolink save link media table charmap hr anchor advlist wordcount help paste',
          menubar: 'file edit view insert format tools table help',
          toolbar: 'undo redo | bold italic underline strikethrough | formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist',
          content_css: [
              '//fonts.googleapis.com/css?family=Poppins|Open+Sans:400,700&display=swap',
            ],
          content_style: 'h1 {font-family: Poppins; font-size: 30px; color: rgba(0,0,0,0.87); font-weight: 700; text-transform: uppercase; text-align: center; margin-bottom: 20px!important;}' +
              'h2 {font-family: Poppins; font-size: 20px; font-weight: 700; line-height: 33px; color: rgba(0,0,0,0.87);}' +
              'p {font-family: Open Sans; font-weight: 400; font-size: 14px; line-height: 25px; color: rgba(0,0,0,0.87); margin-bottom: 20px;}' +
              'body {max-width: 100%; padding: 10px 20px; font-family: Open Sans; white-space: pre-wrap;}' +
              'img {max-width: 100%; height: auto; margin-bottom: 20px;}' +
              '.caption {color: red;}',
          block_formats: 'Titre principal=h1; Titre de section=h2; Paragraphe=p',
          style_formats: {title: 'Légende', format: 'caption'},
          formats: {
            caption: { block: 'div', classes: 'caption' }
          },
          paste_as_text: true,
          images_upload_handler: (blobInfo, success, failure) => {
            let data = new FormData()
            data.append('file', blobInfo.blob(), blobInfo.filename())
            ajax.put('/api/media', data).then(response => {
              success(response.path)
            }).catch(e => {
              failure()
            })
          }
        }}

        />
      )
    }

    const toggleDay = (dayIndex) => {
      let openingHours = [...props.editedResource.openingHours]
      openingHours[dayIndex].open = !openingHours[dayIndex].open
      props.setEditedResource(prevState => ({...prevState, openingHours: openingHours}))
      props.setNeedsSaving(true)
    }

    const toggleContinuousDay = (dayIndex) => {
      let openingHours = [...props.editedResource.openingHours]
      openingHours[dayIndex].continuousDay = !openingHours[dayIndex].continuousDay
      props.setEditedResource(prevState => ({...prevState, openingHours: openingHours}))
      props.setNeedsSaving(true)
    }

    const setHour = (dayIndex, index, date) => {
      if (!date) return
      let openingHours = [...props.editedResource.openingHours]
      openingHours[dayIndex].hours[index] = [date.getHours(), date.getMinutes()]
      props.setEditedResource(prevState => ({...prevState, openingHours: openingHours}))
      props.setNeedsSaving(true)
    }


    const openImageInput = () => {
      if (imageInputRef) {
        imageInputRef.current.click()
      }
    }

    const addImage = (e) => {
      let data = new FormData()
      data.append('file', e.target.files[0])
      ajax.put('/api/media', data).then((media) => {
        let images = props.editedResource.images ? [...props.editedResource.images] : []
        images.push(media._id)
        props.setEditedResource(prevState => ({...prevState, images: images}))
        props.setNeedsSaving(true)
      })
    }

    const removeImage = (i) => {
      let images = [...props.editedResource.images]
      images.splice(i, 1)
      props.setEditedResource(prevState => ({...prevState, images: images}))
      props.setNeedsSaving(true)
    }

    return (
      <Card style={props.style}>
        <CardContent>
          <Grid container wrap='nowrap' direction='column' spacing={5}>
            <Grid container item direction="column" spacing={2}>
              <Grid item container direction='row' spacing={2}>
                <Grid item><Media _id={props.editedResource.logo} editable={user.role === 'admin'} onEdit={(img) => { props.setEditedResource(prevState => ({...prevState, logo: img})); props.setReadyToSave(false); props.setNeedsSaving(true) }} /></Grid>
                {
                  user.role === 'admin' && (
                    <Grid item>
                      <Button style={{marginBottom: '15px'}} variant="contained" color="primary" onClick={() => { props.setEditedResource(prevState => ({...prevState, logo: null})); props.setNeedsSaving(true) }}>
                        <FontAwesomeIcon icon='trash' style={{marginRight: '15px'}} />
                        Supprimer le logo
                      </Button>
                    </Grid>
                  )
                }
              </Grid>
              <Grid item>{props.textInput('name', 'Nom', true)}</Grid>
              <Grid item>
                <Autocomplete disabled={user.role !== 'admin'} options={affiliateList.map(a => a._id)} getOptionLabel={(opt) => affiliates[opt].name} value={typeof props.editedResource.affiliate === 'string' ? props.editedResource.affiliate : null} onChange={(e, aff) => { props.setEditedResource(prevState => ({...prevState, affiliate: aff})); props.setNeedsSaving(true) }} renderInput={(params) => <TextField {...params} label="Adhérent" variant="outlined" />} />
              </Grid>
              <Grid item>
                <PlacesAutocomplete address={props.editedResource.address} onChange={(addr, lat, lng) => { props.setEditedResource(prevState => ({...prevState, address: addr, location: {type: 'Point', coordinates: [lng, lat]}})); props.setNeedsSaving(true) }} />
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Charte</FormLabel>
                  <RadioGroup row value={props.editedResource.type} onClick={(evt) => { if (user.role !== 'admin') return; props.setEditedResource(prevState => ({...prevState, type: evt.target.value})); props.setNeedsSaving(true) }}>
                    <FormControlLabel disabled={user.role !== 'admin'} value="CDO" control={<Radio />} label="CDO" />
                    <FormControlLabel disabled={user.role !== 'admin'} value="HBF" control={<Radio />} label="HBF" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item>
                <Autocomplete options={users.map(u => u._id)} getOptionLabel={(opt) => (usersById[opt] ? (usersById[opt].firstName + ' ' + usersById[opt].lastName) : '')} value={(props.editedResource.manager) || null} onChange={(e, user) => { props.setEditedResource(prevState => ({...prevState, manager: user})); props.setNeedsSaving(true) }} renderInput={(params) => <TextField {...params} label="Responsable de point de vente" variant="outlined" />} />
              </Grid>
              <Grid container item direction='row' spacing={2}>
                <Grid item>{props.textInput('phone', 'Téléphone')}</Grid>
                <Grid item>{props.textInput('email', 'Adresse e-mail')}</Grid>
              </Grid>
              <Grid item>
                <Typography variant="h6">Horaires d'ouverture</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                  <Grid container direction='column' spacing={5}>
                    {props.editedResource.openingHours.map(o => (
                      <Grid item>
                        <OpeningHours key={o.dayIndex} dayIndex={o.dayIndex} open={o.open} hours={o.hours} setHour={setHour.bind(null, o.dayIndex)} continuousDay={o.continuousDay} toggleContinuousDay={toggleContinuousDay.bind(this, o.dayIndex)} toggleOpen={toggleDay.bind(this, o.dayIndex)} />
                      </Grid>
                    ))}
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item>
                {props.textInput('slug', 'Slug')}
              </Grid>
              <div style={{width: '100%', height: '100%', flexGrow: 1, flexShrink: 1, minHeight: 0}}>
                <Typography variant='h6'>Description du point de vente</Typography>
                {renderEditor()}
              </div>
              <Grid item>
                <Typography variant='h6'>Galerie de photos</Typography>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '15px'}}>
                  <Button style={{flexShrink: 0, marginRight: '15px'}} color="primary" variant="contained" onClick={openImageInput}>
                    <FontAwesomeIcon icon='plus' style={{marginRight: '15px'}} />
                    Ajouter une image
                  </Button>
                  <input type='file' accept="image/png, image/jpeg, image/jpg" ref={imageInputRef} style={{display: 'none'}} onChange={addImage} />
                </div>
                <div className='media-gallery'>
                  {props.editedResource.images && props.editedResource.images.map((media, i) =>
                    <Media2 _id={media} key={media} type='image' onRemove={removeImage.bind(null, i)} />
                  )}
                </div>
              </Grid>

            </Grid>
          </Grid>

          <CardActions style={{marginTop: '30px'}}>
            {
              props.needsSaving && (
                <Button variant="contained" color="primary" onClick={props.save}>
                  Enregistrer
                </Button>
              )
            }
            <Button variant="contained" color="secondary" onClick={props.cancel}>
              Annuler
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    )
  }


  if (!user || !usersById) return null

  return (
    <>
      <ResourceList
        resourceType='shop'
        filterFields={['name', 'address', 'type']}
        addLabel="Ajouter un point de vente"
        removeLabel="Suppression d'un point de vente"
        removeQuestion={removeQuestion}
        headers={['Logo', 'Nom', 'Charte', 'Adhérent', 'Adresse', 'Responsable']}
        resourceRow={ShopRow}
        resourceForm={ShopForm}
        pictureFields={['logo']}
        getNewResource={getNewShop}
        sortResources={sortShops}
        downloadCSV={downloadCSV}
        editMineOnly
        noDelete
        allowMarketing
        noNormalAdd
        noNormalView
      />
    </>
  )
}

export default Shops
