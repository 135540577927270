import React, {useState, useEffect} from "react";
import ajax from '../utils/ajax'
import {TableCell, Button, Grid, Card, Typography, TextField, CardContent, CardActions, CardHeader, CardMedia, Avatar} from '@material-ui/core'
import { useSubheader } from "../../_metronic/layout"
import './Article.scss'

export const Deal = (props) => {
  const [article, setArticle] = useState(null)
  const [editedAnswer, setEditedAnswer] = useState(null)
  const subheader = useSubheader();

  const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  const months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']

  const twoDigitString = (n) => {
    if (n < 10) return ('0' + n.toString())
    return n.toString()
  }
  const shortDateString = (date, noDay) => {
    return (noDay ? '' : days[date.getDay()]) + ' ' + twoDigitString(date.getDate()) + '/' + twoDigitString(date.getMonth() + 1) + '/' + date.getFullYear() + " à " + twoDigitString(date.getHours()) + 'h' + twoDigitString(date.getMinutes())
  }

  const fetch = () => {
    const id = (props.match && props.match.params.id || props._id)
    ajax.get('/api/deal/' + id).then(r => {
      let breadcrumbs = []
      breadcrumbs.push({
          text: 'Accueil',
          title: 'Accueil',
          href: '/'
      })
      breadcrumbs.push({
          text: r.title,
          title: r.title,
          href: '/deal/' + id
      });
      subheader.setBreadcrumbs(breadcrumbs)
      subheader.setTitle(r.title)
      setArticle(r)

      ajax.post('/api/deal/view', {_id: id}).then(r => {})
    })
  }
  useEffect(fetch, [])

  const sendAnswer = () => {
    const id = (props.match && props.match.params.id || props._id)
    ajax.post('/api/deal/' + id + '/answer', {content: editedAnswer}).then(() => {
      setEditedAnswer(null)
      fetch()
    })
  }

  if (!article) return null
  return (
    <>
      <Card style={{flexGrow: 0, flexShrink: 0}}>
        <CardHeader
          avatar={<Avatar alt={article.author.firstName + ' ' + article.author.lastName.toUpperCase()} src={"/api/media/thumbnail/" + article.author.picture} />}
          title={article.author.firstName + ' ' + article.author.lastName.toUpperCase()}
          subheader={shortDateString(new Date(article.date))}
          style={{padding: '8px'}}
        />
        <CardContent style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap-reverse'}}>
          {
            article.picture && (
              <img
                src={'/api/media/' + article.picture}
                style={{minWidth: '300px', maxWidth: '450px', width: '40%', height: 'auto', marginRight: '10px', marginBottom: '10px'}}
              />
            )
          }
          <div dangerouslySetInnerHTML={{__html: article.content.replace(/src=\"api/g, "src=\"https://intranetv2.comptoirdelours.fr/api")}} className='ArticleContent' />
        </CardContent>
      </Card>

      {
        article.answers && (
          article.answers.map(answer => (
            <Card className='right' style={{flexGrow: 0, flexShrink: 0}}>
              <CardHeader
                className='right'
                avatar={<Avatar alt={answer.author.firstName + ' ' + answer.author.lastName.toUpperCase()} src={"/api/media/thumbnail/" + answer.author.picture} />}
                title={answer.author.firstName + ' ' + answer.author.lastName.toUpperCase()}
                subheader={shortDateString(new Date(answer.date))}
                style={{padding: '8px'}}
              />
              <CardContent style={{display: 'flex', flexDirection: 'row'}}>
                <div className='ArticleContent right' style={{whiteSpace: 'pre-wrap'}}>{answer.content}</div>
              </CardContent>
            </Card>
          ))
        )
      }

      {
        article.status === 'published' && (
          <Card style={{flexGrow: 0, flexShrink: 0}}>
            <CardContent style={{display: 'flex', flexDirection: 'column'}}>
              <TextField size='small' style={{width: '100%'}} multiline={true} rows={8} label="Votre réponse" variant='outlined' value={editedAnswer || ''} onChange={(e) => { setEditedAnswer(e.target.value) }} />
              <div>
                <Button variant="contained" color="primary" onClick={sendAnswer} style={{marginTop: '16px'}}>
                  Envoyer
                </Button>
              </div>
            </CardContent>
          </Card>
        )
      }
      
      {
        props.validation && (
          <Card style={{marginTop: '10px', flexShrink: 0, flexGrow: 0}}>
            <CardActions>
              <Button variant="contained" color="primary" onClick={props.validate}>
                Valider
              </Button>
              <Button variant="contained" color="secondary" onClick={props.reject}>
                Rejeter
              </Button>
            </CardActions>
          </Card>
        )
      }
    </>
  )
}
