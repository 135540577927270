import React, {useState, useEffect} from "react";
import {Modal} from 'react-bootstrap';
import {TableContainer, Box, Collapse, IconButton, Select, MenuItem, TableHead, TableBody, Table, TableRow, TableCell, TextField, TextArea, Button, Grid, Card, CardContent, CardActions, Avatar, Typography, Radio, RadioGroup, FormControl, FormControlLabel, FormGroup, FormLabel, Checkbox, CheckboxList, List, ListItem, ListItemAvatar, ListItemText, Divider} from '@material-ui/core'
import './Users.scss'
import ajax from '../utils/ajax'
import Media from '../components/Media/Media'
import ResourceList from '../components/ResourceList/ResourceList'
import { Editor } from '@tinymce/tinymce-react'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import frLocale from "date-fns/locale/fr"
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Tabs, Tab} from 'react-bootstrap'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../_metronic/_helpers";
import {useGlobalData} from '../hooks/useGlobalData'
import './Surveys.scss'

const saveBlob = (function () {
  var a = document.createElement("a")
  document.body.appendChild(a)
  a.style = "display: none"
  return function (blob, fileName) {
    var url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = fileName
    a.type = "text/csv"
    a.click()
    window.URL.revokeObjectURL(url)
  }
}())

const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
const twoDigitString = (n) => {
  if (n < 10) return ('0' + n.toString())
  return n.toString()
}
const shortDateString = (date, noDay) => {
  return (noDay ? '' : days[date.getDay()]) + ' ' + twoDigitString(date.getDate()) + '/' + twoDigitString(date.getMonth() + 1) + '/' + date.getFullYear()
}

export const Surveys = () => {
  const [userViewsDetail, setUserViewsDetail] = useState(null)
  const [perpetualDetails, setPerpetualDetails] = useState(null)
  const [openPerpetualSurvey, setOpenPerpetualSurvey] = useState(null)

  const globalData = useGlobalData()
  const user = globalData.user
  const [shops, setShops] = useState([])
  const [shopsById, setShopsById] = useState([])
  const [fetchSingle, setFetchSingle] = useState(null)

  const fetch = () => {
    ajax.get('/api/user/shops').then(res => {
      setShops(res)
      let byId = {}
      for (let shop of res) {
        byId[shop._id] = shop
      }
      setShopsById(byId)
    })
  }
  useEffect(fetch, [])

  const status = (question) => {
    let str = ""
    if (question.status === 'published') str += "Publié"
    else if (question.status === 'unpublished') str += "Non publié"

    if (question.hasStartDate && question.hasEndDate) {
      str += " du " + new Date(question.startDate).toLocaleDateString() + " au " + new Date(question.endDate).toLocaleDateString()
    } else if (question.hasStartDate) {
      str += " à partir du " + new Date(question.startDate).toLocaleDateString()
    } else if (question.hasEndDate) {
      str += " jusqu'au " + new Date(question.endDate).toLocaleDateString()
    }
    return str
  }


  const sortSurveys = (surveys) => {
    if (user && user.role === 'admin') {
      surveys.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    } else {
      surveys.sort((a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime())
    }
  }

  const toggleOpenPerpetualSurvey = (o) => {
    setOpenPerpetualSurvey((openPerpetualSurvey && openPerpetualSurvey._id === o.id) ? null : o)
  }

  const perpetualAnswersCount = (perpetualAnswers => {
    let answers = Object.values(perpetualAnswers)
    let count = 0
    for (let shopAnswer of answers) {
      for (let answer of shopAnswer) {
        if (answer.find(art => art.done)) break
        ++count
      }
    }

    return count
  })

  const SurveyRow = (props) => {
    const downloadCSV = () => {
      ajax.get('/api/survey/' + props._id + '/csv', {responseType: 'arraybuffer'}).then(res => {
        const blob = new Blob([res], { type: 'text/csv' })
        saveBlob(blob, props.title + ' - BDC.csv')
      })
    }

    const visible = props.status === 'published' && (!props.hasStartDate || new Date(props.startDate).getTime() <= Date.now()) && (!props.hasEndDate || new Date(props.endDate).getTime() >= Date.now())
    const answerCount = props.perpetualAnswers ? perpetualAnswersCount(props.perpetualAnswers) : Object.keys(props.answers).length
    let aaa = 12//props.fetchSingle
    return [
      <TableCell key='title'>{props.title}</TableCell>,
      <TableCell key='status'>
        {visible && <div style={{marginBottom: '5px', color: "#17c191", textTransform: "uppercase"}}>
          <FontAwesomeIcon icon='check' style={{marginRight: '10px'}} />
          Visible
        </div>}
        {status(props)}
      </TableCell>,
      <TableCell key='answers'>
        {
          !props.perpetualAnswers && (
            <>
            <div style={{marginBottom: '5px', textTransform: "uppercase"}}>
              <FontAwesomeIcon icon='reply' style={{marginRight: '10px'}} />
              {answerCount.toString() + ' ' + (answerCount > 1 ? 'réponses' : 'réponse')}
            </div>
            </>
          )
        }


        {
          props.user.role === 'admin' && (
            <div style={{marginTop: '5px'}}>
              <FontAwesomeIcon icon='eye' style={{marginRight: '10px'}} />
              <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setUserViewsDetail(props.userViews.sort((a, b) => a.lastName.localeCompare(b.lastName)))}>{(props.userViews ? props.userViews.length : 0) + (props.userViews && props.userViews.length > 1 ? " vues" : " vue")}</span>
            </div>
          )
        }
        {
          !props.perpetualAnswers && (
            <Button variant="outlined" color='primary' size="small" style={{marginTop: '5px', marginRight: '10px'}} onClick={downloadCSV}>
              <FontAwesomeIcon icon='download' style={{marginRight: '15px'}} />
              Réponses
            </Button>
          )
        }

      </TableCell>
    ]
  }

  const SurveyRowAdherent = (props) => {
    let hasAnswered = false
    for (let shop of shops) {
      if (props.answers[shop._id]) {
        hasAnswered = true
        break
      }
    }

    const surveyOpen = !props.hasEndDate || new Date(props.endDate).getTime() > Date.now()

    if (!hasAnswered && !surveyOpen) return null

    return [
      <TableCell key='title'>{props.title}</TableCell>,
      <TableCell key='date'>
        {props.hasEndDate && new Date(props.endDate).toLocaleDateString()}
        {!props.hasEndDate && "Aucune date limite définie"}
      </TableCell>,
      <TableCell key='reponse'>
        {
          (
            !hasAnswered
            ? (
              <a href={"/sondage/" + props._id}>
                <Button variant="outlined" color='primary' size="small"  style={{marginRight: '10px'}}>
                  <FontAwesomeIcon icon='user-edit' style={{marginRight: '15px'}} />
                  Répondre au sondage
                </Button>
              </a>

            )
            : (surveyOpen ?
              (
                <a href={"/sondage/" + props._id}>
                  <Button variant="outlined" color='secondary' size="small"  style={{marginRight: '10px'}}>
                    <FontAwesomeIcon icon='user-check' style={{marginRight: '15px'}} />
                    Modifier mes réponses
                  </Button>
                </a>
              )
              : (
                <div style={{display: "flex", flexDirection: 'column', alignItems: "flex-start"}}>
                  <div>Sondage clos</div>
                  <a href={"/sondage/" + props._id} style={{marginLeft: 0}}>
                    <Button variant="outlined" color='black' size="small">
                      Revoir mes réponses
                    </Button>
                  </a>
                </div>
              )
            )
          )
          
        }

      </TableCell>
    ]
  }

  const SurveyForm = (props) => {
    const [openQuestionIndex, setOpenQuestionIndex] = useState(null)

    const handleEditorChange = (content, editor) => {
      let question = {...props.editedResource}
      question.content = content
      props.setEditedResource(question)
      props.setNeedsSaving(true)
     }

    const renderEditor = () => {
      return (
        <Editor value={props.editedResource.content && props.editedResource.content.replace(/src=\"api/g, "src=\"https://intranetv2.comptoirdelours.fr/api")} onEditorChange={handleEditorChange} apiKey='1a44ucvt07bi0x6k4rxvdbzc2im5lghbn2ojkkf0bp6cbquj'
        init={{
          width: '100%',
          height: '100%',
          language: 'fr_FR',
          language_url: '/langs/fr_FR.js',
          plugins: 'image preview autolink save link media table charmap hr anchor advlist wordcount help paste',
          menubar: 'file edit view insert format tools table help',
          toolbar: 'undo redo | bold italic underline strikethrough | formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | preview | insertfile image media template link anchor',
          content_css: [
              '//fonts.googleapis.com/css?family=Poppins|Open+Sans:400,700&display=swap',
            ],
          content_style: 'h1 {font-family: Poppins; font-size: 30px; color: rgba(0,0,0,0.87); font-weight: 700; text-transform: uppercase; text-align: center; margin-bottom: 20px!important;}' +
              'h2 {font-family: Poppins; font-size: 20px; font-weight: 700; line-height: 33px; color: rgba(0,0,0,0.87);}' +
              'p {font-family: Open Sans; font-weight: 400; font-size: 14px; line-height: 25px; color: rgba(0,0,0,0.87); margin-bottom: 20px;}' +
              'body {max-width: 100%; padding: 10px 20px; font-family: Open Sans;}' +
              'img {max-width: 100%; height: auto; margin-bottom: 20px;}' +
              '.caption {color: red;}',
          block_formats: 'Titre principal=h1; Titre de section=h2; Paragraphe=p',
          style_formats: {title: 'Légende', format: 'caption'},
          formats: {
            caption: { block: 'div', classes: 'caption' }
          },
          paste_as_text: true,
          images_upload_handler: (blobInfo, success, failure) => {
            let data = new FormData()
            data.append('file', blobInfo.blob(), blobInfo.filename())
            ajax.put('/api/media', data).then(response => {
              success(response.path)
            }).catch(e => {
              failure()
            })
          }
        }}

        />
      )
    }

    const toggle = e => {
      const {name} = e.target

      let defaultStartDate = new Date()
      defaultStartDate.setHours(0)
      defaultStartDate.setMinutes(0)
      defaultStartDate.setSeconds(0)
      defaultStartDate.setMilliseconds(0)

      let defaultEndDate = new Date()
      defaultEndDate.setHours(23)
      defaultEndDate.setMinutes(59)
      defaultEndDate.setSeconds(59)
      defaultEndDate.setMilliseconds(999)

      props.setEditedResource(prevState => ({...prevState, startDate: (props.editedResource.startDate || defaultStartDate), endDate: (props.editedResource.endDate || defaultEndDate), [name]: !props.editedResource[name]}))
      props.setNeedsSaving(true)
    }

    const handleChange = e => {
      const { name, value } = e.target;
      props.setEditedResource(prevState => ({...prevState, [name]: value}))
      props.setNeedsSaving(true)
    }

    const setStartDate = (date) => {
      date.setHours(0)
      date.setMinutes(0)
      date.setSeconds(0)
      date.setMilliseconds(0)
      props.setEditedResource(prevState => ({...prevState, startDate: date}))
      props.setNeedsSaving(true)
    }
    const setEndDate = (date) => {
      date.setHours(23)
      date.setMinutes(59)
      date.setSeconds(59)
      date.setMilliseconds(999)
      props.setEditedResource(prevState => ({...prevState, endDate: date}))
      props.setNeedsSaving(true)
    }

    const renderGeneralTab = () => {
      return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto', overflowX: 'hidden', padding: '10px'}}>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center'}}>
            <div style={{marginBottom: '10px', flexGrow: 1, flexShrink: 1, minWidth: 0, marginRight: '10px'}}>{props.textInput('title', 'Titre', true)}</div>
          </div>
          <Grid container item direction='column' spacing={2}>
            <Grid item><Typography variant='h6'>Publication</Typography></Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
              <Grid item>
                <FormControl>
                  <FormGroup>
                    <RadioGroup row name='status' onChange={handleChange} value={props.editedResource.status}>
                      <FormControlLabel value='published' control={<Radio />} label='Publié' />
                      <FormControlLabel value='unpublished' control={<Radio />} label='Non publié' />
                    </RadioGroup>
                  </FormGroup>
                  <FormGroup row={true}>
                    <FormControlLabel control={<Checkbox name='hasStartDate' disabled={props.editedResource.status === 'unpublished'} checked={props.editedResource.hasStartDate} onChange={toggle} />} label='Visible à partir du' />
                    <KeyboardDatePicker
                      disabled={!props.editedResource.hasStartDate || props.editedResource.status === 'unpublished'}
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Date de début"
                      value={props.editedResource.startDate || new Date()}
                      onChange={setStartDate}
                      style={{width: 'unset'}}
                    />
                  </FormGroup>
                  <FormGroup row>
                    <FormControlLabel control={<Checkbox name='hasEndDate' disabled={props.editedResource.status === 'unpublished'} checked={props.editedResource.hasEndDate} onChange={toggle} />} label="Date limite de réponse" />
                    <KeyboardDatePicker
                      disabled={!props.editedResource.hasEndDate || props.editedResource.status === 'unpublished'}
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Date limite de réponse"
                      value={props.editedResource.endDate || new Date()}
                      onChange={setEndDate}
                      style={{width: 'unset'}}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <div style={{width: '100%', height: '300px', flexGrow: 0, flexShrink: 0, marginBottom: '50px', marginTop: '10px'}}>
            <Typography variant='h6'>Description du sondage</Typography>
            {renderEditor()}
          </div>
        </div>
      )
    }

    const addQuestion = () => {
      props.setEditedResource(prevState => ({...prevState, questions: [...props.editedResource.questions, {id: Date.now(), title: "", description: "", type: "string", options: {}} ]}))
      props.setNeedsSaving(true)
    }

    const addVariant = (i) => {
      let questions = [...props.editedResource.questions]
      questions[i].variants.push({id: Date.now(), name: "", unitPrice: ""})
      props.setEditedResource(prevState => ({...prevState, questions: questions}))
      props.setNeedsSaving(true)
    }

    const removeVariant = (i, j) => {
      let questions = [...props.editedResource.questions]
      questions[i].variants.splice(j, 1)
      props.setEditedResource(prevState => ({...prevState, questions: questions}))
      props.setNeedsSaving(true)
    }

    const handleQuestionChange = (i, e) => {
      const { name, value } = e.target;
      console.log(name, value)
      let questions = [...props.editedResource.questions]
      questions[i][name] = value
      props.setEditedResource(prevState => ({...prevState, questions: questions}))
      props.setNeedsSaving(true)
    }

    const handleOptionChange = (i, e) => {
      const { name, value } = e.target;
      console.log(name, value)
      let questions = [...props.editedResource.questions]
      questions[i].options[name] = value
      props.setEditedResource(prevState => ({...prevState, questions: questions}))
      props.setNeedsSaving(true)
    }

    const toggleOptionChange = (i, e) => {
      const { name, value } = e.target;
      console.log(name)
      let questions = [...props.editedResource.questions]
      questions[i].options[name] = !questions[i].options[name]
      props.setEditedResource(prevState => ({...prevState, questions: questions}))
      props.setNeedsSaving(true)
    }

    const addChoice = (i, e) => {
      let questions = [...props.editedResource.questions]
      if (!questions[i].options.choices) questions[i].options.choices = []
      questions[i].options.choices.push('')
      props.setEditedResource(prevState => ({...prevState, questions: questions}))
      props.setNeedsSaving(true)
    }

    const removeChoice = (i, j,  e) => {
      let questions = [...props.editedResource.questions]
      if (!questions[i].options.choices) return
      questions[i].options.choices.splice(j, 1)
      props.setEditedResource(prevState => ({...prevState, questions: questions}))
      props.setNeedsSaving(true)
    }

    const setChoice = (i, j, e) => {
      const { value } = e.target;
      let questions = [...props.editedResource.questions]
      if (!questions[i].options.choices) return
      questions[i].options.choices[j] = e.target.value
      props.setEditedResource(prevState => ({...prevState, questions: questions}))
      props.setNeedsSaving(true)
    }

    const handleVariantChange = (i, j, e) => {
      const { name, value } = e.target;
      if ((name === 'availableStock' || name === 'minQuantity') && value < 0) return
      let questions = [...props.editedResource.questions]
      questions[i].variants[j][name] = value
      props.setEditedResource(prevState => ({...prevState, questions: questions}))
      props.setNeedsSaving(true)
    }

    const toggleQuestionChange = (i, e) => {
      let {name} = e.target
      let questions = [...props.editedResource.questions]
      questions[i][name] = !questions[i][name]
      props.setEditedResource(prevState => ({...prevState, questions: questions}))
      props.setNeedsSaving(true)
      setOpenQuestionIndex(questions[i][name] ? i : (openQuestionIndex === i ? null : openQuestionIndex))
    }

    const removeQuestion = (i) => {
      let questions = [...props.editedResource.questions]
      questions.splice(i, 1)
      props.setEditedResource(prevState => ({...prevState, questions: questions}))
      props.setNeedsSaving(true)
    }

    const toggleOpenQuestionIndex = (i) => {
      setOpenQuestionIndex(openQuestionIndex === i ? null : i)
    }

    const renderQuestionRow = (question, i) => {
      return (
        <>
        <TableRow key={i}>
          <TableCell>
            <TextField size='small' multiline rows={4} fullWidth variant='outlined' value={question.title} name='title' onChange={handleQuestionChange.bind(null, i)} />
          </TableCell>
          <TableCell>
            <Select
              name="type"
              value={question.type}
              label="Type de réponse"
              fullWidth
              onChange={handleQuestionChange.bind(null, i)}
            >
              <MenuItem value='string'>Texte court</MenuItem>
              <MenuItem value='text'>Texte sur plusieurs lignes</MenuItem>
              <MenuItem value='number'>Valeur numérique</MenuItem>
              <MenuItem value='single-choice'>Case à cocher (1 seul choix possible)</MenuItem>
              <MenuItem value='multiple-choice'>Case à cocher (plusieurs choix possibles)</MenuItem>
            </Select>
          </TableCell>
          <TableCell>
            <Grid item>
              <FormControl>
                <FormGroup>
                  <FormControlLabel label='Réponse obligatoire' control={<Checkbox name='mandatory' checked={question.options.mandatory} onChange={toggleOptionChange.bind(null, i)} />} />
                </FormGroup>
              </FormControl>
              {
                question.type === 'number' && (
                  <>
                    <FormGroup row={true}>
                      <FormControlLabel control={<Checkbox name='hasMinValue' checked={question.options.hasMinValue} onChange={toggleOptionChange.bind(null, i)} />} label='Valeur min.' />
                      <TextField size='small' type='number' variant='outlined' disabled={!question.options.hasMinValue} value={question.options.minValue} name="minValue" onChange={handleOptionChange.bind(null, i)} />
                    </FormGroup>
                    <FormGroup row={true}>
                      <FormControlLabel control={<Checkbox name='hasMaxValue' checked={question.options.hasMaxValue} onChange={toggleOptionChange.bind(null, i)} />} label='Valeur max.' />
                      <TextField size='small' type='number' variant='outlined' disabled={!question.options.hasMaxValue} value={question.options.maxValue} name="maxValue" onChange={handleOptionChange.bind(null, i)} />
                    </FormGroup>
                  </>
                )
              }
              {
                (question.type === 'single-choice' || question.type === 'multiple-choice') && (
                  <>
                    <Grid container direction='row' style={{alignItems: "center"}}>
                      <div>Choix possibles</div>
                      <a className='btn btn-icon btn-light btn-sm' onClick={addChoice.bind(null, i)}>
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}/>
                        </span>
                      </a>
                    </Grid>
                    <Grid container direction='column'>
                      {
                        question.options.choices && question.options.choices.map((choice, j) => (
                          <Grid item>
                            <Grid container direction='row'>
                              <Grid item>
                                <a className='btn btn-icon btn-light btn-sm' onClick={removeChoice.bind(null, i, j)}>
                                  <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Minus.svg")}/>
                                  </span>
                                </a>
                              </Grid>
                              <Grid item><TextField fullWidth size='small' variant='outlined' value={choice} onChange={setChoice.bind(null, i, j)}/></Grid>
                            </Grid>  
                          </Grid>
                        ))
                      }
                      
                    </Grid>
                  </>
                )
              }
              
            </Grid>
          </TableCell>

          <TableCell>
            <a className='btn btn-icon btn-light btn-sm' onClick={removeQuestion.bind(null, i)}>
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
              </span>
            </a>
          </TableCell>
        </TableRow>
        </>
      )
    }

    const renderQuestionsTab = () => {
      console.log(props.editedResource)
      return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%', overflowY: 'auto', overflowX: 'hidden', padding: '10px'}}>
          <Grid container direction='row' style={{marginTop: '10px', marginBottom: '10px'}}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={addQuestion}>
                <FontAwesomeIcon icon='plus' style={{marginRight: '15px'}} />
                Ajouter une question
              </Button>
            </Grid>
          </Grid>

          <TableContainer>
            <Table stickyHeader size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>INTITULÉ</TableCell>
                  <TableCell>TYPE DE RÉPONSE</TableCell>
                  <TableCell>OPTIONS</TableCell>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.editedResource.questions.map(renderQuestionRow)}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )
    }

    return (
      <div className='Surveys' style={{display: 'flex', flexDirection: 'column', flexGrow: 1, flexShrink: 1, minHeight: 0}}>
        <Card style={{flexGrow: 1, flexShrink: 1, minHeight: 0, marginBottom: '10px'}}>
          <CardContent style={{height: '100%', display: 'flex', flexDirection: 'column', padding: 0, paddingTop: '10px'}}>
            <Tabs defaultActiveKey="general" style={{paddingLeft: '10px', paddingRight: '10px'}}>
              <Tab eventKey='general' title='Général'>
                {renderGeneralTab()}
              </Tab>
              <Tab eventKey='questions' title='Questions'>
                {renderQuestionsTab()}
              </Tab>
            </Tabs>
          </CardContent>
        </Card>
        <Card style={{flexGrow: 0, flexShrink: 0}}>
          <CardActions>
            {
              props.needsSaving && (
                <Button variant="contained" color="primary" onClick={props.save}>
                  Enregistrer
                </Button>
              )
            }
            <Button variant="contained" color="secondary" onClick={props.cancel}>
              Annuler
            </Button>
          </CardActions>
        </Card>
      </div>
    )
  }

  const removeQuestion = (question) => {
    return `Voulez-vous vraiment supprimer le sondage\n \"${question.title}\" ?`
  }

  const getNewResource = () => {
    return {
      title: 'Nouveau sondage',
      date: new Date(),
      status: 'published',
      hasStartDate: false,
      hasEndDate: false,
      questions: [],
      answers: {},
      userViews: []
    }
  }

  return (
    <>
      <ResourceList
        resourceType='survey'
        filterFields={['title']}
        sortResources={(user && user.role === 'admin') ? sortSurveys : undefined}
        addLabel="Nouveau sondage"
        removeLabel="Suppression d'un sondage"
        removeQuestion={removeQuestion}
        headers={(user && user.role !== 'admin') ? ['Titre', 'Date limite de réponse', 'Votre réponse'] : ['Titre', 'Statut', "Réponses"]}
        resourceRow={(user && user.role === 'admin') ? SurveyRow : SurveyRowAdherent}
        resourceForm={SurveyForm}
        getNewResource={getNewResource}
        normalUserApi={'surveys/public'}
        noNormalUserAction
        excludeFromPost={['answers']}
      />
      <Modal scrollable show={userViewsDetail !== null}>
        <Modal.Header>
          <Modal.Title>
            Utilisateurs ayant ouvert le sondage
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: 0}}>
          {
            userViewsDetail && (
              <List dense>
                {
                  userViewsDetail.map((user, i) => {
                    const fullName = user.firstName + ' ' + user.lastName.toUpperCase()
                    return (
                      <>
                        <ListItem key={i}>
                          <ListItemAvatar><Avatar alt={fullName} src={'/api/media/thumbnail/' + user.picture} /></ListItemAvatar>
                          <ListItemText primary={fullName} />
                        </ListItem>
                        <Divider />
                      </>
                    )
                  })
                }
              </List>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button style={{marginRight: '10px'}} variant="contained" color="secondary" onClick={() => { setUserViewsDetail(null) }}>Fermer</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Surveys
