import React, {useState, useEffect} from "react";
import Media from '../components/Media/Media'
import {TextField, Grid, Paper, Card, CardContent, CardActions, Typography, Button, TableCell, List, ListItem, ListItemAvatar, ListItemText, Avatar, Divider, ListItemIcon, Checkbox} from '@material-ui/core'
import { AvatarGroup, TabPanel } from '@material-ui/lab'
import ResourceList from '../components/ResourceList/ResourceList'
import ajax from '../utils/ajax'

export const Commitees = () => {
  const [users, setUsers] = useState(null)
  const [userArray, setUserArray] = useState(null)
  const [user, setUser] = useState(null)

  const fetch = () => {
    ajax.get('/api/me').then(user => {
      setUser(user)
    })
    ajax.get('/api/users').then(r => {
      let users = {}
      for (let user of r) {
        users[user._id] = user
      }
      setUsers(users)
      r.sort((a, b) => a.lastName.localeCompare(b.lastName))
      setUserArray(r)
    })
  }
  useEffect(fetch, [])

  const CommiteeRow = (props) => {
    if (!userArray) return []

    return [
      <TableCell key='name'>{props.name}</TableCell>,
      <TableCell key='members'>
        <AvatarGroup max={8}>
          {props.users.map(userId => {
            let userObj = users[userId]
            if (!userObj) return null
            return (
              <Avatar key={userId} alt={userObj.firstName + ' ' + userObj.lastName.toUpperCase()} src={'/api/media/thumbnail/' + userObj.picture} />
            )
          })}
        </AvatarGroup>
      </TableCell>,
      <TableCell key='meeting'></TableCell>
    ]
  }

  const toggleMember = (setEditedResource, editedResource, userId) => {
    let res = {...editedResource}
    let users = [...res.users]
    let index = users.indexOf(userId)
    if (index >= 0) {
      users.splice(index, 1)
    } else {
      users.push(userId)
    }
    res.users = users
    setEditedResource(res)
  }

  const CommiteeForm = (props) => {
    const [activeTab, setActiveTab] = useState(0)
    const [memberFilter, setMemberFilter] = useState('')
    const [filteredMembers, setFilteredMembers] = useState(null)

    useEffect(() => {
      if (!userArray) return
      if (memberFilter === '') setFilteredMembers(userArray)
      let rgx = new RegExp(memberFilter, 'gi')
      setFilteredMembers(userArray.filter(user =>
        user.firstName.match(rgx) || user.lastName.match(rgx)
      ))
    }, [memberFilter])

    const handleTabChange = (e, newValue) => {
      setActiveTab(newValue);
    }

    const renderMembers = () => {
      return (
        <List>
          {
            (filteredMembers || userArray).map(user => {
              const fullName = user.firstName + ' ' + user.lastName.toUpperCase()
              return (
                <>
                  <ListItem button onClick={() => {
                    toggleMember(props.setEditedResource, props.editedResource, user._id)
                    props.setNeedsSaving(true)
                  }}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={props.editedResource.users.indexOf(user._id) >= 0}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemAvatar>
                      <Avatar alt={fullName} src={'/api/media/thumbnail/' + user.picture} />
                    </ListItemAvatar>
                    <ListItemText primary={fullName} />
                  </ListItem>
                  <Divider />
                </>

              )
            })
          }
        </List>
      )
    }

    const renderMembersReadonly = () => {
      let commiteeUsers = [...props.editedResource.users]
      commiteeUsers = commiteeUsers.filter(u => users[u] !== undefined)
      commiteeUsers.sort((a, b) => {
        users[a].lastName.toLowerCase().localeCompare(users[b].lastName.toLowerCase())
      })


      return (
        <List style={{flexGrow: 1, flexShrink: 1, minHeight: 0}}>
          {
            commiteeUsers.map(user => {
              user = users[user]
              const fullName = user.firstName + ' ' + user.lastName.toUpperCase()
              return (
                <React.Fragment key={user._id}>
                  <ListItem >
                    <ListItemAvatar>
                      <Avatar alt={fullName} src={'/api/media/thumbnail/' + user.picture} />
                    </ListItemAvatar>
                    <ListItemText primary={fullName} />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              )
            })
          }
        </List>
      )
    }

    return (
      <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, flexShrink: 1, minHeight: 0}}>
        <Card style={{marginBottom: '10px', flexGrow: 0, flexShrink: 0}}>
          <CardContent style={{width: '100%'}}>
            {
              user.role === 'admin'
              ? (
                <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
                  {props.textInput('name', 'Nom')}
                  <TextField type='search' label={"Rechercher des membres..."} size='small' variant='outlined' value={memberFilter || ''} onChange={(e) => {setMemberFilter(e.target.value)}} onKeyDown={e => {if (e.key === 'Enter') e.target.blur()}} />
                </div>
              )
              : <Typography variant='h5'>{props.editedResource.name}</Typography>
            }
          </CardContent>
        </Card>

        <Card style={{display: 'flex', flexDirection: 'column', marginBottom: '10px', flexGrow: 1, flexShrink: 1, minHeight: 0, overflowY: 'auto'}}>
          <CardContent>
            {
              user.role === 'admin'
              ? renderMembers()
              : renderMembersReadonly()
            }
          </CardContent>
        </Card>

          {
            user.role === 'admin'
            && (
              <Card style={{flexGrow: 0, flexShrink: 0}}>
                <CardActions>
                  {
                    props.needsSaving && (
                      <Button variant="contained" color="primary" onClick={props.save}>
                        Enregistrer
                      </Button>
                    )
                  }
                  <Button variant="contained" color="secondary" onClick={props.cancel}>
                    Annuler
                  </Button>
                </CardActions>
              </Card>
            )
          }
      </div>
    )
  }

  const getNewCommitee = () => {
    return {name: '', users: []}
  }

  const removeQuestion = (c) => {
    return `Voulez-vous vraiment supprimer la commission ${c.name} ?`
  }

  return (
    <ResourceList
      resourceType='commitee'
      filterFields={['name']}
      addLabel="Ajouter une commission"
      removeLabel="Suppression d'une commission"
      removeQuestion={removeQuestion}
      headers={['Nom', 'Membres', 'Prochaine réunion']}
      resourceRow={CommiteeRow}
      resourceForm={CommiteeForm}
      pictureFields={['picture']}
      getNewResource={getNewCommitee}
    />
  )
}

export default Commitees
